import React from 'react';
import { Box, Flex, FormLabel, Textarea, Checkbox, Radio, RadioGroup, Switch, Select } from '@chakra-ui/react';
import InputComponent from '../../Input';


const DynamicFormField = ({ fieldConfig, data, handleChange }) => {
    const renderField = () => {
        switch (fieldConfig.fieldType) {
            case 'input' :
                return (
                  <InputComponent
                    id={fieldConfig.value}
                    size={fieldConfig.size || "md"}
                    type={fieldConfig.dataType || 'text'}
                    rounded="md"
                    placeholder={`Edit ${fieldConfig.label} here...`}
                    name={fieldConfig.value}
                    value={data[fieldConfig.value] || ''}
                    handleChange={handleChange}
                    required={fieldConfig.isRequired}
                    min={fieldConfig?.minValue}
                  />
                )

            case 'textarea':
                return (
                    <Textarea
                        id={fieldConfig.value}
                        className="scroll-sm"
                        size="sm"
                        fontSize="0.75rem"
                        fontWeight="600"
                        rounded="lg"
                        bg="shadedGray.400"
                        color="shadedGray.800"
                        border={0}
                        placeholder={`Edit ${fieldConfig.label} here...`}
                        _placeholder={{ opacity: 1, color: 'shadedGray.600' }}
                        name={fieldConfig.value}
                        value={data[fieldConfig.value] || ''}
                        onChange={handleChange}
                        isRequired={fieldConfig.isRequired}
                    />
                );

            case 'checkbox':
                return (
                    <Checkbox
                        id={fieldConfig.value}
                        size="sm"
                        colorScheme="brand"
                        name={fieldConfig.value}
                        isChecked={!!data[fieldConfig.value]}
                        onChange={handleChange}
                    >
                        {fieldConfig.label}
                    </Checkbox>
                );

            case 'radio':
                return (
                    <RadioGroup
                        id={fieldConfig.value}
                        name={fieldConfig.value}
                        value={data[fieldConfig.value] || ''}
                        onChange={(val) => handleChange({ target: { name: fieldConfig.value, value: val } })}
                        isRequired={fieldConfig.isRequired}
                    >
                        {fieldConfig.options.map((option, idx) => (
                            <Radio key={idx} value={option.value}>
                                {option.label}
                            </Radio>
                        ))}
                    </RadioGroup>
                );

            case 'switch':
                return (
                  <Switch
                    id={fieldConfig.value}
                    size={fieldConfig.size}
                    colorScheme="brand"
                    name={fieldConfig.value}
                    isChecked={!!data[fieldConfig.value]}
                    onChange={handleChange}
                    isRequired={fieldConfig.isRequired}
                  />
                )

            case 'select':
                return (
                  <Select
                    id={fieldConfig.value}
                    size={fieldConfig.size}
                    colorScheme="brand"
                    name={fieldConfig.value}
                    placeholder={fieldConfig.placeholder || "Select an option"}
                    value={data[fieldConfig.value] || ""}
                    defaultValue={fieldConfig.defaultValue}
                    onChange={handleChange}
                    isRequired={fieldConfig.isRequired}
                  >
                      {fieldConfig.options.map((option, idx) => (
                        <option
                            key={idx}
                            value={option.value}
                            disabled={option.isDisabled}
                            color={option.color}
                        >
                            {option.label}
                        </option>
                      ))}
                  </Select>
                )
            default:
                return null;
        }
    };

    return (
        <Box>
            {fieldConfig.fieldType !== 'checkbox' && (
                <Flex w="100%" justifyContent="flex-start" height="100%" alignItems="center">
                    <FormLabel
                        alignSelf="flex-end"
                        fontSize="0.75rem"
                        fontWeight="500"
                        mb={0}
                        lineHeight="2rem"
                        pr={2}
                        size={fieldConfig?.size || 'md'}
                        htmlFor={fieldConfig.value}
                    >
                        {fieldConfig.label} {fieldConfig.caption}
                    </FormLabel>
                </Flex>
            )}
            {renderField()}
        </Box>
    );
};

export default DynamicFormField;