import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    Box,
    Grid,
    Heading,
    VStack,
    Spinner,
    Text,
    Flex,
    Button,
    HStack,
    useDisclosure,
    StackDivider, Divider
} from '@chakra-ui/react';
import {
    MdOutlineCheckCircleOutline,
    MdOutlineUpdate
} from "react-icons/md";

import ContentLayout from '../../../layouts/ContentLayout';
import ModalLayout from "../../../components/Modal/ModalLayout";
import RetryBeneficiaryVerificationModal from "../../../components/Modal/RetryBeneficiaryVerificationModal/RetryBeneficiaryVerificationModal";
import ForceUpdateBeneficiaryVerificationModal from '../../../components/Modal/ForceUpdateBeneficiaryVerificationModal/ForceUpdateBeneficiaryVerificationModal';
import GenericCard from '../../../components/GenericCard';
import VerifierSideCard from '../../../components/BeneficieryDashboard/VerifierSideCard';

import {
    forceUpdateBeneficiaryVerification,
    getBeneficiaryVerificationDetails,
    retryBeneficiaryVerification
} from "../../../store/actions/beneficiaryVerificationActions";

import { CARD_TYPE } from '../../../constants';
import useIsMobile from '../../../hooks/useIsMobile';

const RecipientVerificationDetails = () => {
    const dispatch = useDispatch();
    const { verificationId } = useParams();
    const isMobile = useIsMobile();

    const {
        isOpen: isRetryModalOpen,
        onOpen: onRetryModalOpen,
        onClose: onRetryModalClose
    } = useDisclosure();
    const {
        isOpen: isForceUpdateModalOpen,
        onOpen: onForceUpdateModalOpen,
        onClose: onForceUpdateModalClose
    } = useDisclosure();

    const [isRetrying, setIsRetrying] = useState(false);
    const [isForceUpdating, setIsForceUpdating] = useState(false);
    const [activeVerifierData, setActiveVerifierData] = useState(null);
    const [forceUpdateModalData, setForceUpdateModalData] = useState({});
    const [retryBeneficiaryModalData, setRetryBeneficiaryModalData] = useState({});

    const {
        data: verifiers,
        isLoading,
        isError,
        error
    } = useSelector(state => state?.beneficiaryVerificationDashboard?.beneficiaryVerification);

    const {
        data: beneficiaryVerificationList
    } = useSelector(state => state?.beneficiaryVerificationDashboard?.beneficiaryVerifications);

    const triggerGetBeneficiaryVerificationDetail = useCallback(() => {
        dispatch(getBeneficiaryVerificationDetails({ verificationId }));
    }, [dispatch, verificationId]);

    useEffect(() => {
        triggerGetBeneficiaryVerificationDetail();
    }, [beneficiaryVerificationList, verificationId]);

    const handleRetryBeneficiaryVerificationSubmit = () => {
        setIsRetrying(true);
        const { isUsingPreviousVerifierData } = retryBeneficiaryModalData;
        dispatch(
          retryBeneficiaryVerification({
              payload: {
                  verificationId: verificationId,
                  usePreviousVerifierData: !!isUsingPreviousVerifierData
              },
              onComplete: () => {
                  setRetryBeneficiaryModalData({});
                  setIsRetrying(false);
                  onRetryModalClose();
                  triggerGetBeneficiaryVerificationDetail();
              }
          })
        );
    };

    const handleForceUpdateSubmit = () => {
        setIsForceUpdating(true);
        const { beneficiaryAccountType } = forceUpdateModalData;
        dispatch(
          forceUpdateBeneficiaryVerification({
              payload: {
                  verificationId: verificationId,
                  expirationTime: null,
                  beneficiaryType: beneficiaryAccountType,
                  isForceUpdate: true
              },
              onComplete: () => {
                  setForceUpdateModalData({});
                  setIsForceUpdating(false);
                  onForceUpdateModalClose();
                  triggerGetBeneficiaryVerificationDetail();
              }
          })
        );
    };

    return (
      <Box position="relative">
          <ModalLayout
            isOpen={isRetryModalOpen}
            onClose={onRetryModalClose}
            size="sm"
          >
              <RetryBeneficiaryVerificationModal
                modalData={retryBeneficiaryModalData}
                setModalData={setRetryBeneficiaryModalData}
                handleSubmit={handleRetryBeneficiaryVerificationSubmit}
                isLoading={isRetrying}
                onClose={onRetryModalClose}
              />
          </ModalLayout>
          <ModalLayout
            isOpen={isForceUpdateModalOpen}
            onClose={onForceUpdateModalClose}
            size="sm"
          >
              <ForceUpdateBeneficiaryVerificationModal
                modalData={forceUpdateModalData}
                setModalData={setForceUpdateModalData}
                handleSubmit={handleForceUpdateSubmit}
                isLoading={isForceUpdating}
                onClose={onForceUpdateModalClose}
              />
          </ModalLayout>
          <ContentLayout>
              <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0 3fr' }}>
                  <VStack
                    overflowY={{ base: 'initial', lg: 'scroll' }}
                    overflowX="hidden"
                    h={{ lg: '90vh' }}
                    py={10}
                    px={{ base: 4, sm: 10 }}
                    alignItems="stretch"
                    gap={2}
                    divider={<StackDivider borderColor="whiteAlpha.300" />}
                  >
                      <Heading size="lg" color="colorPrimary">
                          Beneficiary Verification Details
                      </Heading>
                      {isLoading ? (
                        <Flex justifyContent="center" alignItems="center" width="full" h="full">
                            <Spinner thickness="4px" size="lg" color="colorPrimary" />
                        </Flex>
                      ) : isError ? (
                        <Box color="red.500" p={4} borderRadius="md" borderWidth="1px">
                            <Text>
                                An error occurred while fetching transactions: {error}
                            </Text>
                        </Box>
                      ) : verificationId ? (
                        <VStack alignItems="start" gap={2}>
                            <HStack my={3} gap={3} justifyContent="start" width="100%">
                                <Button
                                  colorScheme="white"
                                  variant="ghost"
                                  bg="whiteAlpha.300"
                                  rightIcon={<MdOutlineUpdate size={16} />}
                                  onClick={onRetryModalOpen}
                                  _hover={{ backgroundColor: "whiteAlpha.400" }}
                                >
                                    Retry Verification
                                </Button>
                                <Button
                                  colorScheme="brand"
                                  color="colorPrimary"
                                  variant="outline"
                                  rightIcon={<MdOutlineCheckCircleOutline />}
                                  isLoading={isForceUpdating}
                                  onClick={onForceUpdateModalOpen}
                                  _hover={{ backgroundColor: "colorPrimary", color: "gray.800" }}
                                >
                                    Force Update
                                </Button>
                            </HStack>
                            {Array.isArray(verifiers) && verifiers.length > 0 ? (
                              verifiers.map(verifier => (
                                <GenericCard
                                  key={verifier.id}
                                  cardType={CARD_TYPE.VERIFIER_CARD}
                                  cardData={verifier}
                                  ActionButtons={[
                                      <Button
                                        colorScheme="brand"
                                        color="altGray.400"
                                        size="md"
                                        fontSize="sm"
                                        mt={3}
                                        isLoading={isLoading}
                                        onClick={() => setActiveVerifierData(verifier)}
                                      >
                                          View More
                                      </Button>
                                  ]}
                                />
                              ))
                            ) : (
                              <Text color="white" fontSize="md">
                                  No verifier details found
                              </Text>
                            )}
                        </VStack>
                      ) : (
                        <Text color="white" fontSize="md">
                            No active beneficiary details found
                        </Text>
                      )}
                  </VStack>
                  <Divider
                    style={{ margin: '0' }}
                    borderColor="whiteAlpha.300"
                    orientation="vertical"
                  />
                  {!isMobile && activeVerifierData && (
                    <VStack
                      alignItems="start"
                      divider={<StackDivider borderColor="whiteAlpha.300" />}
                    >
                        <VerifierSideCard verifier={activeVerifierData} />
                    </VStack>
                  )}
              </Grid>
          </ContentLayout>
      </Box>
    );
};

export default RecipientVerificationDetails;
