const LULU_REFUNDS_CONSTANTS = {
  GETTING_LULU_REFUNDS: 'GETTING_LULU_REFUNDS',
  GET_LULU_REFUNDS_SUCCESS: 'GET_LULU_REFUNDS_SUCCESS',
  GET_LULU_REFUNDS_FAILURE: 'GET_LULU_REFUNDS_FAILURE',

  GETTING_LULU_REFUND_SHEETS: 'GETTING_LULU_REFUND_SHEETS',
  GET_LULU_REFUND_SHEETS_SUCCESS: 'GET_LULU_REFUND_SHEETS_SUCCESS',
  GET_LULU_REFUND_SHEETS_FAILURE: 'GET_LULU_REFUND_SHEETS_FAILURE',
};

export default LULU_REFUNDS_CONSTANTS;