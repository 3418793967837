const BENEFICIARY_VERIFICATIONS_CONSTANTS = {
    // Plural constants
    GETTING_BENEFICIARY_VERIFICATIONS: 'GETTING_BENEFICIARY_VERIFICATIONS',
    GET_BENEFICIARY_VERIFICATIONS_SUCCESS: 'GET_BENEFICIARY_VERIFICATIONS_SUCCESS',
    GET_BENEFICIARY_VERIFICATIONS_FAILURE: 'GET_BENEFICIARY_VERIFICATIONS_FAILURE',

    // Singular constants
    GETTING_BENEFICIARY_VERIFICATION_DETAIL: 'GETTING_BENEFICIARY_VERIFICATION_DETAIL',
    GET_BENEFICIARY_VERIFICATION_SUCCESS_DETAIL: 'GET_BENEFICIARY_VERIFICATION_SUCCESS_DETAIL',
    GET_BENEFICIARY_VERIFICATION_DETAIL_FAILURE: 'GET_BENEFICIARY_VERIFICATION_DETAIL_FAILURE',

    // Force Update
    FORCE_UPDATING_BENEFICIARY_VERIFICATION: 'FORCE_UPDATING_BENEFICIARY_VERIFICATION',
    FORCE_UPDATE_BENEFICIARY_VERIFICATION_SUCCESS: 'FORCE_UPDATE_BENEFICIARY_VERIFICATION_SUCCESS',
    FORCE_UPDATE_BENEFICIARY_VERIFICATION_FAILURE: 'FORCE_UPDATE_BENEFICIARY_VERIFICATION_FAILURE',

    // Retry
    RETRYING_BENEFICIARY_VERIFICATION: 'RETRYING_BENEFICIARY_VERIFICATION',
    RETRY_BENEFICIARY_VERIFICATION_SUCCESS: 'RETRY_BENEFICIARY_VERIFICATION_SUCCESS',
    RETRY_BENEFICIARY_VERIFICATION_FAILURE: 'RETRY_BENEFICIARY_VERIFICATION_FAILURE',
};

export default BENEFICIARY_VERIFICATIONS_CONSTANTS;
