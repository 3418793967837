import types from "../constants/luluRefundsConstants";


const initialState = {
  luluTransactions: {
    data: [],
    isLoading: false,
    isError: false,
    error: null,
  },
  luluRefundSheets: {
    data: [],
    isLoading: false,
    isError: false,
    error: null,
  }
}

const luluRefundsDashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GETTING_LULU_REFUNDS:
      return {
        ...state,
        luluTransactions: {
          data: [],
          isLoading: true,
          isError: false,
          error: null,
        }
      };

    case types.GET_LULU_REFUNDS_SUCCESS:
      return {
        ...state,
        luluTransactions: {
          data: payload,
          isLoading: false,
          isError: false,
          error: null,
        }
      };

    case types.GET_LULU_REFUNDS_FAILURE:
      return {
        ...state,
        luluTransactions: {
          data: [],
          isLoading: false,
          isError: true,
          error: payload,
        }
      }

      case types.GETTING_LULU_REFUND_SHEETS:
      return {
        ...state,
        luluRefundSheets: {
          data: [],
          isLoading: true,
          isError: false,
          error: null,
        }
      };

    case types.GET_LULU_REFUND_SHEETS_SUCCESS:
      return {
        ...state,
        luluRefundSheets: {
          data: payload,
          isLoading: false,
          isError: false,
          error: null,
        }
      };

    case types.GET_LULU_REFUND_SHEETS_FAILURE:
      return {
        ...state,
        luluRefundSheets: {
          data: [],
          isLoading: false,
          isError: true,
          error: payload,
        }
      }

    default:
      return {
        ...state
      };
  }
};

export default luluRefundsDashboardReducer;