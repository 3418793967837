import { v4 as uuidv4 } from 'uuid';
import {
  CONFIG_PROVIDER_LOOKUP,
  COUNTRY_CODE,
  KYC_CHECK_OUTCOME,
  KYC_CHECK_STATUS,
  KYC_DOC_STATUS,
  KYC_PROVIDER,
  NROB_ACTIVE_STEPS,
  NR_OB_COUNTRY,
  PRODUCT,
  VERIFICATIONS_ACTIVE_SEARCH
} from '../constants';
import { COUNTRIES } from '../constants/v2/common';

export const formatDate = (date) => {
  if (!date) return null;
  try {
    let dateObj = new Date(date);
    var dateOptions = {
      minute: 'numeric',
      hour: 'numeric',
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };
    return dateObj.toLocaleDateString('en-US', dateOptions);
  } catch (error) {
    return '';
  }
};

export const formatDateUtc = (date) => {
  if (!date) return '';
  try {
    let dateObj = new Date(date);
    var dateOptions = {
      timeZone: 'UTC',
      minute: 'numeric',
      hour: 'numeric',
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };
    return dateObj.toLocaleString('en-US', dateOptions);
  } catch (error) {
    return '';
  }
};

export const formatDateWithoutTime = (date) => {
  if (!date) return '';
  try {
    let dateObj = new Date(date);
    var dateOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };
    return dateObj.toLocaleDateString('en-US', dateOptions);
  } catch (error) {
    return '';
  }
};

export const formatDobDate = (date) => {
  if (!date) return '';
  try {
    let dateObj = new Date(date);
    var dateOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };
    return dateObj.toLocaleDateString('en-US', dateOptions);
  } catch (error) {
    return '';
  }
};

export const getKycStatusColor = (kycStatus) => {
  if (!kycStatus) return null;
  if (kycStatus === 'COMPLETED' || kycStatus === 'COMPLETED_NEW')
    return 'brand.500';
  else if (kycStatus === 'E_KYC_REJECTED' || kycStatus === 'REJECTED')
    return 'red';
  else return 'yellow.500';
};

export const getKycV2StatusColor = (kycStatus) => {
  if (!kycStatus) return null;
  if (kycStatus === 'VERIFIED') return 'brand.500';
  else if (kycStatus === 'REJECTED' || kycStatus === 'BLOCKED') return 'red';
  else if (kycStatus === 'PENDING') return 'yellow.500';
  else return 'white';
};

export const getCheckStatusColor = (checkStatus) => {
  if (!checkStatus) return null;
  if (checkStatus === KYC_CHECK_STATUS.COMPLETE) return 'brand.500';
  else if (checkStatus === KYC_CHECK_STATUS.FAILED) return 'red';
  else return 'yellow.500';
};

export const getCheckOutcomeColor = (checkOutcome) => {
  if (!checkOutcome) return 'white';
  if (checkOutcome === KYC_CHECK_OUTCOME.CLEAR) return 'brand.500';
  else return 'yellow.500';
};

export const getSourceAccountStatusColor = (status) => {
  if (status === 'ACTIVE') return 'brand.500';
  if (status === 'PENDING_VERIFICATION') return 'yellow.500';
  else return 'white';
};

export const getJobStatusColor = (status) => {
  if (status === 'CLOSED' || status === 'COMPLETED') return 'brand.500';
  if (status === 'FAILED') return 'red.500';
  else return 'white';
};

export const toUserData = (user) => ({
  ...user,
  createdAt: formatDate(user.createdAt),
  kycStatusColor: getKycStatusColor(user.kycStatus),
  updatedAt: formatDate(user.updatedAt)
});

export const toNrUserData = (user) => ({
  ...user,
  createdAt: formatDate(user.createdAt),
  nrStatusColor: getKycStatusColor(user.nrStatus), //todo
  updatedAt: formatDate(user.updatedAt)
});

export const toNrUserDetailsData = (user) => ({
  ...user
});

export const getOrderStatusColor = (orderStatus) => {
  if (orderStatus === 'COMPLETED') return 'green';
  else if (orderStatus === 'FAILED') return 'red';
  else return 'yellow.500';
};

export const getTxnMonitoringStatusColor = (status) => {
    if (status === 'COMPLETED' || status === 'VERIFIED') return 'green';
    else if (status === 'FAILED' || status === 'REJECTED') return 'red';
    else return 'yellow.500';
};

export const getKycDocStatusColor = (status) => {
  if (status === KYC_DOC_STATUS.COMPLETED || status === KYC_DOC_STATUS.VERIFIED)
    return 'green';
  else if (status === KYC_DOC_STATUS.REJECTED) return 'red';
  else return 'yellow.500';
};

export const getUserKycStatusColor = (status) => {
  if (status === KYC_DOC_STATUS.COMPLETED || status === KYC_DOC_STATUS.VERIFIED)
    return 'green';
  else if (status === KYC_DOC_STATUS.REJECTED || status === KYC_DOC_STATUS.BLOCKED) return 'red';
  else return 'yellow.500';
};

export const getRFIStatusColor = (status) => {
  if (status === 'COMPLETED') return 'green';
  else if (status === 'FAILED' || status === 'REJECTED') return 'red';
  else return 'yellow.500';
};


export const toErrorData = (error) => {
  if (!error) return null;

  let errorMessage;

  if (error.response?.data) {
    try {
      const errorData = JSON.parse(error.response.data);
      errorMessage = errorData.message;
    } catch (error) {
      console.log('Error parsing JSON:', error);
    }
  }

  return {
    status: error.response?.status || null,
    message: errorMessage || error.message || null,
    code: error.code || null,
    data: error.response?.data || null
  };
};

export const toOrderData = (orderData) =>
  !orderData
    ? null
    : {
        userId: orderData.userId,
        orderId: orderData.orderId,
        orderStatus: orderData.orderStatus,
        beneficiaryName: orderData.beneficiaryName,
        sourceAccount: orderData.sourceAccountName,
        user: orderData.userName || null,
        paymentMethod: orderData.paymentMethod || null,
        sendAmnt: orderData.sendAmount,
        receiveAmnt: orderData.receiveAmount,
        currencyFrom: orderData.currencyFrom,
        currencyTo: orderData.currencyTo,
        rate: orderData.transferRate,
        flatFee: orderData.flatFee,
        platformFee: orderData.platformFee,
        discount: orderData.discount,
        acquirer: orderData.acquirer,
        amount: orderData.amount,
        createdAt: formatDate(orderData.createdAt),
        metadata: toOrderMetadata(orderData.orderMetadata)
      };

export const toLuluOrderData = (luluOrderData) =>
  !luluOrderData
    ? null
    : {
        userId: luluOrderData.userId,
        orderId: luluOrderData.orderId,
        status: luluOrderData.status,
        createdAt: formatDate(luluOrderData.createdAt),
        userName: luluOrderData.userName,
        transactionRefId: luluOrderData.transactionRefId,
        instrument: luluOrderData.instrument
      };

export const toOrderDetailsData = (orderData) => {
  return {
    orderId: orderData.orderId,
    userId: orderData.userId,
    orderStatus: orderData.orderStatus,
    vanceCash: orderData?.vanceCash,
    sendAmount: orderData.youSend,
    flatFee: orderData.ourFlatFee,
    discount: orderData.discount,
    weConvert: orderData.weConvert,
    amount: orderData.amount,
    addedReward: orderData.addedReward,
    rate: orderData.googleRate,
    receiveAmount: orderData.theyReceive,
    currencyFrom: orderData.currencyFrom,
    currencyTo: orderData.currencyTo,
    statusFlow: orderData.orderStatusFlow ? orderData.orderStatusFlow : [],
    orderType: orderData.orderType,
    previousStatus: orderData.previousStatus,
    paymentPurpose: orderData.paymentPurpose,
    createdAt: orderData.createdAt,
    updatedAt: orderData.updatedAt,
    fulfillmentProvider: orderData?.fulfillmentProvider,
    paymentAcquirer: orderData?.paymentAcquirer,
    beneficiary: {
      accountHolderName: orderData.beneficiary?.accountHolderName,
      accountHolderNickName: orderData.beneficiary?.accountHolderNickname,
      IFSCCode: orderData.beneficiary?.ifscCode,
      accountNumber: orderData.beneficiary?.accountNumber,
      beneficiaryId: orderData?.beneficiary?.beneficiaryId
    },
    orderPayment: {
      paymentId: orderData.orderPayment?.paymentId,
      paymentStatus: orderData.orderPayment?.paymentStatus,
      previousStatus: orderData.orderPayment?.previousStatus,
      paymentInstrument: orderData.orderPayment?.paymentInstrument,
      paymentAcquirer: orderData.orderPayment?.paymentAcquirer,
      createdAt: orderData.orderPayment?.createdAt,
      updatedAt: orderData.orderPayment?.updatedAt
    },
    uaeManualPaymentData: {
      userId: orderData?.orderPayment?.uaeManualPayment?.userId,
      orderId: orderData?.orderPayment?.uaeManualPayment?.orderId,
      status: orderData?.orderPayment?.uaeManualPayment?.status,
      previousStatus: orderData?.orderPayment?.uaeManualPayment?.previousStatus,
      amount: orderData?.orderPayment?.uaeManualPayment?.amount,
      transferId: orderData?.orderPayment?.uaeManualPayment?.transferId,
      brnNumber: orderData?.orderPayment?.uaeManualPayment?.brnNumber,
      bankDepositReference:
        orderData?.orderPayment?.uaeManualPayment?.bankDepositReference,
      description: orderData?.orderPayment?.uaeManualPayment?.description,
      beneficiaryIban:
        orderData?.orderPayment?.uaeManualPayment?.beneficiaryIban,
      beneficiaryAccountId:
        orderData?.orderPayment?.uaeManualPayment?.beneficiaryAccountId,
      createdAt: orderData?.orderPayment?.uaeManualPayment?.createdAt,
      updatedAt: orderData?.orderPayment?.uaeManualPayment?.updatedAt
    },
    onRampData: {
      sendAmount: orderData?.orderPayment?.onRampData?.sentAmount,
      usdtAmount: orderData?.orderPayment?.onRampData?.usdtAmount,
      fiatAmount: orderData?.orderPayment?.onRampData?.fiatAmount,
      txnId: orderData?.orderPayment?.onRampData?.txnId,
      status: orderData?.orderPayment?.onRampData?.status,
      txnHash: orderData?.orderPayment?.onRampData?.txnHash,
      exchangeRate: orderData?.orderPayment?.onRampData?.exchangeRate,
      currencyFrom: orderData?.orderPayment?.onRampData?.currencyFrom,
      currencyTo: orderData?.orderPayment?.onRampData?.currencyTo,
      createdAt: orderData?.orderPayment?.onRampData?.createdAt,
      updatedAt: orderData?.orderPayment?.onRampData?.updatedAt
    },
    plaidPaymentData: orderData.orderPayment?.plaidPaymentData
      ? {
          plaidRecipientId:
            orderData.orderPayment.plaidPaymentData.plaidRecipientId,
          plaidPaymentId:
            orderData.orderPayment.plaidPaymentData.plaidPaymentId,
          plaidPaymentStatus:
            orderData.orderPayment.plaidPaymentData.plaidPaymentStatus,
          plaidPreviousStatus:
            orderData.orderPayment.plaidPaymentData.plaidPreviousStatus,
          linkToken: orderData.orderPayment.plaidPaymentData.linkToken,
          createdAt: orderData.orderPayment.plaidPaymentData.createdAt,
          updatedAt: orderData.orderPayment.plaidPaymentData.updatedAt
        }
      : null,
    leantechPaymentData: orderData?.orderPayment?.leantechPaymentData
      ? {
          ...orderData.orderPayment.leantechPaymentData
        }
      : null,

    truelayerData: orderData?.orderPayment?.truelayerPaymentData
      ? {
          ...orderData?.orderPayment?.truelayerPaymentData
        }
      : null,
    orderFulfillment: orderData?.orderFulfillment?.map((orderFulfillment) => {
      return {
        fulfillmentId: orderFulfillment?.fulfillmentId,
        fulfillmentType: orderFulfillment?.fulfillmentType,
        fulfillmentStatus: orderFulfillment?.fulfillmentStatus,
        previousStatus: orderFulfillment?.previousStatus,
        fulfillmentProvider: orderFulfillment?.fulfillmentProvider,
        createdAt: orderFulfillment?.createdAt,
        updatedAt: orderFulfillment?.updatedAt
      };
    }),
    rfxData: orderData?.orderFulfillment
      ?.map((fulfillment) => {
        return fulfillment?.rfxData
          ? {
              dealId: fulfillment?.rfxData.dealId,
              recipientPaymentId: fulfillment?.rfxData.recipientPaymentId,
              marketRate: fulfillment?.rfxData.actualQuoteMarketRate,
              rateQuoted: fulfillment?.rfxData.rateQuoted,
              amountSell: fulfillment?.rfxData.amountSell,
              amountBuy: fulfillment?.rfxData.amountBuy,
              currencySell: fulfillment?.rfxData.currencySell,
              currencyBuy: fulfillment?.rfxData.currencyBuy,
              paymentFee: fulfillment?.rfxData.paymentFee,
              status: fulfillment?.rfxData.status,
              previousStatus: fulfillment?.rfxData.previousStatus,
              createdAt: fulfillment?.rfxData?.createdAt,
              updatedAt: fulfillment?.rfxData?.updatedAt
            }
          : null;
      })
      .filter((item) => item !== null),
    thunesData: orderData?.orderFulfillment
      .map((fulfillment) => {
        return fulfillment?.thunesData
          ? fulfillment?.thunesData?.map((data) => ({
              quoteExpiry: data?.quoteExpiry,
              thunesFee: data?.thunesFee,
              transactionType: data?.transactionType,
              sentCurrency: data?.sentCurrency,
              sentAmount: data?.sentAmount,
              destinationCurrency: data?.destinationCurrency,
              destinationAmount: data?.destinationAmount,
              transactionId: data?.transactionId,
              purposeOfRemittance: data?.purposeOfRemittance,
              beneficiaryFirstName: data?.beneficiaryFirstName,
              beneficiaryLastName: data?.beneficiaryLastName,
              transactionStatus: data?.transactionStatus,
              transactionPreviousStatus: data?.transactionPreviousStatus,
              transactionSubStatus: data?.transactionSubStatus,
              wholesaleFxRate: data?.wholesaleFxRate,
              createdAt: data?.createdAt,
              updatedAt: data?.updatedAt,
              fulfillmentId: fulfillment?.fulfillmentId
            }))
          : null;
      })
      .filter((item) => item !== null),
    luluData: orderData?.orderFulfillment
      ?.map((fulfillment) => {
        return fulfillment?.luluData
          ? {
              ...fulfillment?.luluData
            }
          : null;
      })
      .filter((item) => item !== null),
    thunesCollectionData: orderData?.orderPayment?.thunesCollectionData
      ? {
          amount: orderData.orderPayment.thunesCollectionData.amount,
          currency: orderData.orderPayment.thunesCollectionData.currency,
          customerFirstName:
            orderData.orderPayment.thunesCollectionData.customerFirstName,
          email: orderData.orderPayment.thunesCollectionData.email,
          previousStatus:
            orderData.orderPayment.thunesCollectionData.previousStatus,
          status: orderData.orderPayment.thunesCollectionData.status,
          createdAt: orderData.orderPayment.thunesCollectionData.createdAt,
          updatedAt: orderData.orderPayment.thunesCollectionData.updatedAt
        }
      : null,
    offRampManual: orderData?.orderFulfillment
      .map((fulfillment) => fulfillment.offRampManualFulfillment)
      .filter((item) => item !== null)
      .reduce((prev, curr) => {
        return [...prev, ...curr];
      }, []),
    offRampData: orderData?.orderFulfillment
      .map((fulfillment) => fulfillment.offRampFulfillment)
      .filter((item) => item !== null)
      .reduce((prev, curr) => {
        return [...prev, ...curr];
      }, []),
    yblFulfillmentData: orderData?.orderFulfillment
      .map((fulfillment) => fulfillment.yblFulfillment)
      .filter((item) => item !== null)
      .reduce((prev, curr) => {
        return [...prev, ...curr];
      }, []),
    metadata: toOrderMetadata(orderData.orderMetadata),
    payments: orderData.payments
      ? orderData.payments.map((payment) => ({
          ...payment
        }))
      : null,
    opsTicket: orderData?.opsTicket || null
  };
};

export const toCampaignData = (campaign) => ({
  id: campaign?.id,
  expiryDate: campaign?.expiryDate
    ? formatDateWithoutTime(campaign?.expiryDate)
    : null,
  freeTransferReward: campaign?.freeTransferReward,
  campaignRewardCondition: campaign?.campaignRewardCondition,
  rewardConditionTransferAmount: campaign?.rewardConditionTransferAmount,
  referralCode: campaign?.referralCode
});

export const toOrderMetadata = (metadata) => ({
  ...metadata,
  updatedByEmail: metadata?.updateByEmail,
  createdAt: formatDate(metadata?.createdAt),
  updatedAt: formatDate(metadata?.updatedAt)
});

export const toHolidayData = (holiday) => {
  return {
    ...holiday,
    startTime: formatDateUtc(holiday.startTime),
    endTime: formatDateUtc(holiday.endTime),
    settlementDay: formatDateUtc(holiday.settlementDay)
  };
};

//check if a value is falsy, excluding 0
export const doesExist = (value) => value !== null && value !== undefined;

//for development
export const dummyApi = (response, error, timeout = 2000) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      if (response)
        return resolve({
          data: response
        });
      else return reject(error);
    }, timeout);
  });

export const initializeDeviceId = () => {
  let deviceId = localStorage.getItem('device-id');
  if (deviceId) return;

  deviceId = uuidv4();

  localStorage.setItem('device-id', deviceId);
};

export const toNrAccountOnboardingDetails = (onboarding) => ({
  ...onboarding
});

const isValidDate = (dateStr) => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}\+\d{2}:\d{2}$/;
  return dateRegex.test(dateStr);
};

export const toScreenDataList = (screenData) => ({
  ...Object.keys(screenData)
    .map((key) => ({
      key: camelCaseToSpaceSeparated(key),
      value:
        key === 'filePath'
          ? {
              value: 'Open File',
              isFile: true,
              fileUrl: screenData[key]
            }
          : isValidDate(screenData[key])
          ? {
              value: formatDate(screenData[key])
            }
          : {
              value: screenData[key]
            }
    }))
    .reduce(
      (prev, curr) => ({
        ...prev,
        [curr.key]: curr.value
      }),
      {}
    )
});

export const toWorkflowScreenDataList = (screenData) => {
  const fileFields = screenData?.documentsList
    ? Object.keys(screenData.documentsList)
        .map((key) => ({
          key: camelCaseToSpaceSeparated(key),
          value: {
            value: 'Open File',
            isFile: true,
            fileUrl: screenData.documentsList[key]
          }
        }))
        .reduce(
          (prev, curr) => ({
            ...prev,
            [curr.key]: curr.value
          }),
          {}
        )
    : {};

  let res = !screenData
    ? {}
    : {
        ...Object.keys(screenData)
          .map((key) => ({
            key: camelCaseToSpaceSeparated(key),
            value: isValidDate(screenData[key])
              ? {
                  value: formatDate(screenData[key])
                }
              : {
                  value: screenData[key]
                }
          }))
          .reduce(
            (prev, curr) => ({
              ...prev,
              [curr.key]: curr.value
            }),
            {}
          )
      };

  delete res['Documents List'];

  res = {
    ...res,
    ...fileFields
  };
  return res;
};

/* camelcase to space seperated word, considering abbreviated words like PANCard */
export const camelCaseToSpaceSeparated = (text) => {
  if (!text?.length) return text;
  let arr = [],
    lastCap = 0;

  for (let i = 0; i < text.length; i++) {
    if (
      text[i] === text[i].toLowerCase() &&
      i >= 1 &&
      text[i - 1] === text[i - 1].toUpperCase()
    ) {
      arr.push(text.slice(lastCap, i - 1));
      lastCap = i - 1;
    }
  }
  arr.push(text.slice(lastCap));
  arr[0] = arr[0].slice(0, 1).toUpperCase() + arr[0].slice(1);

  return arr.join(' ');
};

export const kebabCaseToSpaceSeperate = (text) => {
  if (!text) return text;
  return text.split('_').join(' ');
};

export const toSentenceCase = (str) => {
  if (!str) return str;
  return str.toLowerCase().replace(/^(.)|\s(.)/g, function (match) {
    return match.toUpperCase();
  });
};

export function isObject(value) {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

const isLowerCase = (l) => l.toUpperCase() !== l && l.toLowerCase() === l;
const isUpperCase = (l) => l.toUpperCase() === l && l.toLowerCase() !== l;

export const camelizeObjectKeys = (obj) => {
  let resObj = {};

  Object.keys(obj).forEach((key) => {
    if (!isObject(obj[key])) {
      resObj = {
        ...resObj,
        [camelizeString(key)]: obj[key] //todo: update with camelized string
      };
    } else {
      resObj = {
        ...resObj,
        [camelizeString(key)]: camelizeObjectKeys(obj[key]) //todo update key
      };
    }
  });

  return resObj;
};

const camelizeString = (str) => {
  let wordStartInd = 0;
  const arr = [];
  for (let i = 1; i < str.length; i++) {
    if (isUpperCase(str[i]) && isLowerCase(str[i - 1])) {
      arr.push(str.slice(wordStartInd, i));
      wordStartInd = i;
    } else if (str[i] === '_') {
      arr.push(str.slice(wordStartInd, i));
      wordStartInd = i + 1;
    } else if (
      isUpperCase(str[i]) &&
      isUpperCase(str[i - 1]) &&
      i + 1 < str.length &&
      isLowerCase(str[i + 1])
    ) {
      arr.push(str.slice(wordStartInd, i));
      wordStartInd = i;
    }
  }

  if (str.slice(wordStartInd)?.length) arr.push(str.slice(wordStartInd));

  let res = '';

  for (let i = 0; i < arr.length; i++) {
    if (isUpperCase(arr[i])) {
      let j = i;

      while (j < arr.length && isUpperCase(arr[j])) {
        j++;
      }

      res = res + arr.slice(i, j).join('_');
      i = j - 1;
    } else {
      res =
        res +
        (i === 0 ? arr[i] : arr[i].slice(0, 1).toUpperCase() + arr[i].slice(1));
    }
  }

  return res;
};

export const getDefaultProduct = () => PRODUCT.REMITTANCE;

export const getDefaultKycProviderForProduct = (product) => {
  switch (product) {
    case PRODUCT.REMITTANCE:
      return KYC_PROVIDER.COMPLY_CUBE;
    case PRODUCT.INVEST_MF:
    case PRODUCT.INVEST_MF_ADD_NRI_BANK:
      return KYC_PROVIDER.TARRAKKI;
    default:
      return null;
  }
};

export const getDefaulProductForKycProvider = (provider) => {
  switch (provider) {
    case KYC_PROVIDER.TARRAKKI:
      return PRODUCT.INVEST_MF;
    default:
      return PRODUCT.REMITTANCE;
  }
};

export const getErrorMessage = ({
  error,
  genericMessage = 'Something went wrong!'
}) =>
  error?.data
    ? JSON.parse(error.data)?.message
    : error.message || genericMessage;

export const toInvestmentOrderData = (order) =>
  !order
    ? null
    : {
        ...order,
        createdAt: formatDate(order.createdAt),
        updatedAt: formatDate(order.updatedAt)
        // isSipOrder: doesExist(order.sipId)
      };

export const toInvestmentSipData = (sip) =>
  !sip
    ? null
    : {
        ...sip,
        createdAt: formatDate(sip.createdAt),
        updatedAt: formatDate(sip.updatedAt)
      };

export const getInvestmentOrderStatusColor = (status) => {
  switch (status) {
    case 'FAILED':
      return 'red';
    case 'COMPLETED':
      return 'brand.500';
    default:
      return 'white';
  }
};

export const getSipStatusColor = (status) => {
  switch (status) {
    case 'CANCELLED':
      return 'red';
    case 'ACTIVE':
      return 'brand.500';
    default:
      return 'white';
  }
};

export const getProductsForActiveVerificationSearch = (activeSearch) => {
  switch (activeSearch) {
    case VERIFICATIONS_ACTIVE_SEARCH.REMITTANCE:
      return [PRODUCT.REMITTANCE];
    case VERIFICATIONS_ACTIVE_SEARCH.INVESTMENTS:
      return [PRODUCT.INVEST_MF, PRODUCT.INVEST_MF_ADD_NRI_BANK];
    default:
      return [];
  }
};

export const getProvidersForActiveVerificationSearch = (activeSearch) => {
  switch (activeSearch) {
    case VERIFICATIONS_ACTIVE_SEARCH.REMITTANCE:
      return [
        KYC_PROVIDER.COMPLY_CUBE,
        KYC_PROVIDER.SABER,
        KYC_PROVIDER.EFR,
        KYC_PROVIDER.ON_RAMP_MONEY,
        KYC_PROVIDER.BAERSCREST,
        KYC_PROVIDER.SUMSUB

        // KYC_PROVIDER.ENCRYPTUS
      ];
    case VERIFICATIONS_ACTIVE_SEARCH.INVESTMENTS:
      return [KYC_PROVIDER.TARRAKKI];
    default:
      return [];
  }
};

export const getTextForProduct = (product) => {
  switch (product) {
    case PRODUCT.REMITTANCE:
      return 'Remittance';
    case PRODUCT.INVEST_MF:
      return 'Kyc Verification';
    case PRODUCT.INVEST_MF_ADD_NRI_BANK:
      return 'Bank Verification';
    default:
      return '';
  }
};

export const getTextForProvider = (provider) => {
  switch (provider) {
    case KYC_PROVIDER.COMPLY_CUBE:
      return 'Comply Cube';
    case KYC_PROVIDER.EFR:
      return 'EFR';
    case KYC_PROVIDER.SABER:
      return 'Saber';
    case KYC_PROVIDER.TARRAKKI:
      return 'Tarrakki';
    case KYC_PROVIDER.ON_RAMP_MONEY:
      return 'On Ramp Money';
    case KYC_PROVIDER.ENCRYPTUS:
      return 'Encryptus';
    case KYC_PROVIDER.BAERSCREST:
      return 'Baerscrest';
    case KYC_PROVIDER.SUMSUB:
      return 'Sumsub';
    default:
      return '';
  }
};

export const toSearchInvestmentOrdersDto = ({
  pageNo = 0,
  pageSize = 10,
  query,
  filterKey,
  filterValue
}) => ({
  pageNo,
  pageSize,
  query: query?.length ? query : null,
  filters:
    filterKey?.length && filterValue?.length
      ? [
          {
            filterKey,
            searchOperation: 'EQUALS',
            values: [filterValue]
          }
        ]
      : []
});

export const getVerificationTitle = (product, provider) => {
  switch (`${product}:${provider}`) {
    case `${PRODUCT.REMITTANCE}:${KYC_PROVIDER.RFX}`:
      return 'RFX Kyc';
    case `${PRODUCT.REMITTANCE}:${KYC_PROVIDER.COMPLY_CUBE}`:
      return 'Comply Cube Kyc';
    case `${PRODUCT.REMITTANCE}:${KYC_PROVIDER.ON_RAMP_MONEY}`:
      return 'On Ramp Money Kyc';
    case `${PRODUCT.INVEST_MF}:${KYC_PROVIDER.TARRAKKI}`:
      return 'Investment Kyc';
    case `${PRODUCT.INVEST_MF_ADD_NRI_BANK}:${KYC_PROVIDER.TARRAKKI}`:
      return 'Investment Bank';
    case `${PRODUCT.REMITTANCE}:${KYC_PROVIDER.VANCE}`:
      return 'Vance Kyc';
    default:
      return '';
  }
};

export const isPlainObject = (value) => {
  return Object.prototype.toString.call(value) === '[object Object]';
};

export const mapJobsReqData = (searchReqData) => {
  return {
    ...searchReqData,
    filters: Object.keys(searchReqData.filters)
      .map((filterKey) => ({
        filterKey: filterKey,
        values: searchReqData.filters[filterKey]?.length
          ? [searchReqData.filters[filterKey]]
          : null
      }))
      .filter((filter) => filter.values !== null)
  };
};

export const formatToInputDisplayDate = (dateString) => {
  if (!dateString) return '';
  let dateObj = new Date(dateString);

  try {
    var dateOptions = {
      timeZone: 'UTC',
      minute: 'numeric',
      hour: 'numeric',
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };
    dateObj = dateObj.toLocaleString('en-US', dateOptions);
  } catch (error) {
    return '';
  }
  dateObj = new Date(dateString);
  let year = dateObj.getFullYear();
  year = '0'.repeat(4 - year.toString().length) + year;
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const formatFromInputDisplayDate = (dateString) => {
  if (dateString === '') return '';
  const dateTime = dateString + 'T00:00';
  return new Date(dateTime + 'Z').toISOString();
};
export const getInputDisplayDate = (date) => {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getUTCDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const toWorkflowScreenDataListV2 = (screenData) => {
  const { uploadedDocs = null, screenId, ...screen } = screenData;

  const result = Object.keys(screen)
    .map((key) => ({
      key: camelCaseToSpaceSeparated(key),
      value: screen[key]
    }))
    .reduce((prev, curr) => {
      return {
        ...prev,
        [curr.key]: {
          value: curr.value
        }
      };
    }, {});

  if (!uploadedDocs) return result;

  const files = Object.keys(uploadedDocs)
    .map((key) => ({
      key: camelCaseToSpaceSeparated(key),
      value: {
        isFile: true,
        value: 'Open File',
        fileUrl: uploadedDocs[key]
      }
    }))
    .reduce((prev, curr) => {
      return {
        ...prev,
        [curr.key]: curr.value
      };
    }, {});

  return { ...result, ...files };
};

export function camelCaseKeys(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(camelCaseKeys);
  }

  return Object.keys(obj).reduce((acc, key) => {
    if (key.split('_').length === 1) {
      acc[key] = camelCaseKeys(obj[key]);
      return acc;
    }
    const camelKey = key
      .toLowerCase()
      .replace(/[_-]\w/g, (match) => match[1].toUpperCase())
      .replace(/[-_]/g, '');
    acc[camelKey] = camelCaseKeys(obj[key]);
    return acc;
  }, {});
}

export function decamelizeAndUpperCase(str) {
  return str.replace(/([a-z\d])([A-Z])/g, '$1_$2').toUpperCase();
}

export function camelCaseString(str) {
  return str
    .toLowerCase()
    .replace(/[_-]\w/g, (match) => match[1].toUpperCase())
    .replace(/[-_]/g, '');
}

export function transformToNrobFunnelReqData({
  query = null,
  pageNo = 0,
  pageSize = 20,
  /* regular filters */
  filters = {
    country: null
  },
  /* additional filters */
  additionalFilters = null
}) {
  let countryValues = [];
  // TODO: create a mapper for each filter
  const { country, funnelState, funnelSubstate } = filters;
  if (!country) countryValues = [NR_OB_COUNTRY.AE, NR_OB_COUNTRY.GB];
  else if (country === NR_OB_COUNTRY.AE) countryValues = [NR_OB_COUNTRY.AE];
  else if (country === NR_OB_COUNTRY.GB) countryValues = [NR_OB_COUNTRY.GB];

  const hasAdditionalFilters = !additionalFilters
    ? false
    : Object.values(additionalFilters).reduce((prev, curr) => {
        return prev && curr;
      }, true);

  function getFunnelStateFilter(activeStep, subStateVal) {
    if (!subStateVal) return null;
    switch (activeStep) {
      case NROB_ACTIVE_STEPS.APPLICATION_PROCESSING:
        return {
          filter_key: 'APPLICATION_FILLING_STATUS',
          operation: 'EQUALS',
          values: [subStateVal]
        };
      default:
        return null;
    }
  }

  const funnelSubStateFilter = getFunnelStateFilter(
    funnelState,
    funnelSubstate
  );

  const reqFilters = !funnelSubStateFilter
    ? [
        {
          filter_key: 'COUNTRY',
          search_operation: 'EQUALS',
          values: countryValues
        },
        {
          filter_key: 'ACTIVE_STEP',
          search_operation: 'EQUALS',
          values: [funnelState]
        }
      ]
    : [
        {
          filter_key: 'COUNTRY',
          search_operation: 'EQUALS',
          values: countryValues
        },
        {
          filter_key: 'ACTIVE_STEP',
          search_operation: 'EQUALS',
          values: [funnelState]
        },
        {
          ...funnelSubStateFilter
        }
      ];

  return {
    query,
    pageNo,
    pageSize,
    filters: reqFilters,
    ...(hasAdditionalFilters ? { additionalFilters } : {})
  };
}

export const getRejectReasonsBasedOnDoc = (docType) => {
  switch (docType) {
    case 'BANK_STATEMENT':
      return ['Date out of range', 'Not a valid bank statement'];
    case 'PASSPORT_FRONT':
    case 'PASSPORT_BACK':
      return [
        'Incorrect document provided',
        'Passport information not visible (Blurry or unclear image)',
        'Expired passport',
        'Tampered passport',
        'Expiry date is less than 6 months',
        'Uploaded a screenshot'
      ];
    case 'BRP_FRONT':
    case 'BRP_BACK':
      return [
        'Blurry or unclear image',
        'BRP information not visible (Blurry or unclear image)',
        'Expired BRP',
        'Uploaded invalid document',
        'Expiry date is less than 6 months',
        'Uploaded a screenshot'
      ];
    case 'SIGNATURE':
    case 'SELFIE':
      return [
        'Poor image quality (blurry or low resolution)',
        'Signature not matching with passport',
        'Face not clearly visible or obscured',
        'Selfie does not match the photo in the provided ID document',
        'Invalid Selfie'
      ];
    case 'EMIRATES_ID':
      return [
        'Expired Emirates ID',
        'Invalid document provided',
        'Blurry or unclear Image',
        'Expiry date is less than 6 months'
      ];
    default:
      return [];
  }
};

export function configCountryCodeLookup(countryCode) {
  const lookup = Object.keys(CONFIG_PROVIDER_LOOKUP).reduce((prev, curr) => {
    const providers = CONFIG_PROVIDER_LOOKUP[curr];

    if (providers.length === 0)
      return {
        ...prev,
        [curr]: {
          code: curr,
          provider: null
        }
      };

    const providerTags = providers.reduce((prev, provider) => {
      return {
        ...prev,
        [`${curr}_${provider}`]: {
          code: curr,
          provider
        }
      };
    }, {});

    return {
      ...prev,
      [curr]: {
        code: curr,
        provider: null
      },
      ...providerTags
    };
  }, {});

  return lookup[countryCode];
}

export function transformCountryCodesForConfig(countries) {
  return countries
    ?.map((country) => {
      if (CONFIG_PROVIDER_LOOKUP[country].length === 0)
        return [
          {
            country,
            provider: null
          }
        ];
      return [
        ...CONFIG_PROVIDER_LOOKUP[country]?.map((provider) => {
          return { country, provider };
        }),
        { country, provider: null }
      ];
    })
    .reduce((prev, curr) => {
      return [...prev, ...curr];
    }, []);
}

export function convertHoursToHoursAndMinutes(hours) {
  // Split the hours into integer and fractional parts
  var integerHours = Math.floor(hours);
  var fractionalHours = hours - integerHours;

  // Calculate minutes from the fractional part
  var minutes = Math.round(fractionalHours * 60);

  // Return formatted result
  return integerHours + ' hrs ' + minutes + ' minutes';
}

export function getCountryFromCode(countryCode) {
  switch (countryCode) {
    case COUNTRY_CODE['+44']:
      return `${COUNTRIES.GB} - United Kingdom`;
    case COUNTRY_CODE['+971']:
      return `${COUNTRIES.AE} - United Arab Emarites`;
    case COUNTRY_CODE['+49']:
      return `${COUNTRIES.DE} - Germany`;
    case COUNTRY_CODE['+33']:
      return `${COUNTRIES.FR} - France`;
    case COUNTRY_CODE['+31']:
      return `${COUNTRIES.NL} - Netherlands`;
    case COUNTRY_CODE['+351']:
      return `${COUNTRIES.PT} - Portugal`;
    case COUNTRY_CODE['+34']:
      return `${COUNTRIES.ES} - Spain`;
    case COUNTRY_CODE['+353']:
      return `${COUNTRIES.IE} - Ireland`;
    case COUNTRY_CODE['+39']:
      return `${COUNTRIES.IT} - Italy`;
    default:
      return '-';
  }
}

export const getParsedObj = (value) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
};

export const getNumberFormatBasedOnCountry = (country) => {
  switch (country.toUpperCase()) {
    case 'INDIA':
      return {
        formatCode: 'en-IN',
        currency: 'INR'
      };
    case 'UK':
      return {
        formatCode: 'en-US',
        currency: 'GBP'
      };
  }
};

export const formatNumberWithCommas = (number, precision = 2) => {
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
    });
    return formatter.format(number);
}
