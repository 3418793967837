import {
  RFI_ALLOWED_ITEMS,
  RFI_REQUESTED_FOR,
  RFI_REQUESTED_INFO,
  RFI_STATUS
} from './constants';

export const getRFIStatusColor = (status) => {
  if (status === RFI_STATUS.VERIFIED) return 'green';
  else if (
    status === RFI_STATUS.EXPIRED ||
    status === RFI_STATUS.FAILED ||
    status === RFI_STATUS.REJECTED
  )
    return 'red';
  else return 'yellow.500';
};

export const getRfiRequestedItems = () => {
  return Object.keys(RFI_ALLOWED_ITEMS).flatMap((requestForKey) =>
    RFI_ALLOWED_ITEMS[requestForKey].flatMap((info) => ({
      requestedFor: requestForKey,
      requestedInfo: info.requestedInfo,
      title: info.title
    }))
  );
};
