import api from '../../api';
import { BENEFICIARY_VERIFICATIONS_CONSTANTS as types } from '../constants';
import { setAlert } from './alertActions';


// For getting all the beneficiary verification list
export const getBeneficiaryVerifications = ({
   payload,
   onSuccess,
   onComplete
}) => async (dispatch) => {
    try {
        dispatch({
            type: types.GETTING_BENEFICIARY_VERIFICATIONS,
        })

        const { data } = await api.beneficiaryVerifications.getBeneficiaryVerifications({ payload });

        if(onSuccess) {
            onSuccess(data);
        }

        dispatch({
            type: types.GET_BENEFICIARY_VERIFICATIONS_SUCCESS,
            payload: data.result,
        })
    } catch(error) {
        dispatch(
            setAlert({
                title: error?.message,
                status: 'error'
            })
        );
        dispatch({
            type: types.GET_BENEFICIARY_VERIFICATIONS_FAILURE,
            payload: error.message
        });
    } finally {
        onComplete && onComplete();
    }
}

// For getting a beneficiary verification detail by id
export const getBeneficiaryVerificationDetails = ({
  verificationId,
  onSuccess,
  onComplete
}) => async (dispatch) => {
    try {
        dispatch({
            type: types.GETTING_BENEFICIARY_VERIFICATION_DETAIL,
        });

        const { data } = await api.beneficiaryVerifications.getBeneficiaryVerificationDetails({verificationId});

        if(onSuccess) {
            onSuccess(data);
        }

        dispatch({
            type: types.GET_BENEFICIARY_VERIFICATION_SUCCESS_DETAIL,
            payload: data
        });
    } catch(error) {
        dispatch(
            setAlert({
                title: error?.message,
                status: 'error'
            })
        );
        dispatch({
            type: types.GET_BENEFICIARY_VERIFICATION_DETAIL_FAILURE,
            payload: error?.message
        });
    } finally {
        onComplete && onComplete();
    }
}

//For force update
export const forceUpdateBeneficiaryVerification = ({
   payload,
   onSuccess,
   onComplete
}) => async (dispatch) => {
    try {
        dispatch({
            type: types.FORCE_UPDATING_BENEFICIARY_VERIFICATION,
        });

        const { data } = await api.beneficiaryVerifications.forceUpdateBeneficiaryVerification({ payload });

        if(onSuccess) {
            onSuccess(data);
        }

        dispatch({
            type: types.FORCE_UPDATE_BENEFICIARY_VERIFICATION_SUCCESS,
            payload: data
        });
        dispatch(
          setAlert({
              title: "Successfully updated the bank account type",
              status: 'success'
          })
        );
    } catch(error) {
        dispatch(
            setAlert({
                title: error?.message,
                status: 'error'
            })
        );
        dispatch({
            type: types.FORCE_UPDATE_BENEFICIARY_VERIFICATION_FAILURE,
            payload: error?.message
        });
    } finally {
        onComplete && onComplete();
    }
}

// Retry Beneficiary Verification
export const retryBeneficiaryVerification = ({
 payload,
 onSuccess,
 onComplete
}) => async (dispatch) => {
    try {
        dispatch({
            type: types.RETRYING_BENEFICIARY_VERIFICATION,
        });

        const { data } = await api.beneficiaryVerifications.retryBeneficiaryVerification({ payload });

        if(onSuccess) {
            onSuccess(data);
        }

        dispatch({
            type: types.RETRY_BENEFICIARY_VERIFICATION_SUCCESS,
            payload: data
        });
    } catch(error) {
        dispatch(
            setAlert({
                title: error?.message,
                status: 'error'
            })
        );
        dispatch({
            type: types.RETRYING_BENEFICIARY_VERIFICATION,
            payload: error?.message
        });
    } finally {
        onComplete && onComplete();
    }
}