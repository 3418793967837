import React, { useState } from 'react';
import ContentLayout from '../../layouts/ContentLayout';
import {
  Box,
  Divider,
  Grid,
  Heading,
  Tab,
  TabList,
  Tabs,
  VStack,
  Spinner
} from '@chakra-ui/react';
import { TAB_STATES, TAB_STATES_ROLES } from './constant';
import { ClientDashboard } from './ClientDashboard';
import { TransactionDashboard } from './TransactionDashboard';
import EntityDetails from '../../components/FalconServiceDashboard/EntityDetails';
import FalconTransactionCard from '../../components/FalconTransactionCard/FalconTransactionCard';
import { VendorDashboard } from './VendorDashboard';
import { useDispatch, useSelector } from 'react-redux';
import {
  clientEntityConfig,
  rfiEntityConfig,
  vendorEntityConfig
} from './utils';
import { useEffect } from 'react';
import { FALCON_DASHBOARD_CONSTANTS } from '../../store/constants';
import { getDashboardConfig } from '../../store/actions/falconDashboardActions';
import useAuthRole from '../../hooks/useAuthRole';
import { RFITransactionDashboard } from './RFITransactionDashboard';
import { kebabCaseToSpaceSeperate } from '../../utils';

const FalconServiceDashboard = () => {
  const [selectedTab, setSelectedTab] = useState(TAB_STATES.TRANSACTIONS);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [selectedRfiTransaction, setSelectedRfiTransaction] = useState(null);
  const [renderTrigger, setRenderTrigger] = useState(false);
  const client = useSelector((state) => state.falconDashboard.client);
  const transactionsConfig = useSelector(
    (state) => state.falconDashboard.transactionDashboard.configs
  );
  const vendor = useSelector((state) => state.falconDashboard.vendor);
  const rfi = useSelector((state) => state.falconDashboard.rfiTransaction);
  const { hasAnyAuthority } = useAuthRole();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getDashboardConfig({
        onSuccess: (data) => {
          dispatch({
            type: FALCON_DASHBOARD_CONSTANTS.GET_DASHBOARD_CONFIG_SUCCESS,
            payload: { data: data.configs }
          });
          dispatch({
            type: FALCON_DASHBOARD_CONSTANTS.GET_TRANSACTION_CONFIGS_SUCCESS,
            payload: {
              payoutPartners: data.payoutPartners,
              statusList: data.statuses?.falcon
            }
          });
        }
      })
    );
  }, []);

  useEffect(() => {}, [renderTrigger]);
  return (
    <ContentLayout>
      <Grid
        gridTemplateColumns={{
          base: '1fr',
          lg: `7fr 0fr 3fr`
        }}
      >
        <VStack
          alignItems={'stretch'}
          gap={4}
          overflowY={{ base: 'initial', lg: 'scroll' }}
          overflowX={'hidden'}
          h={{ lg: '100vh' }}
          py={10}
          px={{ base: 4, sm: 10 }}
          // className='scroll'
        >
          <Heading size={'lg'} color={'white'}>
            Falcon Service Dashboard
          </Heading>
          <Tabs
            variant={'enclosed'}
            size={'md'}
            onChange={(e) => {
              setSelectedTab(Object.keys(TAB_STATES)[e]);
            }}
            defaultIndex={Object.keys(TAB_STATES).indexOf(selectedTab)}
          >
            <TabList color={'white'}>
              {Object.keys(TAB_STATES).map(
                (tabState, ind) =>
                  hasAnyAuthority(...TAB_STATES_ROLES[tabState]) && (
                    <Tab
                      key={ind}
                      _selected={{
                        color: 'brand.500',
                        border: '1px solid white',
                        borderBottom: '1px solid black'
                      }}
                    >
                      {kebabCaseToSpaceSeperate(tabState)}
                    </Tab>
                  )
              )}
            </TabList>
          </Tabs>
          {selectedTab === TAB_STATES.CLIENTS && (
            <ClientDashboard setSelectedClient={setSelectedClient} selectedClient={selectedClient} />
          )}
          {selectedTab === TAB_STATES.TRANSACTIONS && (
            <TransactionDashboard
              selectedTransaction={selectedTransaction}
              setSelectedTransaction={setSelectedTransaction}
            />
          )}
          {selectedTab === TAB_STATES.VENDORS && (
            <VendorDashboard setSelectedVendor={setSelectedVendor} selectedVendor={selectedVendor} />
          )}
          {selectedTab === TAB_STATES.RFI_TRANSACTIONS && (
            <RFITransactionDashboard
              setSelectedRfiTrxs={setSelectedRfiTransaction}
              selectedTrx={selectedRfiTransaction}
            />
          )}
        </VStack>
        <Divider />
        <Box pr={2} width="fit-content" height={'100vh'} overflow={'scroll'}>
          {selectedClient && selectedTab === TAB_STATES.CLIENTS && (
            <EntityDetails
              isSidebar={true}
              entity={selectedClient}
              client={client}
              config={clientEntityConfig}
            />
          )}
          {selectedVendor && selectedTab === TAB_STATES.VENDORS && (
            <EntityDetails
              isSidebar={true}
              entity={selectedVendor}
              client={vendor}
              config={vendorEntityConfig}
            />
          )}
          {selectedTransaction?.transaction &&
            selectedTab === TAB_STATES.TRANSACTIONS &&
            (!selectedTransaction.isLoading ? (
              <FalconTransactionCard
                falconTransaction={selectedTransaction.transaction}
                recipient={selectedTransaction.recipient}
                payout={selectedTransaction.payout}
                isSidebar={true}
                setRenderTrigger={setRenderTrigger}
                configs={transactionsConfig}
              />
            ) : (
              <Spinner
                align="center"
                color="brand.400"
                size="xl"
                thickness="4px"
              />
            ))}
          {selectedRfiTransaction &&
            selectedTab === TAB_STATES.RFI_TRANSACTIONS && (
              <EntityDetails
                isSidebar={true}
                entity={selectedRfiTransaction}
                client={rfi}
                config={rfiEntityConfig}
              />
            )}
        </Box>
      </Grid>
    </ContentLayout>
  );
};

export default FalconServiceDashboard;
