import { ROLES } from '../../constants';

export const TAB_STATES_ROLES = {
  TRANSACTIONS: [
    ROLES.SUPPORT_ROLE,
    ROLES.ADMIN_ROLE,
    ROLES.FALCON_ADMIN,
    ROLES.FALCON_DEVELOPER
  ],
  RFI_TRANSACTIONS: [
    ROLES.ADMIN_ROLE,
    ROLES.FALCON_ADMIN,
    ROLES.FALCON_OPS_MANAGER,
    ROLES.FALCON_DEVELOPER
  ],
  CLIENTS: [
    ROLES.ADMIN_ROLE,
    ROLES.FALCON_CLIENT_MANAGER,
    ROLES.FALCON_ADMIN,
    ROLES.FALCON_DEVELOPER
  ],
  VENDORS: [
    ROLES.ADMIN_ROLE,
    ROLES.FALCON_VENDOR_MANAGER,
    ROLES.FALCON_ADMIN,
    ROLES.FALCON_DEVELOPER
  ]
};

export const TAB_STATES = {
  TRANSACTIONS: 'TRANSACTIONS',
  RFI_TRANSACTIONS: 'RFI_TRANSACTIONS',
  CLIENTS: 'CLIENTS',
  VENDORS: 'VENDORS'
};
