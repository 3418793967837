import {
  Box,
  Button,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  VStack,
  Select,
  Text,
  Textarea
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  camelCaseToSpaceSeparated,
  kebabCaseToSpaceSeperate
} from '../../utils';
import { MdOutlineCallMade } from 'react-icons/md';
import ClientManager from '../../components/FalconServiceDashboard/ClientManager';
const ForceFailTransactionModal = ({
  isLoading,
  onCancel,
  onSubmit,
  title = 'Force Fail',
  listOfAvailableClients = []
}) => {
  const [clientPriorityList, setClientPriorityList] = useState([]);
  const [comments, setComments] = useState();
  const handleSubmit = (e) => {
    e?.preventDefault();
    onSubmit &&
      onSubmit({
        comments,
        forceRoutingPartners: clientPriorityList
      });
  };
  const handleCancel = (e) => {
    e?.preventDefault();
    onCancel && onCancel();
  };
  return (
    <>
      <ModalHeader>{title}</ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <VStack overflow={'auto'} width={'full'} alignItems={'stretch'}>
            <FormLabel>Comments</FormLabel>
            <Textarea
              name={'comments'}
              onChange={(e) => {
                setComments(e?.target?.value);
              }}
            />
            <ClientManager
              setClientPriorityList={setClientPriorityList}
              listOfAvailableClients={listOfAvailableClients}
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack w="100%" justifyContent="space-between" p={4}>
            <Button
              onClick={handleCancel}
              variant="outline"
              colorScheme="red"
              isLoading={isLoading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="outline"
              colorScheme="brand"
              isLoading={isLoading}
            >
              Confirm
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default ForceFailTransactionModal;
