import {
  Box,
  Button,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  Select,
  VStack,
  Checkbox
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useIsAdmin from '../../hooks/useIsAdmin';
import ContentLayout from '../../layouts/ContentLayout';
import {
  clearCampaignDetails,
  createCampaign,
  editCampaign,
  getCampaignByReferralCode
} from '../../store/actions/referralActions';
import { CAMPAIGN_REWARD_CONDITIONS, toDate } from './utils';
import FullPageSpinner from '../../components/FullPageSpinner';
import { doesExist } from '../../utils';
import InputComponent from '../../components/Input';
import NumberInput from '../../components/NumberInput';
import { COUNTRIES } from '../../constants/v2/common';
import { getCountryFromCode } from '../../utils';
const CreateCampaign = ({ isEdit }) => {
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const { isLoading: isInitDetailsLoading, data: initDetails } = useSelector(
    (state) => state.referral.campaign.campaignDetails
  );
  const [formData, setFormData] = useState({
    referralCode: '',
    expiryDate: '',
    freeTransferReward: '',
    campaignRewardCondition: '',
    rewardConditionTransferAmount: '',
    referralLink: '',
    eligibleCountries: []
  });
  const countries = Object.keys(COUNTRIES);

  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [isInValid, setIsInValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const today = toDate();
  const expiryDate = formData.expiryDate ? toDate(formData.expiryDate) : '';
  useEffect(() => {
    if (!isEdit) return;

    dispatch(getCampaignByReferralCode(campaignId));
  }, [campaignId, isEdit, dispatch]);

  /* Inititalize formdata from existing campaign details when data has been fetched */
  useEffect(() => {
    if (isEdit && initDetails) {
      const { eligibleCountries: eligibleCountriesString, ...restInitDetails } =
        initDetails;
      const eligibleCountries = JSON.parse(eligibleCountriesString);

      setFormData((prev) => ({
        ...prev,
        ...restInitDetails,
        eligibleCountries
      }));
    }
  }, [initDetails]);

  //cleanup on dismount
  useEffect(() => {
    return () => dispatch(clearCampaignDetails());
  }, []);

  const handleChange = (e) => {
    setIsInValid(false);
    setFormErrorMessage('');
    setFormData((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.name === 'expiryDate'
          ? new Date(e.target.value).toISOString()
          : e.target.value
    }));
  };
  const handleCountryChange = (selected) => {
    setFormData((prev) => ({ ...prev, ['eligibleCountries']: selected }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validateObj = validate(formData);
    if (!validateObj.isValid) {
      setIsInValid(true);
      setFormErrorMessage(validateObj.message);
      return;
    }
    //convert strings to numbers where required
    const formattedFormData = {};
    Object.keys(formData).forEach((key) => {
      formattedFormData[key] = !isNaN(formData[key])
        ? Number(formData[key])
        : formData[key];
    });
    isEdit ? editReferral(formData) : createReferral(formData);
  };

  const validate = (formData) => {
    const isEmpty = (value) => (!value || value === '') && value !== 0;

    //if TRANSACTION_COMPLETED is selected, transfer amount is also required
    if (
      formData.campaignRewardCondition ===
        CAMPAIGN_REWARD_CONDITIONS.TRANSACTION_COMPLETED &&
      isEmpty(formData.rewardConditionTransferAmount)
    ) {
      return {
        isValid: false,
        message: `Please fill out 'Reward condition transfer amount' field`
      };
    }

    return {
      isValid: true
    };
  };

  const createReferral = (formData) => {
    setIsLoading(true);

    const onSuccess = () => {
      setIsLoading(false);
      navigate('/campaign?refresh=true', {
        replace: true
      });
    };

    const onFinish = () => setIsLoading(false);

    dispatch(createCampaign(formData, onSuccess, null, onFinish));
  };

  const editReferral = (formData) => {
    setIsLoading(true);

    const onSuccess = () => {
      setIsLoading(false);
      navigate('/campaign?refresh=true', {
        replace: true
      });
    };

    const onFinish = () => setIsLoading(false);

    dispatch(editCampaign(formData, onSuccess, null, onFinish));
  };

  if (!isAdmin) navigate('/');
  if (isInitDetailsLoading) return <FullPageSpinner />;

  return (
    <ContentLayout>
      <VStack alignItems={'flex-start'} p={10} gap={4}>
        <Heading color={'colorPrimary'}>
          {isEdit ? 'Edit' : 'Create'} Campaign
        </Heading>
        <FormControl color={'white'} maxW={'50rem'} isInvalid={isInValid}>
          <VStack
            gap={4}
            alignItems={'stretch'}
            w={'100%'}
            as={'form'}
            onSubmit={handleSubmit}
          >
            {' '}
            {/* REFERRAL CODE */}
            <Grid
              gridTemplateColumns={{
                base: '1fr',
                md: '0.4fr 0.6fr'
              }}
            >
              <FormLabel display={'flex'} alignItems={'center'} mb={0}>
                Referral code:
              </FormLabel>
              <InputComponent
                type="text"
                handleChange={handleChange}
                value={formData.referralCode}
                name={'referralCode'}
                required
                errorBorderColor="#ffffff"
                boxShadow={'none !important'}
                disabled={isEdit}
                width="100%"
                maxWidth="30rem"
              />
            </Grid>
            {/* Expiry date */}
            <Grid
              gridTemplateColumns={{
                base: '1fr',
                md: '0.4fr 0.6fr'
              }}
              alignItems={'center'}
            >
              <FormLabel display={'flex'} alignItems={'center'} mb={0}>
                Expiry Date:
              </FormLabel>
              <InputComponent
                type="date"
                value={expiryDate}
                name="expiryDate"
                handleChange={handleChange}
                cursor={'pointer'}
                min={!isEdit ? today : 'none'}
                width="100%"
                maxWidth="30rem"
                style={{
                  width: '100%',
                  maxWidth: '30rem'
                }}
              />
            </Grid>
            {/* FREE TRANSFER REWARD */}
            <Grid
              gridTemplateColumns={{
                base: '1fr',
                md: '0.4fr 0.6fr'
              }}
            >
              <FormLabel display={'flex'} alignItems={'center'} mb={0}>
                Free transfer reward:
              </FormLabel>

              <NumberInput
                min={0}
                required={true}
                handleChange={(value) =>
                  handleChange({
                    target: {
                      name: 'freeTransferReward',
                      value
                    }
                  })
                }
                value={formData.freeTransferReward}
              />
            </Grid>
            {/* CAMPAIGN REWARD CONDITION */}
            <Grid
              gridTemplateColumns={{
                base: '1fr',
                md: '0.4fr 0.6fr'
              }}
            >
              <FormLabel display={'flex'} alignItems={'center'} mb={0}>
                Campaign reward condition:
              </FormLabel>
              <Box position="relative" width={'100%'} maxW="30rem">
                <Select
                  position={'relative'}
                  placeholder="Select option"
                  bg={'black'}
                  onChange={handleChange}
                  value={formData.campaignRewardCondition}
                  name={'campaignRewardCondition'}
                  boxShadow={'none !important'}
                  required
                  size="lg"
                  color="white"
                  rounded={'lg'}
                  focusBorderColor="brand.400"
                  autoFocus={false}
                  border={'none'}
                  errorBorderColor="red.300"
                  style={{
                    border: 0,
                    background: '#414141',
                    gap: '12px',
                    width: '100%',
                    maxWidth: '30rem',
                    margin: '0',
                    borderRadius: '12px'
                  }}
                >
                  {Object.keys(CAMPAIGN_REWARD_CONDITIONS)?.map((condition) => (
                    <option value={condition} key={condition}>
                      {condition}
                    </option>
                  ))}
                </Select>
              </Box>
            </Grid>
            <Grid
              gridTemplateColumns={{
                base: '1fr',
                md: '0.4fr 0.6fr'
              }}
              alignItems={'center'}
            >
              <FormLabel display={'flex'} alignItems={'center'} mb={0}>
                Referral Link:
              </FormLabel>
              <InputComponent
                type="text"
                value={formData.referralLink}
                name="referralLink"
                handleChange={handleChange}
                cursor={'pointer'}
                width="100%"
                maxWidth="30rem"
                style={{
                  width: '100%',
                  maxWidth: '30rem'
                }}
              />
            </Grid>
            <Grid
              gridTemplateColumns={{
                base: '1fr',
                md: '0.4fr 0.6fr'
              }}
              alignItems="center"
            >
              <FormLabel display="flex" alignItems="center" mb={0}>
                Eligible Countries
              </FormLabel>
              <CheckboxGroup
                value={formData.eligibleCountries}
                onChange={handleCountryChange}
              >
                <Grid
                  gridTemplateColumns={{
                    base: '1fr',
                    sm: 'repeat(2, 1fr)',
                    md: 'repeat(3, 1fr)'
                  }}
                  gap={2}
                  maxHeight="200px"
                  overflowY="auto"
                  border="1px solid #ccc"
                  borderRadius="md"
                  p={2}
                >
                  {countries.map((country) => (
                    <Checkbox key={country} value={country}>
                      {country}
                    </Checkbox>
                  ))}
                </Grid>
              </CheckboxGroup>
            </Grid>
            {/* REWARD CONDITION TRANSFER AMOUNT */}
            {formData.campaignRewardCondition ===
              CAMPAIGN_REWARD_CONDITIONS.TRANSACTION_COMPLETED && (
              <Grid
                gridTemplateColumns={{
                  base: '1fr',
                  md: '0.4fr 0.6fr'
                }}
              >
                <FormLabel display={'flex'} alignItems={'center'} mb={0}>
                  Reward condition transfer amount:
                </FormLabel>
                <NumberInput
                  min={0}
                  step={100}
                  handleChange={(value) =>
                    handleChange({
                      target: {
                        name: 'rewardConditionTransferAmount',
                        value
                      }
                    })
                  }
                  value={formData.rewardConditionTransferAmount}
                  required
                />
              </Grid>
            )}
            <FormErrorMessage>{formErrorMessage}</FormErrorMessage>
            <Button
              colorScheme="brand"
              color="altGray.400"
              mr={3}
              size={'md'}
              type="submit"
              alignSelf={'center'}
              // onClick={handleSubmit}
              isLoading={isLoading}
            >
              Submit Now
            </Button>
          </VStack>
        </FormControl>
      </VStack>
    </ContentLayout>
  );
};

export default CreateCampaign;
