export const RFI_TYPES = {
  TRANSFER: 'TRANSFER'
};

export const RFI_STATUS = {
  REQUESTED: 'REQUESTED',
  SUBMITTED: 'SUBMITTED',
  VERIFIED: 'VERIFIED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
  FAILED: 'FAILED'
};

export const RFI_ITEMS = {
  DOCUMENT: 'DOCUMENT',
  TEXT: 'TEXT'
};

export const RFI_REQUESTED_FOR = {
  USER: 'USER',
  SOURCE_ACCOUNT: 'SOURCE_ACCOUNT',
  RECIPIENT: 'RECIPIENT',
  SOURCE_OF_FUNDS: 'SOURCE_OF_FUNDS'
};

export const RFI_REQUESTED_INFO = {
  RELATIONSHIP: 'RELATIONSHIP',
  DOCUMENT_PROOF: 'DOCUMENT_PROOF',
  ID_PROOF: 'ID_PROOF'
};

export const RFI_ALLOWED_ITEMS = {
  [RFI_REQUESTED_FOR.USER]: [
    {
      requestedInfo: RFI_REQUESTED_INFO.ID_PROOF,
      title: 'User ID Proof'
    }
  ],
  [RFI_REQUESTED_FOR.SOURCE_ACCOUNT]: [
    {
      requestedInfo: RFI_REQUESTED_INFO.ID_PROOF,
      title: 'Source Account ID Proof'
    },
    {
      requestedInfo: RFI_REQUESTED_INFO.RELATIONSHIP,
      title: 'Source Account User Relationship'
    }
  ],
  [RFI_REQUESTED_FOR.SOURCE_OF_FUNDS]: [
    {
      requestedInfo: RFI_REQUESTED_INFO.DOCUMENT_PROOF,
      title: 'Document Proof for Source of Fund'
    }
  ],
  [RFI_REQUESTED_FOR.RECIPIENT]: [
    {
      requestedInfo: RFI_REQUESTED_INFO.ID_PROOF,
      title: 'Recipient ID Proof'
    },
    {
      requestedInfo: RFI_REQUESTED_INFO.RELATIONSHIP,
      title: 'Recipient Relationship with User'
    }
  ]
};
