import dayjs from 'dayjs';

export const getVerifierDetailsTable = (verifierDetails) => {
  if (!verifierDetails) {
    return null;
  }

  return [
    {
      title: 'Verifier Details',
      data: [
        {
          name: 'Verification ID',
          isCopyable: true,
          value: verifierDetails.verificationId || 'NULL'
        },
        {
          name: 'Verifier',
          isCopyable: true,
          value: verifierDetails.verifier || 'NULL'
        },
        {
          name: 'Created At',
          value: dayjs(verifierDetails.createdAt).format('HH:mm  DD/MM/YYYY') || '-'
        },
        {
          name: 'Updated At',
          value: dayjs(verifierDetails.updatedAt).format('HH:mm  DD/MM/YYYY') || '-'
        },
        {
          name: 'External Status',
          value: (verifierDetails?.externalStatus ?? 'NULL')
        },
        {
          name: 'User Id ',
          value: verifierDetails.userId || 'NULL',
          isCopyable: true
        },
        {
          name: 'Acc. No',
          value: verifierDetails?.accountNumber,
          isCopyable: true
        },
        {
          name: 'IFSC',
          value: verifierDetails?.ifscCode,
          isCopyable: true
        },
        {
          name: 'Account Type',
          value: verifierDetails?.accountType ?? "NULL"
        },
        {
          name: 'Account Holder',
          value: verifierDetails?.accountHolderName ?? "NULL"
        },
        {
          name: 'Expiry',
          value:  dayjs(verifierDetails?.expiry).format('HH:mm DD/MM/YYYY')
        },
        {
          name: 'Verifier Res',
          value:  JSON.stringify(verifierDetails.verifierRes) ?? '-',
          isCopyable: true
        }
      ],
    }
  ];
};
