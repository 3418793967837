import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Box,
  Button,
  Select,
  List,
  ListItem,
  Flex,
  IconButton,
  Text
} from '@chakra-ui/react';
import { IoMdClose } from 'react-icons/io';
import { MdOutlineAdd, MdOutlineClose } from 'react-icons/md';

function ClientManager({ listOfAvailableClients, setClientPriorityList }) {
  const [selectedClient, setSelectedClient] = useState('');
  const [addedClients, setAddedClients] = useState([]);

  // Handle adding a client
  const addClient = () => {
    if (selectedClient && !addedClients.includes(selectedClient)) {
      setAddedClients([...addedClients, selectedClient]);
      setSelectedClient(''); // Reset the dropdown
    }
  };

  // Handle deleting a client
  const deleteClient = (client) => {
    setAddedClients(addedClients.filter((c) => c !== client));
  };

  // Handle drag-and-drop reordering
  const handleDragEnd = (result) => {
    if (!result.destination) return; // If dropped outside the list, do nothing

    const items = Array.from(addedClients);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAddedClients(items);
  };
  useEffect(() => {
    setClientPriorityList(addedClients);
  }, [addedClients]);
  return (
    <Box>
      <Text fontSize="md" mb={4}>
        Force Routing Partners List
      </Text>

      {/* Dropdown for selecting clients */}
      <Flex mb={4} gap={2}>
        <Select
          placeholder="Select a client"
          value={selectedClient}
          onChange={(e) => setSelectedClient(e.target.value)}
        >
          {listOfAvailableClients.map((client, index) => (
            <option key={index} value={client}>
              {client}
            </option>
          ))}
        </Select>
        <Button
          colorScheme="brand"
          size={'md'}
          onClick={addClient}
          isDisabled={!selectedClient}
          rightIcon={<MdOutlineAdd />}
        >
          Add
        </Button>
      </Flex>

      {/* Drag-and-Drop List */}
      <Box
        p={2}
        borderRadius="10px"
        border="1px solid white"
        maxHeight="400px"
        overflow="scroll"
      >
        {addedClients.length > 0 ? (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="clients-list">
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  spacing={3}
                  borderRadius="md"
                  p={4}
                >
                  {addedClients.map((client, index) => (
                    <Draggable key={client} draggableId={client} index={index}>
                      {(provided) => (
                        <ListItem
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          borderRadius="md"
                          p={2}
                          // bg="grey"
                          shadow="sm"
                        >
                          <Text>
                            {index + 1} - {client}
                          </Text>
                          <IconButton
                            icon={<MdOutlineClose />}
                            size="md"
                            color="red"
                            bg="transparent"
                            onClick={() => deleteClient(client)}
                            aria-label={`Delete ${client}`}
                          />
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  item -{provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <Text color="grey" size="sm" align="center">
            * No Routing Partners Selected *
          </Text>
        )}
      </Box>
    </Box>
  );
}

export default ClientManager;
