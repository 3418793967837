import React, { useEffect, useState } from 'react';
import ContentLayout from '../../layouts/ContentLayout';
import {
  Box,
  Button,
  Divider,
  Grid,
  Heading,
  HStack,
  Select,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react';
import { MdEast, MdSearch } from 'react-icons/md';
import InputComponent from '../../components/Input';
import { RFI_STATUS, RFI_TYPES } from './constants';
import useDebounce from '../../hooks/useDebounce';
import { useDispatch } from 'react-redux';
import { getRFIs } from '../../store/actions/rfiActions';
import { RFI_CONSTANTS as types } from '../../store/constants';
import { useSelector } from 'react-redux';
import Pagination from '../../components/Pagination';
import GenericCard from '../../components/GenericCard';
import { CARD_TYPE } from '../../constants';
import { useNavigate } from 'react-router-dom';
import useIsMobile from '../../hooks/useIsMobile';
import OrderDetails from '../Order';
import OrderDetailsCard from '../../components/OrderDetailsCard';

const RFITransactions = () => {
  const rfis = useSelector((state) => state.rfiDetails.rfis);
  const [filters, setFilters] = useState({});
  const [searchValue, setSearchValue] = useState(null);
  const [page, setPage] = useState(rfis.page);
  const [showOrder, setShowOrder] = useState(false);
  const debounce = useDebounce(searchValue, 500);
  const disptach = useDispatch();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const handleFilterChange = (e) => {
    setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setPage(0);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (debounce != null) setFilters((prev) => ({ ...prev, query: debounce }));
  }, [debounce]);

  useEffect(() => {
    if (filters || rfis.page >= 0) {
      handleSearch();
    }
  }, [filters, rfis.page]);

  const handleSearch = () => {
    disptach(
      getRFIs({
        reqData: { ...filters, pageSize: rfis.pageSize, page: page },
        onSuccess: (data) => {
          disptach({
            type: types.GET_RFI_ORDERS_SUCCESS,
            payload: { data }
          });
        }
      })
    );
  };

  const handlePageChange = (page) => {
    disptach({
      type: types.UPDATE_RFI_PAGE_NUMBER,
      payload: { page: page - 1 }
    });
    setPage(page - 1);
  };

  const handleOrderDetailsClick = (orderId) => {
    if (isMobile) {
      navigate(`/order/${orderId}`);
      setShowOrder(null);
    } else {
      setShowOrder(orderId);
    }
  };

  const onViewRfiClick = (orderId, rfiType, userId) => {
    navigate(`/user/${userId}/order/${orderId}/rfi/${rfiType}`)
  }

  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
        <VStack m={'1rem'} align={'flex-start'} gap={2}>
          <InputComponent
            type="text"
            value={searchValue}
            name="query"
            handleChange={handleSearchChange}
            placeholder="Search by Order ID"
            inputTitle="Search RFI"
            leftElement={<MdSearch size="2rem" color="white" />}
            width="40rem"
            maxWidth="100%"
            autoComplete={'off'}
            spellcheck={false}
            autoCorrect={'off'}
          />

          <HStack
            w={'100%'}
            justifyContent={'space-between'}
            maxW={'40rem'}
            alignSelf={'flex-start'}
          >
            <Button
              type="submit"
              color="black"
              rounded="lg"
              colorScheme="brand"
              my={5}
              py={2}
              onClick={handleSearch}
              fontWeight={600}
              isLoading={rfis.isLoading}
              alignSelf="start"
              style={{
                marginTop: '1em'
              }}
              size={{
                base: 'md',
                md: 'lg'
              }}
            >
              Search
            </Button>
            <VStack maxWidth="15rem">
              <Select
                placeholder={`Filter RFI Type`}
                onChange={handleFilterChange}
                name={'type'}
                value={filters.type}
                bg="black"
                color="gray"
                size={'sm'}
                rounded={'lg'}
                _focus={{
                  border: '1px solid #81EBAB',
                  boxShadow: 'none'
                }}
              >
                {Object.keys(RFI_TYPES).map((rfiType) => (
                  <option value={rfiType} key={rfiType}>
                    {rfiType}
                  </option>
                ))}
              </Select>
              <Select
                placeholder={`Filter RFI Status`}
                onChange={handleFilterChange}
                name={'status'}
                value={filters.status}
                bg="black"
                color="gray"
                size={'sm'}
                rounded={'lg'}
                _focus={{
                  border: '1px solid #81EBAB',
                  boxShadow: 'none'
                }}
              >
                {Object.keys(RFI_STATUS).map((rfiStatus) => (
                  <option value={rfiStatus} key={rfiStatus}>
                    {rfiStatus}
                  </option>
                ))}
              </Select>
            </VStack>
          </HStack>
          {rfis.isLoading ? (
            <Spinner color="brand.400" size="xl" thickness="4px" />
          ) : (
            <>
              {rfis.totalPages && (
                <Pagination
                  totalPages={rfis.totalPages}
                  currentPage={rfis.page + 1}
                  onPageChange={handlePageChange}
                />
              )}
              <VStack
                w={'full'}
                align={'flex-start'}
                overflow={'auto'}
                maxH={'60vh'}
                className="scroll"
              >
                {rfis.data?.map((data) => (
                  <GenericCard
                    cardData={data}
                    cardType={CARD_TYPE.RFI_FUNNEL_CARD}
                    ActionButtons={[
                      <Button
                        key={'order-details-button'}
                        mt={{ base: 2, md: 0 }}
                        ml={{ base: 0, md: 1 }}
                        rightIcon={<MdEast color="black" />}
                        rounded="lg"
                        color={'black'}
                        colorScheme="brand"
                        alignSelf={{ base: 'center', md: 'center' }}
                        px={5}
                        onClick={() => {
                          handleOrderDetailsClick(data.referenceId);
                        }}
                      >
                        Order details
                      </Button>,
                      <Text />,
                      <Button
                        key={'rfi-details-button'}
                        mt={{ base: 2, md: 0 }}
                        ml={{ base: 0, md: 1 }}
                        rightIcon={<MdEast color="black" />}
                        rounded="lg"
                        color={'black'}
                        colorScheme="brand"
                        alignSelf={{ base: 'center', md: 'center' }}
                        px={5}
                        onClick={() => {
                          onViewRfiClick(data.referenceId, data.rfiType, data.userId)
                        }}
                      >
                        RFI Details
                      </Button>
                    ]}
                  />
                ))}
              </VStack>
            </>
          )}
        </VStack>
        <Divider />
        {!isMobile && showOrder && (
          <OrderDetailsCard orderId={showOrder} isSidebar />
        )}
      </Grid>
    </ContentLayout>
  );
};

export default RFITransactions;
