import {
  Button,
  Heading,
  HStack,
  Icon,
  Input,
  ModalBody,
  ModalHeader,
  Text,
  VStack
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { RFI_STATUS } from '../../views/RFITransactions/constants';
import { getRFIStatusColor } from '../../views/RFITransactions/utils';
import { useDispatch } from 'react-redux';
import { updateRFIData } from '../../store/actions/rfiActions';
import { RFI_CONSTANTS } from '../../store/constants';

const UpdateModal = ({
  requestedItems,
  orderId,
  rfiType,
  askReason,
  status,
  onClose,
}) => {
  const [reason, setReason] = useState('');
  const disptach = useDispatch();

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleSave = () => {
    const rfiData = {
      rejectionReasons: reason,
      status: status,
      updatedItems: requestedItems
    };
    disptach(
      updateRFIData({
        orderId,
        rfiType: rfiType,
        rfiData,
        onSuccess: (data) => {
          disptach({
            type: RFI_CONSTANTS.UPDATE_RFI_SUCCESS,
            payload: { data }
          });
          onClose();
        }
      })
    );
  };

  return (
    <>
      <ModalHeader>
        <Heading size={'md'}>
          {`Update RFI: ${rfiType} for ${orderId} `}
        </Heading>
      </ModalHeader>
      <ModalBody pb={'2rem'}>
        <VStack alignItems={'start'} gap={4}>
          <HStack>
            <Text fontSize={'lg'}>RFI status will be updated to</Text>
            <HStack justifySelf={'center'}>
              <Icon
                viewBox="0 0 200 200"
                color={getRFIStatusColor(status)}
                w="8px"
              >
                <path
                  fill="currentColor"
                  d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                />
              </Icon>
              <Text fontSize={{ base: 'sm', sm: 'md' }} color="white">
                {status}
              </Text>
            </HStack>
          </HStack>
          {askReason && (
            <Input
              placeholder={`Enter the reason for ${
                status === RFI_STATUS.FAILED ? 'failure' : 'rejection'
              }`}
              colorScheme="brand"
              onChange={handleReasonChange}
            />
          )}
          Í
          <Button
            colorScheme="brand"
            color={'black'}
            alignSelf="end"
            onClick={handleSave}
            isDisabled={askReason && reason.trim().length === 0}
          >
            Save RFI
          </Button>
        </VStack>
      </ModalBody>
    </>
  );
};

export default UpdateModal;
