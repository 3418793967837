import {
  Button,
  Grid,
  Heading,
  HStack,
  ModalBody,
  ModalHeader,
  VStack,
  Text,
  Select
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import InputComponent from '../Input';
import { MdOutlineAdd, MdOutlineClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { updateClientPayoutPartners } from '../../store/actions/falconDashboardActions';

const UpdatePayoutPartner = ({ partners, onClose, fetchDetails }) => {
  const [updatedPartners, setUpdatedPartners] = useState([]);
  const [newPartner, setNewPartner] = useState({
    partnerName: '',
    retryCount: '',
    priority: ''
  });
  const [toReview, setToReview] = useState(false);
  const client = useSelector((state) => state.falconDashboard.client.data);
  const partnersList = useSelector(
    (state) => state.falconDashboard.config.data.payoutPartners
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (partners) {
      setUpdatedPartners(
        partners.map((partner) => ({
          partnerName: partner['partnerName']?.value,
          retryCount: partner['retryCount']?.value,
          priority: partner['priority']?.value
        }))
      );
    }
  }, [partners]);

  const handleReview = () => {
    setToReview(!toReview);
  };

  const addPartner = () => {
    setUpdatedPartners((preState) => [...preState, newPartner]);
    setNewPartner({
      partnerName: '',
      retryCount: '',
      priority: ''
    });
  };

  const handleRemove = (partner) => {
    setUpdatedPartners((prevState) =>
      prevState.filter((item) => item.partnerName !== partner)
    );
  };

  const handleUpdate = () => {
    const data = {
      client: client
        .filter((c) => c.heading === 'Client Details')[0]
        ?.data.filter((d) => d.title === 'Client Name')[0]?.value,
      currency: client
        .filter((c) => c.heading === 'Configurations')[0]
        ?.data.filter((d) => d.title === 'Currency')[0]?.value,
      payoutPartners: updatedPartners
    };
    dispatch(
      updateClientPayoutPartners({
        data,
        onSuccess: fetchDetails
      })
    );
    onClose();
  };

  const handleChange = (e) => {
    setNewPartner((prevState) => ({
      ...prevState,
      partnerName: e.target.value
    }));
  };

  const handleUpdatePartner = (name, value, partnerName) => {
    setUpdatedPartners((prevState) =>
      prevState.map((item) =>
        item.partnerName === partnerName ? { ...item, [name]: value } : item
      )
    );
  };

  return (
    <>
      <ModalHeader pt={0}>
        <Heading size={'lg'}>Update Payout Partners</Heading>
      </ModalHeader>
      <ModalBody pb={4}>
        <VStack alignItems={'flex-start'}>
          {!toReview && (
            <Grid
              w={'full'}
              gridTemplateColumns={`2fr 1.5fr 1.5fr 1fr`}
              alignItems={'center'}
              justifyContent={'space-between'}
              gap={1}
            >
              {partnersList ? (
                <Select
                  placeholder={'Select Partner Name'}
                  value={newPartner.partnerName}
                  onChange={handleChange}
                >
                  {partnersList.map((partner) => (
                    <option value={partner}>{partner}</option>
                  ))}
                </Select>
              ) : (
                <InputComponent
                  placeholder={'Enter Partner Name'}
                  width="100%"
                  value={newPartner.partnerName}
                  handleChange={(e) =>
                    setNewPartner((prevState) => ({
                      ...prevState,
                      partnerName: e.target.value
                    }))
                  }
                />
              )}
              <InputComponent
                placeholder={'Retry Count'}
                width="100%"
                handleChange={(e) =>
                  setNewPartner((prevState) => ({
                    ...prevState,
                    retryCount: e.target.value
                  }))
                }
                value={newPartner.retryCount}
              />
              <InputComponent
                placeholder={'Priority'}
                width="100%"
                handleChange={(e) =>
                  setNewPartner((prevState) => ({
                    ...prevState,
                    priority: e.target.value
                  }))
                }
                value={newPartner.priority}
              />
              <Button
                // ml={2}
                w={'fit-content'}
                colorScheme="brand"
                color={'black'}
                rightIcon={<MdOutlineAdd />}
                onClick={addPartner}
              >
                Add
              </Button>
            </Grid>
          )}
          <Grid
            gap={2}
            gridTemplateColumns={'0fr 2fr 2fr 1fr'}
            alignItems={'center'}
            w={'full'}
          >
            <Text />
            <Text size={'sm'} color={'gray'}>
              Partner Name
            </Text>
            <Text size={'sm'} color={'gray'}>
              Retry Count
            </Text>
            <Text size={'sm'} color={'gray'}>
              Priority
            </Text>
          </Grid>
          {updatedPartners?.map((data, id) => (
            <Grid
              gap={2}
              gridTemplateColumns={'2fr 3fr 1fr 1fr'}
              alignItems={'center'}
              w={'100%'}
            >
              {toReview ? (
                <>
                  <Text size={'sm'} color={'gray'}>
                    {id + 1}.
                  </Text>
                  <Text size={'sm'} color={'white'} wordBreak={'break-word'}>
                    {data.partnerName}
                  </Text>
                  <Text size={'sm'} color={'white'}>
                    {data.retryCount}
                  </Text>
                  <Text size={'sm'} color={'white'}>
                    {data.priority}
                  </Text>
                </>
              ) : (
                <>
                  <Button
                    variant="ghost"
                    colorScheme="brand"
                    onClick={() => handleRemove(data.partnerName)}
                  >
                    <MdOutlineClose />
                  </Button>
                  <Text size={'sm'} color={'white'} wordBreak={'break-word'}>
                    {data.partnerName}
                  </Text>
                  <InputComponent
                    placeholder={'Retry Count'}
                    width="fit-content"
                    size="sm"
                    handleChange={(e) =>
                      handleUpdatePartner(
                        e.target.name,
                        e.target.value,
                        data.partnerName
                      )
                    }
                    name={'retryCount'}
                    value={data.retryCount}
                  />
                  <InputComponent
                    placeholder={'Priority'}
                    width="fit-content"
                    size="sm"
                    handleChange={(e) =>
                      handleUpdatePartner(
                        e.target.name,
                        e.target.value,
                        data.partnerName
                      )
                    }
                    name={'priority'}
                    value={data.priority}
                  />
                </>
              )}
            </Grid>
          ))}
        </VStack>
        <HStack w="full" justifyContent={'flex-end'} mt={4}>
          <Button
            colorScheme="brand"
            onClick={handleReview}
            variant={'outline'}
          >
            {toReview ? 'Edit' : 'Update'}
          </Button>
          {toReview && (
            <Button color={'black'} colorScheme="brand" onClick={handleUpdate}>
              Update
            </Button>
          )}
        </HStack>
      </ModalBody>
    </>
  );
};

export default UpdatePayoutPartner;
