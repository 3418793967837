import React, { useState } from 'react';
import {
  Box,
  Heading,
  Select,
  SimpleGrid,
  FormControl,
  FormLabel
} from '@chakra-ui/react';

const FiltersAndSort = ({ filtersBody, onChange }) => {
  return (
    <Box p={4}>
      <Heading as="h2" size="md" mb={4}>
        Filters and Sort
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 4 }} spacing={4}>
        {/* Status Filter */}
        <FormControl>
          <FormLabel>Status</FormLabel>
          <Select
            placeholder="Select status"
            onChange={onChange}
            value={filtersBody.status}
            name="status"
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </Select>
        </FormControl>

        {/* Priority Filter */}
        <FormControl>
          <FormLabel>Priority</FormLabel>
          <Select
            placeholder="Select priority"
            onChange={onChange}
            value={filtersBody.priority}
            name="priority"
          >
            <option value="high">High</option>
            <option value="medium">Medium</option>
            <option value="low">Low</option>
          </Select>
        </FormControl>

        {/* Volume Range Filter */}
        <FormControl>
          <FormLabel>Volume Range</FormLabel>
          <Select
            placeholder="Select volume range"
            onChange={onChange}
            value={filtersBody.volumeRange}
            name="volumeRange"
          >
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
          </Select>
        </FormControl>

        {/* Balance Status Filter */}
        <FormControl>
          <FormLabel>Balance Status</FormLabel>
          <Select
            placeholder="Select balance status"
            onChange={onChange}
            value={filtersBody.balanceStatus}
            name="balanceStatus"
          >
            <option value="positive">Positive</option>
            <option value="negative">Negative</option>
          </Select>
        </FormControl>
      </SimpleGrid>
    </Box>
  );
};

export default FiltersAndSort;
