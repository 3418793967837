import types from "../constants/beneficiaryVerificationsConstants";


const initialState = {
    beneficiaryVerifications: {
        data: [],
        isLoading: false,
        isError: false,
        error: null,
    },
    beneficiaryVerification: {
        data: [],
        isLoading: false,
        isError: false,
        error: null,
    },
}

const beneficiaryVerificationDashboardReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case types.GETTING_BENEFICIARY_VERIFICATIONS:
            return {
                ...state,
                beneficiaryVerifications: {
                    data: [],
                    isLoading: true,
                    isError: false,
                    error: null,
                }
            };

        case types.GET_BENEFICIARY_VERIFICATIONS_SUCCESS:
            return {
                ...state,
                beneficiaryVerifications: {
                    data: payload,
                    isLoading: false,
                    isError: false,
                    error: null,
                }
            };

        case types.GET_BENEFICIARY_VERIFICATIONS_FAILURE:
            return {
                ...state,
                beneficiaryVerifications: {
                    data: [],
                    isLoading: false,
                    isError: true,
                    error: payload,
                }
            }

        case types.GETTING_BENEFICIARY_VERIFICATION_DETAIL:
            return {
                ...state,
                beneficiaryVerification: {
                    data: [],
                    isLoading: true,
                    isError: false,
                    error: null,
                }
            };

        case types.GET_BENEFICIARY_VERIFICATION_SUCCESS_DETAIL:
            return {
                ...state,
                beneficiaryVerification: {
                    data: payload,
                    isLoading: false,
                    isError: false,
                    error: null,
                }
            };

        case types.GET_BENEFICIARY_VERIFICATION_DETAIL_FAILURE:
            return {
                ...state,
                beneficiaryVerification: {
                    data: [],
                    isLoading: false,
                    isError: true,
                    error: payload,
                }
            };

        default:
            return {
                ...state
            };
    }
};

export default beneficiaryVerificationDashboardReducer;