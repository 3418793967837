import dayjs from 'dayjs';
import { doesExist, formatDate, kebabCaseToSpaceSeperate } from '../../utils';
import { FULFILLMENT_PROVIDER, ORDER_ACQUIRER } from '../../constants';

function getOffRampManualFulfillmentData(offRampManualFulfillmentItems) {
  return offRampManualFulfillmentItems?.map((data) => ({
    title: 'Off Ramp Manual Fulfillment Data',
    data: data
      ? [
          {
            name: 'Fulfillment Provider',
            value: data?.fulfillmentProvider || '-'
          },
          {
            name: 'Manual Transfer ID',
            value: data?.manualTransferId
          },
          {
            name: 'Status',
            value: data?.status
          },
          {
            name: 'Created At',
            value: dayjs(data?.createdAt).format('DD ddd, YYYY')
          },
          {
            name: 'Updated At',
            value: dayjs(data?.updatedAt).format('DD ddd, YYYY')
          }
        ]
      : null
  }));
}

function getOffRampAutoFulfillmentData(offRampFulfillmentItems) {
  if (offRampFulfillmentItems.length === 0) return [];
  return offRampFulfillmentItems?.map((data, ind) => {
    return {
      title: `Off Ramp Auto Fulfillment Data - ${ind + 1}`,
      ...(data?.status === 'COMPLETED'
        ? {
            hasActionBtns: true
          }
        : {}),
      data: data
        ? [
            {
              name: 'Fulfillment Provider',
              value: data?.fulfillmentProvider || '-'
            },
            {
              name: 'Payout Partner',
              value: data?.payoutPartner || '-'
            },
            {
              name: 'Payout ID',
              value: data?.payoutId || '-'
            },
            {
              name: 'Payout Status',
              value: data?.payoutStatus || '-'
            },
            {
              name: 'Previous Status',
              value: data?.previousStatus || '-'
            },
            {
              name: 'Quote ID',
              value: data?.quoteId || '-'
            },
            {
              name: 'Quote Rate',
              value: data?.quoteRate || '-'
            },
            {
              name: 'Receive Amount',
              value: data?.receiveAmount || '-'
            },
            {
              name: 'Status',
              value: data?.status || '-'
            },
            {
              name: 'Transaction Hash',
              value: data?.transactionHash || '-'
            },
            {
              name: 'Transfer ID',
              value: data?.transferId || '-'
            }
          ]
        : null
    };
  });
}

function getOnRampData(onRampData) {
  if (!onRampData?.txnId) return [];
  return [
    {
      title: 'On Ramp Data',
      data: onRampData
        ? [
            {
              name: 'OnRamp Txn ID',
              value: onRampData?.txnId || '-'
            },
            {
              name: 'OnRamp Txn Hash',
              value: onRampData?.txnHash || '-'
            },
            {
              name: 'Txn Status',
              value: onRampData?.status || '-'
            },
            {
              name: 'Send Amount',
              value: onRampData?.sendAmount || '-'
            },
            {
              name: 'USDT Amount',
              value: onRampData?.usdtAmount || '-'
            },
            {
              name: 'Exchange Rate',
              value: onRampData?.exchangeRate || '-'
            },
            {
              name: 'Currency From',
              value: onRampData?.currencyFrom || '-'
            },
            {
              name: 'Currency To',
              value: onRampData?.currencyTo || '-'
            },
            {
              name: 'Fiat Amount',
              value: onRampData?.fiatAmount || '-'
            },
            {
              name: 'Created At',
              value: dayjs(onRampData?.createdAt).format('DD MMM YYYY') || '-'
            },
            {
              name: 'Updated At',
              value: dayjs(onRampData?.updatedAt).format('DD MMM YYYY') || '-'
            }
          ]
        : null
    }
  ];
}

function getThunesData(thunesData) {
  return thunesData?.map((data, ind) => ({
    title: thunesData?.length > 1 ? `Thunes Data - ${ind + 1}` : 'Thunes Data',
    data: data
      ? [
          {
            name: 'Quote Expiry:',
            value: formatDate(data?.quoteExpiry)
          },
          {
            name: 'Transaction Type:',
            value: data?.transactionType || '-'
          },

          {
            name: 'Sent amount:',
            value: doesExist(data?.sentAmount)
              ? `${data?.sentCurrency} ${data?.sentAmount.toFixed(2)}`
              : '-'
          },

          {
            name: 'Destination amount:',
            value: doesExist(data?.destinationAmount)
              ? `${data?.destinationCurrency} ${data?.destinationAmount.toFixed(
                  2
                )}`
              : '-'
          },
          {
            name: 'Transaction ID:',
            value: data?.transactionId || '-'
          },
          {
            name: 'Purpose:',
            value: data?.purposeOfRemittance || '-'
          },
          {
            name: 'Beneficiary first name:',
            value: data?.beneficiaryFirstName || '-'
          },
          {
            name: 'Beneficiary last name:',
            value: data?.beneficiaryLastName || '-'
          },
          {
            name: 'Status:',
            value: data?.transactionStatus || '-'
          },
          {
            name: 'Previous status:',
            value: data?.transactionPreviousStatus || '-'
          },
          {
            name: 'Sub status:',
            value: data?.transactionSubStatus || '-'
          },
          {
            name: 'Wholesale FX rate:',
            value: doesExist(data?.wholesaleFxRate)
              ? data?.wholesaleFxRate.toFixed(2)
              : '-'
          },
          {
            name: 'Created at:',
            value: formatDate(data?.createdAt) || '-'
          },
          {
            name: 'Updated at:',
            value: formatDate(data?.updatedAt) || '-'
          },
          {
            name: 'Fulfillment ID',
            value: data?.fulfillmentId || '-'
          }
        ]
      : null
  }));
}

function getUaeManualPaymentData(uaeManualPaymentData) {
  return {
    title: 'UAE Manual Payment Data',
    data: [
      {
        name: 'Transfer ID',
        value: uaeManualPaymentData?.transferId || '-'
      },
      {
        name: 'Amount',
        value: uaeManualPaymentData?.amount || '-'
      },
      {
        name: 'Status',
        value: uaeManualPaymentData?.status || '-'
      },
      {
        name: 'Previous Status',
        value: uaeManualPaymentData?.previousStatus || '-'
      },
      {
        name: 'BRN Number',
        value: uaeManualPaymentData?.brnNumber || '-'
      },
      {
        name: 'Bank Deposit Reference Number',
        value: uaeManualPaymentData?.bankDepositReferenceNumber || '-'
      },
      {
        name: 'Beneficiary IBAN',
        value: uaeManualPaymentData?.beneficiaryIban || '-'
      },
      {
        name: 'Beneficiary Account ID',
        value: uaeManualPaymentData?.beneficiaryAccountId || '-'
      },
      {
        name: 'Created At',
        value:
          dayjs(uaeManualPaymentData?.createdAt).format('DD MMM YYYY') || '-'
      },
      {
        name: 'Updated At',
        value:
          dayjs(uaeManualPaymentData?.updatedAt).format('DD MMM YYYY') || '-'
      }
    ]
  };
}

function getOrderFulfillmentData(orderFulfillments) {
  return orderFulfillments?.map((orderFulfillment, ind) => {
    return {
      title: `Fulfillment Meta Data`,
      ...(orderFulfillment?.fulfillmentProvider ===
        FULFILLMENT_PROVIDER.THUNES &&
      orderFulfillment?.fulfillmentStatus === 'INITIATED'
        ? {
            element: 'reinitiate_fulfillment',
            hasActionBtns: true,
            fulfillmentId: orderFulfillment?.fulfillmentId
          }
        : {}),
      ...(orderFulfillment?.fulfillmentProvider ===
        FULFILLMENT_PROVIDER.OFF_RAMP &&
      orderFulfillment?.fulfillmentStatus === 'COMPLETED'
        ? {
            element: 'off_ramp_reinitiate_with_thunes',
            hasActionBtns: true
          }
        : {}),
      data: [
        {
          name: 'Fulfillment Id:',
          value: orderFulfillment?.fulfillmentId || '-'
        },
        {
          name: 'type:',
          value: orderFulfillment?.fulfillmentType || '-'
        },
        {
          name: 'provider:',
          value: orderFulfillment?.fulfillmentProvider || '-'
        },
        {
          name: 'status:',
          value: orderFulfillment?.fulfillmentStatus || '-'
        },
        {
          name: 'Previous status:',
          value: orderFulfillment?.previousStatus || '-'
        },
        {
          name: 'Falcon Transaction ID',
          value: orderFulfillment?.falconTransactionId || '-'
        },
        {
          name: 'Created at:',
          value: orderFulfillment?.createdAt
            ? formatDate(orderFulfillment.createdAt)
            : '-'
        },
        {
          name: 'Updated at:',
          value: orderFulfillment?.updatedAt
            ? formatDate(orderFulfillment.updatedAt)
            : '-'
        }
      ]
    };
  });
}

const getRfxDetails = (rfxItems) => {
  return rfxItems?.map((rfxData) => ({
    title: 'RFX Details',
    data: rfxData
      ? [
          {
            name: 'Deal Id:',
            value: rfxData?.dealId || '-',
            isCopyable: true
          },
          {
            name: 'Recipient payment Id:',
            value: rfxData?.recipientPaymentId || '-'
          },
          {
            name: 'Market rate:',
            value:
              doesExist(rfxData?.marketRate) && rfxData?.marketRate
                ? rfxData?.marketRate
                : '-'
          },
          {
            name: 'Rate Quoted:',
            value:
              doesExist(rfxData?.rateQuoted) && rfxData?.rateQuoted
                ? rfxData?.rateQuoted
                : '-'
          },
          {
            name: 'Amount sell:',
            value:
              doesExist(rfxData?.amountSell) && rfxData?.currencySell
                ? `${rfxData?.currencySell} ${rfxData?.amountSell}`
                : '-'
          },
          {
            name: 'Amount buy:',
            value:
              rfxData?.currencyBuy && doesExist(rfxData?.amountBuy)
                ? `${rfxData?.currencyBuy} ${rfxData?.amountBuy}`
                : '-'
          },
          {
            name: 'Payment fee:',
            value: doesExist(rfxData?.paymentFee) ? rfxData?.paymentFee : '-'
          },
          { name: 'Status:', value: rfxData?.status || '-' },
          {
            name: 'Previous status:',
            value: rfxData?.previousStatus || '-'
          },
          {
            name: 'Created at:',
            value: rfxData?.createdAt ? formatDate(rfxData?.createdAt) : '-'
          },
          {
            name: 'Updated at:',
            value: rfxData?.updatedAt ? formatDate(rfxData?.updatedAt) : '-'
          }
        ]
      : null
  }));
};

const getLuluData = (luluItems) => {
  return luluItems?.map((luluData) => ({
    title: 'Lulu Data',
    hasActionBtns: true,
    element: 'lulu_data',
    data: luluData
      ? [
          {
            name: 'Type:',
            value: luluData.type
          },
          {
            name: 'Status:',
            value: luluData.status || '-'
          },
          {
            name: 'Previous status:',
            value: luluData.previousStatus || '-'
          },
          {
            name: 'Sub status:',
            value: luluData.subStatus || '-'
          },
          {
            name: 'Transaction Ref Id:',
            value: luluData.transactionRefId || '-',
            isCopyable: true
          },
          {
            name: 'Transaction agent No:',
            value: luluData.transactionAgentRefNumber || '-'
          },
          {
            name: 'Transaction Quote Id:',
            value: luluData.transactionQuoteId || '-',
            isCopyable: true
          },
          {
            name: 'Source of income:',
            value: luluData.sourceOfIncome || '-'
          },

          {
            name: 'Purpose:',
            value: luluData.purposeOfTxn || '-'
          },
          {
            name: 'Instrument:',
            value: luluData.instrument || '-'
          },
          {
            name: 'Receiver name:',
            value: luluData.receiverFirstName + ' ' + luluData.receiverLastName
          },
          {
            name: 'Receiver relation:',
            value: luluData.receiverRelationCode || '-'
          },
          {
            name: 'Receiver nationality:',
            value: luluData.receiverNationality || '-'
          },
          {
            name: 'Account Type:',
            value: luluData.bankAccountType || '-'
          },
          {
            name: 'Account number:',
            value: luluData.bankAccountNumber || '-'
          },
          {
            name: 'Bank routing No:',
            value: luluData.bankRoutingNumber || '-'
          },
          {
            name: 'Created at:',
            value: formatDate(luluData.createdAt) || '-'
          },
          {
            name: 'Updated at:',
            value: formatDate(luluData.updatedAt) || '-'
          },
          {
            name: 'Lulu Client:',
            value: luluData.luluClient || '-'
          }
        ]
      : null
  }));
};

const getYblFulfillmentData = (yblFulfillments) => {
  return yblFulfillments?.map((fulfillment) => ({
    title: 'YBL Fulfillment Data',
    data: [
      {
        name: 'YBL Fulfillment ID',
        value: fulfillment?.yblFulfillmentId
      },
      {
        name: 'Unique Request No',
        value: fulfillment?.uniqueRequestNo
      },
      {
        name: 'Unique Response No',
        value: fulfillment?.uniqueResponseNo
      },
      {
        name: 'Attempt No',
        value: fulfillment?.attemptNo
      },
      {
        name: 'Remitter Name',
        value: fulfillment?.remitterName
      },
      {
        name: 'Remitter Address',
        value: fulfillment?.remitterAddress
      },
      {
        name: 'Remitter Phone',
        value: fulfillment?.remitterPhone
      },
      {
        name: 'Remitter Email',
        value: fulfillment?.remitterEmail
      },
      {
        name: 'Fulfillment ID',
        value: fulfillment?.fulfillmentId
      },
      {
        name: 'Order ID',
        value: fulfillment?.orderId
      },
      {
        name: 'Transfer Amount',
        value: fulfillment?.transferAmount
      },
      {
        name: 'Beneficiary Name',
        value: fulfillment?.beneficiaryName
      },
      {
        name: 'Beneficiary Contact',
        value: fulfillment?.beneficiaryContact
      },
      {
        name: 'Beneficiary Account No',
        value: fulfillment?.beneficiaryAccountNo
      },
      {
        name: 'Beneficiary IFSC Code',
        value: fulfillment?.beneficiaryIfscCode
      },
      {
        name: 'Transfer Type',
        value: fulfillment?.transferType
      },
      {
        name: 'Status',
        value: fulfillment?.status
      },
      {
        name: 'Previous Status',
        value: fulfillment?.previousStatus
      },
      {
        name: 'Transfer Currency Code',
        value: fulfillment?.transferCurrencyCode
      },
      {
        name: 'Purpose Code',
        value: fulfillment?.purposeCode
      },
      {
        name: 'Bank Reference Number',
        value: fulfillment?.bankReferenceNumber
      },
      {
        name: 'YBL FX Rate',
        value: fulfillment?.yblFxRate
      },
      {
        name: 'Failure Reason',
        value: fulfillment?.failureReason
      },
      {
        name: 'Created At',
        value: dayjs(fulfillment?.createdAt).format('DD MMM YYYY hh : mm : ss')
      },
      {
        name: 'Updated At',
        value: dayjs(fulfillment?.updatedAt).format('DD MMM YYYY hh : mm : ss')
      }
    ]
  }));
};
export const getFalconTransactionTable = (falconTransaction) => {
  return [
    {
      title: 'Falcon Transaction',
      data: [
        {
          name: 'Transaction ID',
          value: falconTransaction.transactionId || 'N/A',
          isCopyable: true
        },
        {
          name: 'Client Transaction ID',
          value: falconTransaction.clientTxnId || 'N/A'
        },
        {
          name: 'Client',
          value: falconTransaction.client || 'N/A'
        },
        {
          name: 'Source Currency',
          value: falconTransaction.sourceCurrency || 'N/A'
        },
        {
          name: 'Source Amount',
          value: falconTransaction.sourceAmount
            ? falconTransaction.sourceAmount.toFixed(2)
            : 'N/A'
        },
        {
          name: 'Payout Currency',
          value: falconTransaction.payoutCurrency || 'N/A'
        },
        {
          name: 'Payout Amount',
          value: falconTransaction.payoutAmount
            ? falconTransaction.payoutAmount.toFixed(2)
            : 'N/A'
        },
        {
          name: 'Exchange Rate',
          value: falconTransaction.exchangeRate
            ? falconTransaction.exchangeRate.toFixed(4)
            : 'N/A'
        },
        {
          name: 'Transaction Details',
          value: falconTransaction.transactionDetails || 'N/A'
        },
        {
          name: 'Transaction Expires At',
          value: falconTransaction.transactionExpiresAt
            ? new Date(falconTransaction.transactionExpiresAt).toLocaleString()
            : 'N/A'
        }
      ]
    }
  ];
};

export const getOrderDetailsTable = (orderData) => {
  return [
    {
      title: 'Order Details',
      data: [
        {
          name: 'Order Id:',
          value: orderData.orderId || '-',
          isCopyable: true
        },
        {
          name: 'Amount:',
          value:
            orderData.currencyFrom && doesExist(orderData.amount)
              ? ` ${orderData.currencyFrom} ${orderData.amount}`
              : '-'
        },
        {
          name: 'Amount sent:',
          value:
            orderData.currencyFrom && doesExist(orderData.sendAmount)
              ? ` ${orderData.currencyFrom} ${orderData.sendAmount}`
              : '-'
        },
        {
          name: 'Flat fee:',
          value:
            orderData.currencyFrom && doesExist(orderData.flatFee)
              ? `${orderData.currencyFrom} ${orderData.flatFee}`
              : '-'
        },
        {
          name: 'Discount:',
          value:
            orderData.currencyFrom && doesExist(orderData.discount)
              ? `${orderData.currencyFrom} ${orderData.discount}`
              : '-'
        },
        {
          name: 'Added Reward:',
          value:
            orderData.currencyFrom && doesExist(orderData.addedReward)
              ? ` ${orderData.currencyFrom} ${orderData.addedReward}`
              : '-'
        },
        {
          name: 'Vance Cash:',
          value: orderData?.vanceCash || '-'
        },
        {
          name: 'Fee charged:',
          value:
            orderData.currencyFrom &&
            doesExist(orderData.flatFee) &&
            doesExist(orderData.discount)
              ? `${orderData.currencyFrom} ${
                  orderData.flatFee - orderData.discount
                }`
              : '-'
        },
        {
          name: 'Convert amount:',
          value:
            orderData.currencyFrom && doesExist(orderData.weConvert)
              ? `${orderData.currencyFrom} ${orderData.weConvert}`
              : '-'
        },
        {
          name: 'Credit amount:',
          value:
            orderData.currencyTo && doesExist(orderData.receiveAmount)
              ? `${orderData.currencyTo} ${orderData.receiveAmount}`
              : '-'
        },
        {
          name: 'Google rate:',
          value:
            orderData.rate && doesExist(orderData.rate) ? orderData.rate : '-'
        },
        {
          name: 'Created at:',
          value: orderData.createdAt ? formatDate(orderData.createdAt) : '-'
        },
        {
          name: 'Updated at:',
          value: orderData.createdAt ? formatDate(orderData.updatedAt) : '-'
        },
        {
          name: 'Payment Purpose',
          value: orderData.paymentPurpose || '-'
        },

        {
          name: 'Order Type:',
          value: orderData?.orderType || '-'
        },
        {
          name: 'Order status:',
          value: orderData.orderStatus || '-'
        },
        {
          name: 'Previous status:',
          value: orderData?.previousStatus || '-'
        }
      ]
    },
    {
      title: 'Beneficiary',
      hasActionBtns: true,
      element: 'beneficiary',
      data: [
        {
          name: 'Name:',
          value: orderData?.beneficiary?.accountHolderName || '-'
        },
        {
          name: 'Nickname:',
          value: orderData?.beneficiary?.accountHolderNickName || '-'
        },
        {
          name: 'IFSC Code:',
          value: orderData?.beneficiary?.IFSCCode || '-'
        },
        {
          name: 'Account Number:',
          value: orderData?.beneficiary?.accountNumber || '-'
        }
      ]
    },
    {
      title: 'Order Payment',
      hasActionBtns:
        ['NEW', 'CREATED', 'FAILED'].findIndex(
          (status) => status === orderData?.orderStatus
        ) !== -1 &&
        orderData?.orderPayment?.paymentAcquirer ===
          ORDER_ACQUIRER.THUNES_COLLECTION,
      element: 'order_payment',
      data: [
        {
          name: 'Payment Id:',
          value: orderData.orderPayment?.paymentId || '-'
        },
        {
          name: 'Payment status:',
          value: orderData.orderPayment?.paymentStatus || '-'
        },
        {
          name: 'Previous status:',
          value: orderData.orderPayment?.previousStatus || '-'
        },
        {
          name: 'Payment instrument:',
          value: orderData.orderPayment?.paymentInstrument || '-'
        },
        {
          name: 'Payment Acquirer:',
          value: orderData.orderPayment?.paymentAcquirer || '-'
        },
        {
          name: 'Created at:',
          value: orderData.orderPayment?.createdAt
            ? formatDate(orderData.orderPayment.createdAt)
            : '-'
        },
        {
          name: 'Updated at:',
          value: orderData.orderPayment?.updatedAt
            ? formatDate(orderData.orderPayment.updatedAt)
            : '-'
        }
      ]
    },
    ...getOnRampData(orderData?.onRampData),
    ...orderData.payments
      .filter((_, i) => i !== 0)
      .map((payment) => ({
        title: null,
        defaultHidden: true,
        data: [
          {
            name: 'Payment Id:',
            value: payment?.paymentId || '-'
          },
          {
            name: 'Payment status:',
            value: payment?.paymentStatus || '-'
          },
          {
            name: 'Previous status:',
            value: payment?.previousStatus || '-'
          },
          {
            name: 'Payment instrument:',
            value: payment?.paymentInstrument || '-'
          },
          {
            name: 'Payment Acquirer:',
            value: payment?.paymentAcquirer || '-'
          },
          {
            name: 'Created at:',
            value: payment?.createdAt ? formatDate(payment.createdAt) : '-'
          },
          {
            name: 'Updated at:',
            value: payment?.updatedAt ? formatDate(payment.updatedAt) : '-'
          }
        ]
      })),
    {
      title: 'Plaid Payment Data',
      data: orderData.plaidPaymentData
        ? [
            {
              name: 'Payment Id:',
              value: orderData.plaidPaymentData?.plaidPaymentId || '-',
              isCopyable: true
            },
            {
              name: 'Recipient Id:',
              value: orderData.plaidPaymentData?.plaidRecipientId || '-',
              isCopyable: true
            },
            {
              name: 'Link Token:',
              value: orderData.plaidPaymentData?.linkToken || '-',
              isCopyable: true
            },
            {
              name: 'Payment Status:',
              value: orderData.plaidPaymentData?.plaidPaymentStatus || '-'
            },
            {
              name: 'Previous Status:',
              value: orderData.plaidPaymentData?.plaidPreviousStatus || '-'
            },
            {
              name: 'Created At:',
              value: orderData.plaidPaymentData?.createdAt
                ? formatDate(orderData.plaidPaymentData?.createdAt)
                : '-'
            },
            {
              name: 'Updated At:',
              value: orderData.plaidPaymentData?.updatedAt
                ? formatDate(orderData.plaidPaymentData?.updatedAt)
                : '-'
            }
          ]
        : null
    },
    {
      title: 'Leantech Data',
      data: orderData.leantechPaymentData
        ? [
            {
              name: 'Payment Id:',
              value: orderData.leantechPaymentData?.leanPaymentId || '-',
              isCopyable: true
            },
            {
              name: 'Customer Id:',
              value: orderData.leantechPaymentData?.customerId || '-',
              isCopyable: true
            },
            {
              name: 'Payment intent Id:',
              value: orderData.leantechPaymentData?.paymentIntentId || '-',
              isCopyable: true
            },
            {
              name: 'Payment Status:',
              value: orderData.leantechPaymentData?.status || '-'
            },
            {
              name: 'Previous Status:',
              value: orderData.leantechPaymentData?.previousStatus || '-'
            },
            {
              name: 'Description:',
              value: orderData.leantechPaymentData?.description || '-'
            },
            {
              name: 'Payment Destination:',
              value: orderData.leantechPaymentData?.paymentDestination || '-'
            },
            {
              name: 'Bank transaction ref:',
              value:
                orderData.leantechPaymentData?.bankTransactionReference || '-'
            },
            {
              name: 'Amount:',
              value: doesExist(orderData.leantechPaymentData?.amount)
                ? `${orderData.leantechPaymentData?.currency} ${orderData.leantechPaymentData?.amount}`
                : '-'
            },
            {
              name: 'Created At:',
              value: orderData.leantechPaymentData?.createdAt
                ? formatDate(orderData.leantechPaymentData?.createdAt)
                : '-'
            },
            {
              name: 'Updated At:',
              value: orderData.leantechPaymentData?.updatedAt
                ? formatDate(orderData.leantechPaymentData?.updatedAt)
                : '-'
            }
          ]
        : null
    },
    //
    {
      title: 'Thunes Collection Data',
      data: orderData.thunesCollectionData
        ? [
            {
              name: 'Amount:',
              value: doesExist(orderData.thunesCollectionData.amount)
                ? `${
                    orderData.thunesCollectionData.currency
                  } ${orderData.thunesCollectionData.amount.toFixed(2)}`
                : '-'
            },
            {
              name: 'First name:',
              value: orderData.thunesCollectionData.customerFirstName
            },
            {
              name: 'Email:',
              value: orderData.thunesCollectionData.email
            },
            {
              name: 'Status:',
              value: orderData.thunesCollectionData.status
            },
            {
              name: 'Previous status:',
              value: orderData.thunesCollectionData.previousStatus
            },
            {
              name: 'Created at:',
              value: formatDate(orderData.thunesCollectionData.createdAt) || '-'
            },
            {
              name: 'Updated at:',
              value: formatDate(orderData.thunesCollectionData.updatedAt) || '-'
            }
          ]
        : null
    },
    getUaeManualPaymentData(orderData?.uaeManualPaymentData),
    ...getOrderFulfillmentData(orderData?.orderFulfillment),
    {
      title: 'RFX Details',
      data: orderData?.orderFulfillment?.rfxData
        ? [
            {
              name: 'Deal Id:',
              value: orderData.rfxData?.dealId || '-',
              isCopyable: true
            },
            {
              name: 'Recipient payment Id:',
              value: orderData.rfxData?.recipientPaymentId || '-'
            },
            {
              name: 'Market rate:',
              value:
                doesExist(orderData.rfxData?.marketRate) &&
                orderData.rfxData?.marketRate
                  ? orderData.rfxData?.marketRate
                  : '-'
            },
            {
              name: 'Rate Quoted:',
              value:
                doesExist(orderData.rfxData?.rateQuoted) &&
                orderData.rfxData?.rateQuoted
                  ? orderData.rfxData?.rateQuoted
                  : '-'
            },
            {
              name: 'Amount sell:',
              value:
                doesExist(orderData.rfxData?.amountSell) &&
                orderData.rfxData?.currencySell
                  ? `${orderData.rfxData?.currencySell} ${orderData.rfxData?.amountSell}`
                  : '-'
            },
            {
              name: 'Amount buy:',
              value:
                orderData.rfxData?.currencyBuy &&
                doesExist(orderData.rfxData?.amountBuy)
                  ? `${orderData.rfxData?.currencyBuy} ${orderData.rfxData?.amountBuy}`
                  : '-'
            },
            {
              name: 'Payment fee:',
              value: doesExist(orderData.rfxData?.paymentFee)
                ? orderData.rfxData?.paymentFee
                : '-'
            },
            { name: 'Status:', value: orderData.rfxData?.status || '-' },
            {
              name: 'Previous status:',
              value: orderData.rfxData?.previousStatus || '-'
            },
            {
              name: 'Created at:',
              value: orderData.rfxData?.createdAt
                ? formatDate(orderData.rfxData?.createdAt)
                : '-'
            },
            {
              name: 'Updated at:',
              value: orderData.rfxData?.updatedAt
                ? formatDate(orderData.rfxData?.updatedAt)
                : '-'
            }
          ]
        : null
    },
    ...orderData?.thunesData
      ?.map((data) => {
        return getThunesData(data);
      })
      .reduce((prev, curr) => {
        return [...prev, ...curr];
      }, []),
    ...getRfxDetails(orderData?.rfxData),
    ...getLuluData(orderData?.luluData),
    ...getYblFulfillmentData(orderData?.yblFulfillmentData),
    {
      title: 'Truelayer Data',
      data: orderData?.truelayerData
        ? [
            {
              name: 'Truelayer Payment ID',
              value: orderData?.truelayerData?.truelayerPaymentId || '-'
            },
            {
              name: 'Truelayer User ID',
              value: orderData?.truelayerData?.truelayerUserId || '-'
            },
            {
              name: 'Send Amount',
              value: orderData?.truelayerData?.sendAmount || '-'
            },
            {
              name: 'Status',
              value: orderData?.truelayerData?.status || '-'
            },
            {
              name: 'Previous Status',
              value: orderData?.truelayerData?.previousStatus || '-'
            },
            {
              name: 'Failure Reason',
              value: orderData?.truelayerData?.failureReason || '-'
            },
            {
              name: 'Created At',
              value: formatDate(orderData?.truelayerData?.createdAt) || '-'
            },
            {
              name: 'Updated At',
              value: formatDate(orderData?.truelayerData?.updatedAt) || '-'
            }
          ]
        : null
    },
    ...getOffRampManualFulfillmentData(orderData?.offRampManual),
    ...getOffRampAutoFulfillmentData(orderData?.offRampData)
  ];
};
