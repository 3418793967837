import {
  Button,
  FormLabel,
  Grid,
  Heading,
  ModalBody,
  ModalHeader,
  Select,
  Switch,
  Text,
  VStack
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { getRfiRequestedItems } from '../../views/RFITransactions/utils';
import { createRFI } from '../../store/actions/rfiActions';
import { useDispatch } from 'react-redux';
import { RFI_CONSTANTS as types } from '../../store/constants';
import { useNavigate, useParams } from 'react-router-dom';

const CreateRFIModal = ({ onClose, rfiType, rfiTypeList }) => {
  const [rfiDetails, setRfiDetails] = useState([]);
  const [requestedItems, setRequestedItems] = useState([]);
  const [selectedRfiType, setSelectedRfiType] = useState(
    rfiType || rfiTypeList[0]
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId, orderId } = useParams();

  useEffect(() => {
    if (!orderId) {
      onClose();
    } else {
      setRfiDetails(getRfiRequestedItems());
    }
  }, [orderId]);

  const handleRequestedItemToggle = (requestedFor, requestedInfo) => {
    setRequestedItems((prevState) => {
      const exists = prevState.some(
        (item) =>
          item.requestedFor === requestedFor &&
          item.requestedInfo === requestedInfo
      );
      if (exists) {
        return prevState.filter(
          (item) =>
            item.requestedFor !== requestedFor ||
            item.requestedInfo !== requestedInfo
        );
      } else {
        return [...prevState, { requestedInfo, requestedFor }];
      }
    });
  };

  const handleCreateRFI = () => {
    const rfiData = {
      orderId,
      requestedItems,
      type: selectedRfiType
    };
    dispatch(
      createRFI({
        rfiData,
        onSuccess: (data) => {
          dispatch({
            type: types.CREATE_RFI_SUCCESS,
            payload: { data }
          });
          onClose();
          navigate(`/user/${userId}/order/${orderId}/rfi/${selectedRfiType}`);
        }
      })
    );
  };

  const handleSelectRfiType = (e) => {
    const { value } = e.target;
    setSelectedRfiType(value);
  };

  return (
    <>
      <ModalHeader pt={0}>
        <Heading size={'md'}>Create RFI</Heading>
      </ModalHeader>
      <ModalBody>
        <VStack w={'full'} gap={2} alignItems={'flex-start'}>
          <Grid w={'full'} gridTemplateColumns={'1fr 3fr'}>
            <Text color={'gray.300'}>Order ID:</Text>
            <Text>{orderId}</Text>
          </Grid>
          <Grid w={'full'} gridTemplateColumns={'1fr 3fr'}>
            <Text color={'gray.300'}>RFI Type:</Text>
            {rfiType && <Text>{rfiType}</Text>}
            {rfiTypeList && (
              <Select w={'max-content'} onChange={handleSelectRfiType}>
                {rfiTypeList.map((type) => (
                  <option value={type}>{type}</option>
                ))}
              </Select>
            )}
          </Grid>
          <Heading size={'md'}>Request Items</Heading>
          <VStack gap={1} maxH={'50vh'} overflow={'auto'}>
            {rfiDetails.map((rfi) => (
              <Grid w={'full'} gridTemplateColumns={'1fr 3fr'}>
                <Switch
                  id={`${rfi.requestedFor}-${rfi.requestedInfo}`}
                  size={'md'}
                  colorScheme="brand"
                  onChange={() =>
                    handleRequestedItemToggle(
                      rfi.requestedFor,
                      rfi.requestedInfo
                    )
                  }
                />
                <FormLabel
                  htmlFor={`${rfi.requestedFor}-${rfi.requestedInfo}`}
                  mb={0}
                  cursor={'pointer'}
                >
                  <Text color={'gray.400'}>{`${rfi.title}`}</Text>
                </FormLabel>
              </Grid>
            ))}
          </VStack>
          <Button
            colorScheme="brand"
            color={'black'}
            w={'fit-content'}
            alignSelf={'flex-end'}
            disabled={requestedItems.length == 0}
            onClick={handleCreateRFI}
          >
            Create
          </Button>
        </VStack>
      </ModalBody>
    </>
  );
};

export default CreateRFIModal;
