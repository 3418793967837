import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetch } from './api';
import Dashboard from './views/Dashboard';
import User from './views/User';
import OrderDetails from './views/Order';
import { logout } from './store/actions/authActions';
import Campaigns from './views/Campaigns';
import CreateCampaign from './views/Campaigns/CreateCampaign';
import LuluOrdersFunnel from './views/Funnel/LuluOrdersFunnel';
import KycFunnel from './views/Funnel/KycFunnel';
import NrAccountUsers from './views/nrAccount';
import EditNrUser from './views/nrAccount/EditNrUser';
import Onboarding from './views/nrAccount/Onboarding';
import UserWorkflowDocs from './views/User/UserWorkflowDocs';
import Workflows from './views/Workflows';
import KycDocs from './views/Kyc/KycDocs';
import KycChecks from './views/Kyc/KycChecks';
import Kycs from './views/Kyc';
import InvestmentOrderDetails from './views/Order/InvestmentOrder';
import SipOrderDetails from './views/Order/SipOrder';
import SourceAccount from './views/SourceAccount';
import ThunesFulfillments from './views/Thunes/ThunesFulfillments';
import Jobs from './views/jobs';
import Config from './views/Config/Config';
import Referrals from './views/Referrals';
import GoldenTickets from './views/GoldenTicket';
import OrdersV2Funnel from './views/Funnel/OrdersV2Funnel';
import RedeemedCoins from './views/RedeemedCoins';
import UserRedeemedCoins from './views/RedeemedCoins/UserRedeemedCoins';
import Tickets from './views/Tickets';
import NrOnboardingPage from './views/NrOnboarding';
import FtnrForm from './views/Ftnr';
import NronboardFunnel from './views/Funnel/Nronboard';
import UserSourceAccount from './views/UserSourceAccount';
import OrderScreenConfig from './views/OrderScreenConfig';
import OrderTransferChecks from './views/OrderTransferChecks';
import ExchangeRates from './views/ExchangeRates';
import UaeManualPayments from './views/uaeManualPayments';
import VanceCash from './views/VanceCash';
import VdaDashboard from './views/Funnel/SaberFunnel';
import ReconDashboardConfig from './views/Config/ReconDashboard';
import FxRatePeakingConfig from './views/Config/FxRatePeaking';
import TransferExperienceConfig from './views/Config/TransferExperienceManagement';
import UserBeneficiaries from './views/User/Beneficiaries';
import FeatureFlags from './views/Config/FeatureFlags';
import StudentProgramDetails from './views/User/StudentProgram';
import PendingOrderReceipts from './views/OrderReceipts';
import ManualPayoutBatch from './views/Batch/ManualPayoutBatch';
import BatchDetailsPage from './views/Batch/BatchDetailsPage';
import AccessManagement from './views/AccessManagement/AccessManagement';
import RewardsFunnel from './views/Rewards';
import BaerscrestOpsPage from './views/Baerscrest/BaerscrestOpsPage';
import RdaDashboard from './views/RDADashboard';
import VDATransferRoutingSystemDashboard from './views/VDATransferRoutingSystemDashboard/index';
import FalconServiceDashboard from './views/FalconServiceDashboard';
import RecipientVerificationDashboard from './views/BeneficiaryVerificationDashboard/RecipientVerificationDashboard';
import RecipientVerificationDetails from './views/BeneficiaryVerificationDashboard/RecipientVerificationDetails';
import { useNavigate } from 'react-router-dom';
import LuluRefundsDashboard from './views/LuluRefundsDashboard';
// import PayoutDashboard from './views/PayoutDashboard/PayoutDashboard';
const AxiosInterceptor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.metaKey && event.shiftKey && event.key === '1') {
        navigate('/falcon-dashboard');
      } else if (event.metaKey && event.shiftKey && event.key === '2')
        navigate('/thunes/fulfillments');
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  useEffect(() => {
    fetch.interceptors.response.use(
      (res) => res,
      (err) => {
        // console.log(err);
        if (err?.response?.status === 401) {
          dispatch(logout());
        }
        return Promise.reject(err);
      }
    );
  }, [dispatch]);

  return <></>;
};

function AuthenticatedApp() {
  return (
    <BrowserRouter>
      {/* {import.meta.env.PROD && <SegmentAnalytics />} */}
      <AxiosInterceptor />
      <Routes>
        <Route path="/rewards" element={<RewardsFunnel />} />
        <Route path="/tickets" element={<Tickets />} />
        {/* <Route path="/rda" element={<Rda />} />
        <Route path="/rda/provider" element={<RdaProvider />} /> */}
        {/* <Route
          path="/uae-manual-non-reconciled"
          element={<UaeManualNonReconciled />}
        /> */}
        <Route
          path="/non-verified-order-receipts"
          element={<PendingOrderReceipts />}
        />
        <Route path="/" element={<Dashboard />} />
        <Route
          path="/user/:userId/student-program"
          element={<StudentProgramDetails />}
        />
        <Route path="/user/:userId" element={<User />} />
        <Route
          path="/user/:userId/source-accounts"
          element={<UserSourceAccount />}
        />
        <Route path="user/:userId/vance-cash" element={<VanceCash />} />
        <Route path="/user/:userId/documents" element={<UserWorkflowDocs />} />
        <Route path="/funnel/orders" element={<OrdersV2Funnel />} />
        <Route path="/funnel/orders/lulu" element={<LuluOrdersFunnel />} />
        <Route path="/funnel/nr-onboarding" element={<NronboardFunnel />} />
        <Route path="/order/:orderId" element={<OrderDetails />} />
        <Route
          path="/order/:orderId/screen-config"
          element={<OrderScreenConfig />}
        />
        <Route
          path="/order/:orderId/transfer-checks"
          element={<OrderTransferChecks />}
        />
        <Route path="/manual-payouts/batch" element={<ManualPayoutBatch />} />
        <Route
          path="/manual-payouts/batch/:batchId"
          element={<BatchDetailsPage />}
        />
        <Route path="/funnel/kyc" element={<KycFunnel />} />
        <Route path="/campaign" element={<Campaigns />} />
        <Route path="/campaign/create" element={<CreateCampaign />} />
        <Route path="/nr-account/users" element={<NrAccountUsers />} />
        <Route path="/nr-account/users/:userId/edit" element={<EditNrUser />} />
        <Route path="/kyc" element={<Kycs />} />
        <Route
          path="/user/:userId/kyc/product/:product/provider/:provider"
          element={<KycDocs />}
        />
        <Route path="/user/:userId/kyc/checks" element={<KycChecks />} />
        <Route
          path="/nr-account/users/:userId/onboarding"
          element={<Onboarding />}
        />
        <Route
          path="/campaign/edit/:campaignId"
          element={<CreateCampaign isEdit />}
        />
        <Route path="/onboarding/:userId" element={<NrOnboardingPage />} />
        <Route path="/user/:userId/ybl-details" element={<FtnrForm />} />
        <Route path="/user/:userId/workflows" element={<Workflows />} />
        <Route path="/user/:userId/referrals" element={<Referrals />} />
        <Route
          path="/user/:userId/beneficiaries"
          element={<UserBeneficiaries />}
        />
        <Route path="/vda-dashboard" element={<VdaDashboard />} />
        <Route
          path="/investments/orders/:orderId"
          element={<InvestmentOrderDetails />}
        />
        <Route path="/investments/sips/:sipId" element={<SipOrderDetails />} />
        <Route path="/thunes/fulfillments" element={<ThunesFulfillments />} />
        <Route path="/source-accounts" element={<SourceAccount />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route
          path="/config/fx-rate-peaking"
          element={<FxRatePeakingConfig />}
        />
        <Route path="/config/feature-flags" element={<FeatureFlags />} />
        <Route
          path="/config/transfer-experience"
          element={<TransferExperienceConfig />}
        />
        <Route path="/config/recon" element={<ReconDashboardConfig />} />
        <Route path="/config" element={<Config />} />
        <Route path="/redeemed-coins" element={<RedeemedCoins />} />
        <Route path="/platform-transfer-fees" element={<ExchangeRates />} />
        <Route
          path="/user/:userId/redeemed-coins"
          element={<UserRedeemedCoins />}
        />
        <Route
          path="/manual-payments/pending"
          element={<UaeManualPayments />}
        />
        <Route path="/user/:userId/golden-ticket" element={<GoldenTickets />} />
        <Route path="/access-management" element={<AccessManagement />} />
        <Route path="/rda-dashboard" element={<RdaDashboard />} />
        <Route path="/baerscrest-ops" element={<BaerscrestOpsPage />} />
        <Route path="/falcon-dashboard" element={<FalconServiceDashboard />} />
        <Route
          path="/beneficiary-verification-dashboard"
          element={<RecipientVerificationDashboard />}
        />
        <Route
          path="/beneficiary-verification-dashboard/:verificationId"
          element={<RecipientVerificationDetails />}
        />
        <Route
          path="/VDA-transfer-routing"
          element={<VDATransferRoutingSystemDashboard />}
        />
        <Route
          path="/lulu-refunds-dashboard"
          element={<LuluRefundsDashboard />}
        />
        {/* Add routes above */}
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export { AuthenticatedApp };
