import dayjs from 'dayjs';

export const getTransactionDetailsTable = (transaction) => {
  if (!transaction) {
    return null;
  }

  return [
    {
      title: 'Transaction Details',
      data: [
        {
          name: 'Transaction ID',
          isCopyable: true,
          value: transaction?.transactionId || 'NULL'
        },
        {
          name: 'Transaction Amount',
          isCopyable: true,
          value: transaction?.transactionAmount || 'NULL'
        },
        {
          name: 'Beneficiary Name',
          value: transaction?.beneficiaryName ?? "NULL"
        },
        {
          name: 'Beneficiary Iban Number',
          value: transaction?.beneficiaryIbanNumber ?? "NULL"
        },
        {
          name: 'Description',
          isCopyable: true,
          value: transaction?.description || 'NULL'
        },
        {
          name: 'Created At',
          value: dayjs(transaction.createdAt).format('HH:mm  DD/MM/YYYY') || '-'
        },
        {
          name: 'Updated At',
          value: dayjs(transaction.updatedAt).format('HH:mm  DD/MM/YYYY') || '-'
        },
        {
          name: 'User Id ',
          value: transaction.userId || 'NULL',
          isCopyable: true
        },
        {
          name: 'Sheet Id ',
          value: transaction.userId || 'NULL',
          isCopyable: true
        },
        {
          name: 'Order Id ',
          value: transaction.userId || 'NULL',
          isCopyable: true
        },
        {
          name: 'Lulu Refund Status',
          value: transaction?.luluRefundStatus,
          isCopyable: true
        },
        {
          name: 'Reason',
          value: transaction?.reason,
          isCopyable: true
        },
      ],
    }
  ];
};
