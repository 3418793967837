import React from 'react';
import {
    Flex,
    ModalBody,
    Button,
    ModalFooter,
    FormControl,
    ModalHeader,
    ModalCloseButton,
    Text
} from '@chakra-ui/react';
import DynamicFormField from '../../Config/DynamicFormField/DynamicFormField';
import { RETRY_BENEFICIARY_VERIFICATION_FORM_CONFIG } from './config';

const RetryBeneficiaryVerificationModal = ({
    modalData,
    setModalData,
    handleSubmit,
    isLoading,
    onClose,
}) => {
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setModalData((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : type === 'number' ? Number(value) : value,
        }));
    };

    const handleLocalSubmit = (e) => {
        e.preventDefault();
        handleSubmit();
    };

    return (
      <>
          <ModalHeader textAlign="center" py={0}>
              Retry Beneficiary Verification
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
              <FormControl as="form" onSubmit={handleLocalSubmit}>
                  {RETRY_BENEFICIARY_VERIFICATION_FORM_CONFIG.map((field, index) => {
                      if (field?.fieldType === 'header') {
                          return (
                            <Flex
                              key={`${index}-${field.value}`}
                              w="100%"
                              justifyContent="flex-start"
                              height="100%"
                              alignItems="center"
                            >
                                <Text
                                  alignSelf="flex-end"
                                  fontSize="1rem"
                                  fontWeight="500"
                                  mb={0}
                                  lineHeight="2rem"
                                  pr={2}
                                >
                                    {field.label} : {modalData[field.value]}
                                </Text>
                            </Flex>
                          );
                      }

                      if (field?.isEditable) {
                          return (
                            <DynamicFormField
                              key={`${index}-${field.value}`}
                              fieldConfig={field}
                              data={modalData}
                              handleChange={handleChange}
                            />
                          );
                      }

                      return null;
                  })}

                  <ModalFooter justifyContent="center" gap={6}>
                      <Button
                        onClick={onClose}
                        variant="ghost"
                        colorScheme="white"
                        bg="whiteAlpha.300"
                        width="100%"
                        size="md"
                        fontSize="sm"
                        mt={3}
                      >
                          Cancel
                      </Button>
                      <Button
                        type="submit"
                        colorScheme="brand"
                        color="altGray.400"
                        width="100%"
                        size="md"
                        fontSize="sm"
                        mt={3}
                        isLoading={isLoading}
                      >
                          Submit
                      </Button>
                  </ModalFooter>
              </FormControl>
          </ModalBody>
      </>
    );
};

export default RetryBeneficiaryVerificationModal;
