import { ORDERS, RES_DATA } from '../constants';
import api from '../../api';
import { getErrorMessage, toErrorData } from '../../utils';
import { setAlert } from './alertActions';

export const getOrderById =
  ({ orderId }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ORDERS.GETTING_ORDER_BY_ID
      });

      const { data } = await api.orders.orderById(orderId);

      return dispatch({
        type: ORDERS.GET_ORDER_BY_ID_SUCCESS,
        payload: data
      });
    } catch (error) {
      return dispatch({
        type: ORDERS.GET_ORDER_BY_ID_FAILURE,
        payload: error
      });
    }
  };

export const clearOrderDetails = () => ({
  type: ORDERS.CLEAR_ORDER_DETAILS
});

export const getOrders =
  ({ query, orderType, pageNo, pageSize = 10, refresh = false }) =>
  async (dispatch) => {
    try {
      if (!query?.length && !orderType?.length) {
        return dispatch({
          type: RES_DATA.SEARCH_ORDERS_FAILURE,
          payload: toErrorData({
            message: 'search query and order type can not be empty'
          })
        });
      }

      dispatch({
        type: RES_DATA.SEARCHING_ORDERS,
        payload: {
          pageNo,
          refresh
        }
      });
      const { data } = await api.search.ordersV2(
        query,
        orderType,
        pageNo,
        pageSize
      );

      return dispatch({
        type: RES_DATA.SEARCH_ORDERS_SUCCESS,
        payload: data
      });
    } catch (error) {
      return dispatch({
        type: RES_DATA.SEARCH_ORDERS_FAILURE,
        payload: {
          error,
          pageNo
        }
      });
    }
  };

export const updateOrderMetadata =
  ({
    orderId,
    note,
    flag,
    successMessage,
    failureMessage,
    onSuccess,
    onFinish
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ORDERS.UPDATING_ORDER_METADATA
      });

      const { data } = await api.orders.updateOrderMetadata({
        orderId,
        details: note,
        flag
      });

      onSuccess(); //trigger modal close

      dispatch(setAlert({ title: successMessage, status: 'success' }));

      dispatch({
        type: ORDERS.UPDATE_ORDER_METADATA_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: failureMessage,
          status: 'error'
        })
      );
      dispatch({
        type: ORDERS.UPDATE_ORDER_METADATA_FAILURE,
        payload: error
      });
    } finally {
      onFinish();
    }
  };

export const getLuluOrders = (searchReqData) => async (dispatch) => {
  try {
    dispatch({
      type: RES_DATA.SEARCHING_LULU_ORDERS
    });

    const { data } = await api.search.luluOrders(searchReqData);

    return dispatch({
      type: RES_DATA.SEARCH_LULU_ORDERS_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch(
      setAlert({
        title: getErrorMessage({
          error,
          genericMessage: 'Failed to get Lulu order: ' + searchReqData?.query
        }),
        status: 'error'
      })
    );
    return dispatch({
      type: RES_DATA.SEARCH_LULU_ORDERS_FAILURE,
      payload: error
    });
  }
};
export const failAndRefund =
  ({ orderId, onSuccess, onFinish }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ORDERS.FAILING_ORDER
      });
      await api.orders.failAndRefund(orderId);
      if (onSuccess) onSuccess();
      dispatch(getOrderById({ orderId }));
      dispatch(
        setAlert({
          title: 'Failed and Refunded Order ' + orderId,
          status: 'success'
        })
      );

      return dispatch({
        type: ORDERS.FAIL_ORDER_SUCCESS
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'Could not force fail order'
          }),
          status: 'error'
        })
      );
      return dispatch({
        type: ORDERS.FAIL_ORDER_FAILURE,
        payload: error
      });
    } finally {
      onFinish && onFinish();
    }
  };

export const forceFailOrder =
  ({ orderId, onSuccess, onFinish }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ORDERS.FAILING_ORDER
      });
      await api.orders.failOrder(orderId);
      if (onSuccess) onSuccess();

      dispatch(getOrderById({ orderId }));
      dispatch(
        setAlert({
          title: 'Failed Order ' + orderId,
          status: 'success'
        })
      );

      return dispatch({
        type: ORDERS.FAIL_ORDER_SUCCESS
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'Could not force fail order'
          }),
          status: 'error'
        })
      );
      return dispatch({
        type: ORDERS.FAIL_ORDER_FAILURE,
        payload: error
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

export const updateOrderTicketData = (ticketData) => (dispatch) => {
  dispatch({
    type: ORDERS.UPDATE_ORDER_TICKET_DATA,
    payload: {
      ...ticketData
    }
  });
};

export const getOrderTransferChecks =
  ({ orderId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.orders.getOrderTransferChecks({ orderId });
      onSuccess && onSuccess(data);
    } catch (error) {
      console.error(error?.message);
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const updateBeneficiaryDetails =
  ({ userId, beneficiaryId, payload, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.orders.updateBeneficiaryDetails({
        userId,
        beneficiaryId,
        payload
      });
      onSuccess && onSuccess();
    } catch (error) {
      console.error(error.message);
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const reinitiateThunesFulfillment =
  ({ fulfillmentId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.orders.reinitiateThunesFulfillment({ fulfillmentId });
      onSuccess && onSuccess();
    } catch (error) {
      console.error(error.message);
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export function getTxnMonitoringByFulfillmentId ({ fulfillmentId}) {
    return async (dispatch) => {
        try {
            const {data} = await api.orders.getTxnMonitoringByFulfillmentId({fulfillmentId});
            return data;
        } catch (error) {
            dispatch(
                setAlert({
                    title: error.message,
                    status: 'error'
                })
            );
            throw error;
        }
    }
}

export const getProviderEntityDetails =
    ({ fulfillmentId }) =>
    async (dispatch) => {
        try {
            const { data } = await api.orders.getProviderEntityDetails({ fulfillmentId });
            return data;
        } catch (error) {
            dispatch(
                setAlert({
                    title: error.message,
                    status: 'error'
                })
            );
            throw error;
        }
    };


export const forceSyncThunesPayment =
  ({ orderId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      // TODO: perform api call here
      await api.orders.forceSyncThunesPayment({ orderId });
      onSuccess && onSuccess();
    } catch (error) {
      console.log(error.message);
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const updateOrderBeneficiary =
  ({ orderId, beneficiaryId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.orders.updateOrderBeneficiary({
        beneficiaryId,
        orderId
      });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const payoutWithThunes =
  ({ orderId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.orders.payoutWithThunes({
        orderId
      });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getUaeManualNonReconciled =
  ({ page, pageSize, orderId, isUpdatedByAgent, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.orders.getUaeManualNonReconciled({
        page,
        pageSize,
        orderId,
        isUpdatedByAgent
      });
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getOrderReceiptUrl =
  ({ orderId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.orders.getOrderReceiptLink({ orderId });
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const updateBrnAgainstOrder =
  ({ orderId, brnNumber, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.orders.updateBrnAgainstOrder({ orderId, brnNumber });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getPendingOrderReceipts =
  ({
    orderId,
    pageNumber,
    pageSize,
    startDate,
    endDate,
    receiptStatus,
    onComplete,
    onSuccess
  }) =>
  async (dispatch) => {
    try {
      const { data } = await api.orders.getNotReviewedOrderReceipts({
        pageNumber,
        pageSize,
        orderId,
        startDate,
        endDate,
        receiptStatus
      });
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const acceptOrderReceipt =
  ({ receiptId, paymentReferenceId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.orders.acceptOrderReceipt({
        receiptId,
        paymentReferenceId
      });
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const rejectOrderReceipt =
  ({ receiptId, rejectionNote, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.orders.rejectOrderReceipt({
        receiptId,
        rejectionNote
      });
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getOrderReceiptsByOrderId =
  ({ orderId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.orders.getOrderReceiptsByOrderId({ orderId });
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const forceSyncLuluOrder =
  ({ orderId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.orders.forceSyncLuluOrder({ orderId });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };
