import api from '../../api';
import { LULU_DASHBOARD_CONSTANTS as types } from '../constants';
import { setAlert } from './alertActions';


export const getLuluRefunds = ({
  payload,
  onSuccess,
  onComplete
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_LULU_REFUNDS,
    })

    const { data } = await api.luluRefundsDashboard.getLuluRefunds({ payload });

    if(onSuccess) {
      onSuccess(data);
    }

    dispatch({
      type: types.GET_LULU_REFUNDS_SUCCESS,
      payload: data?.data,
    })
  } catch(error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
    dispatch({
      type: types.GET_LULU_REFUNDS_FAILURE,
      payload: error.message
    });
  } finally {
    onComplete && onComplete();
  }
}

export const getLuluRefundSheets = ({
 onSuccess,
 onComplete
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_LULU_REFUND_SHEETS,
    })

    const { data }  = await api.luluRefundsDashboard.getLuluRefundSheets();

    if(onSuccess) {
      onSuccess(data);
    }

    dispatch({
      type: types.GET_LULU_REFUND_SHEETS_SUCCESS,
      payload: data?.data,
    });
  } catch(error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
    dispatch({
      type: types.GET_LULU_REFUNDS_SHEETS_FAILURE,
      payload: error.message
    });
  } finally {
    onComplete && onComplete();
  }
}

export const downloadLuluRefundSheet = ({
  sheetId,
  onSuccess,
  onComplete
}) => async (dispatch) => {
  try {

    const { data }  = await api.luluRefundsDashboard.downloadSheet({sheetId});

    if(onSuccess) {
      onSuccess(data);
    }

    dispatch(
      setAlert({
        title: 'Successfully downloaded the refund sheet!',
        status: 'success'
      })
    );
  } catch(error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
  } finally {
    onComplete && onComplete();
  }
}


export const processLuluRefundSheet = ({
  sheetId,
  onSuccess,
  onComplete
}) => async (dispatch) => {
  try {

    const { data }  = await api.luluRefundsDashboard.processSheet({sheetId});

    if(onSuccess) {
      onSuccess(data);
    }

    dispatch(
      setAlert({
        title: 'Successfully processed the refund sheet!',
        status: 'success'
      })
    );
  } catch(error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
  } finally {
    onComplete && onComplete();
  }
}

export const uploadLuluRefundSheet = ({
  refundSheetDetails,
  onSuccess,
  onComplete
}) => async (dispatch) => {
  try {

    const { data }  = await api.luluRefundsDashboard.uploadLuluRefundSheet({refundSheetDetails});

    if(onSuccess) {
      onSuccess(data);
    }

    dispatch(
      setAlert({
        title: 'Successfully uploaded the refund sheet!',
        status: 'success'
      })
    );
  } catch(error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
  } finally {
    onComplete && onComplete();
  }
}

export const uploadMarkCompleteRefundSheet = ({
  completedSheetDetail,
  onSuccess,
  onComplete
}) => async (dispatch) => {
  try {

    const { data }  = await api.luluRefundsDashboard.uploadMarkCompleteRefundSheet({completedSheetDetail});

    if(onSuccess) {
      onSuccess(data);
    }

    dispatch(
      setAlert({
        title: 'Successfully marked as completed the refund sheet!',
        status: 'success'
      })
    );
  } catch(error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
  } finally {
    onComplete && onComplete();
  }
}



export const createRefundSheet = ({
  onSuccess,
  onComplete
}) => async (dispatch) => {
  try {

    const { data }  = await api.luluRefundsDashboard.createRefundSheet();

    if(onSuccess) {
      onSuccess(data);
    }

    dispatch(
      setAlert({
        title: 'Successfully created the refund sheet!',
        status: 'success'
      })
    );
  } catch(error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
  } finally {
    onComplete && onComplete();
  }
}