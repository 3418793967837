import React, { useState, useEffect } from 'react';
import {
  Box,
  Checkbox,
  VStack,
  Text,
  Stack,
  Collapse,
  useDisclosure,
  HStack,
  Button
} from '@chakra-ui/react';

const FilterSelect = ({ filtersData, handleFilterSubmit }) => {
  return (
    <HStack spacing={4} align="stretch">
      {filtersData.map((filter) => (
        <FilterDropdown
          handleFilterSubmit={handleFilterSubmit}
          key={filter.key}
          filterKey={filter.key}
          title={filter.title}
          filters={filter.filters}
        />
      ))}
    </HStack>
  );
};

const FilterDropdown = ({ handleFilterSubmit, filterKey, title, filters }) => {
  const { isOpen, onToggle } = useDisclosure();
  const [selectedFilters, setSelectedFilters] = useState(filters || []);
  const handleApply = (e) => {
    handleFilterSubmit &&
      handleFilterSubmit({ key: filterKey, filters: selectedFilters });
    onToggle();
  };
  const handleCheckboxChange = (filterValue) => {
    setSelectedFilters((prevSelected) =>
      prevSelected.includes(filterValue)
        ? prevSelected.filter((value) => value !== filterValue)
        : [...prevSelected, filterValue]
    );
  };

  return (
    <Box>
      <Box
        bg="shadedGray.800"
        color="white"
        px={4}
        py={2}
        borderRadius="md"
        cursor="pointer"
        onClick={onToggle}
        _hover={{ bg: 'shadedGray.700' }}
      >
        <Text fontWeight="bold">
          {title} {isOpen ? '▲' : '▼'}
        </Text>
      </Box>
      <Collapse in={isOpen} animateOpacity>
        <Box
          mt={2}
          bg="shadedGray.900"
          border="1px solid"
          borderColor="shadedGray.700"
          borderRadius="md"
          p={4}
        >
          <Stack spacing={2} overflowY={'scroll'} maxH={'48'}>
            {filters.map((filter, index) => (
              <Checkbox
                key={index}
                colorScheme="brand"
                value={filter}
                isChecked={selectedFilters.includes(filter)}
                onChange={() => handleCheckboxChange(filter)}
                color="white"
              >
                {filter}
              </Checkbox>
            ))}
          </Stack>
          <Button
            mt={4}
            colorScheme="brand"
            bg="brand.400"
            color="white"
            _hover={{ bg: 'brand.500' }}
            onClick={handleApply}
            size="sm"
          >
            Apply
          </Button>
        </Box>
      </Collapse>
    </Box>
  );
};
export default FilterSelect;
