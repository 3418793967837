import {
  Divider,
  Grid,
  HStack,
  Link,
  Spinner,
  Stack,
  StackDivider,
  Text,
  VStack
} from '@chakra-ui/react';
import { BsChevronRight } from 'react-icons/bs';
import { getKycV2DetailsTable, getVanceKycDetailsList, mapVanceKycDetails } from './utils';
import { Link as ReactLink } from 'react-router-dom';
import {isAllowedKycProviderForSync} from "../Kyc/kycUiUtils";
import {AiOutlineSync} from "react-icons/ai";
import React, {useCallback, useState} from "react";
import { syncVanceUserKyc } from "../../store/actions/kycActions";
import {useDispatch} from "react-redux";
import {PRODUCT} from "../../constants";
import VanceUserKycDetails from "./VanceUserKycDetails";
import { getUserById } from '../../store/actions/usersActions';

const KycV2Details = ({
    vanceKycData,
    kycData,
    username,
    userId,
    country
}) => {
  const dispatch  = useDispatch();
  const [isSyncLoading, setIsSyncLoading] = useState(false);

  const kycDetailsList = getKycV2DetailsTable(kycData, userId, country);
  const vanceKycDetail = mapVanceKycDetails(vanceKycData);
  const vanceKycDetailsList = getVanceKycDetailsList(vanceKycData);

  const triggerSyncVanceUserKyc = useCallback(
    ({ userId, product }) => {
      setIsSyncLoading(true);
      dispatch(
        syncVanceUserKyc({
          userId,
          product,
          onFinish: () => {
            setIsSyncLoading(false)
            dispatch(getUserById(userId));
          }
        })
      );
    }, [dispatch]);

  const handleSyncButtonClick = useCallback(() => {
    triggerSyncVanceUserKyc({
      userId,
      product: PRODUCT.REMITTANCE
    });
  }, [userId, triggerSyncVanceUserKyc]);

  return (
    <Stack
      my={0}
      pt={{ base: 2, xl: 0 }}
      alignSelf={{ base: 'flex-start', xl: 'center' }}
      width={{ base: '100%', xl: 'auto' }}
    >
      <VStack
        p={4}
        border={'1px solid gray'}
        rounded={'lg'}
        w={{ base: '100%', xl: '24rem' }}
        divider={
          <StackDivider style={{ margin: '0' }} borderColor="whiteAlpha.300" />
        }
      >
        <HStack
          alignSelf="start"
          spacing={'2.5rem'}
          justifyContent="space-around"
          mb={{ base: '1.25rem', lg: 0 }}
          width={{ base: '100%', xl: 'auto' }}
          py={3}
        >
          <Text fontSize={'lg'} color="gray">
            User Verifications
          </Text>
        </HStack>
          <VStack w={'100%'} pt={2}>
            {vanceKycDetail && (
              <VStack
                w={'100%'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Grid
                  gridTemplateColumns={'1fr 1fr'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  w={'100%'}
                >
                  <Text color={'gray'}>{vanceKycDetail.title}</Text>
                  {vanceKycDetail.showLink ? (
                    <HStack justifySelf={'flex-end'}>
                      <Link
                        as={ReactLink}
                        to={vanceKycDetail.detailsLink}
                        color={vanceKycDetail.color}
                        justifyContent={'flex-start'}
                        w={'100%'}
                        fontSize={'sm'}
                      >
                        <HStack>
                          <Text>{vanceKycDetail.value}</Text>
                          <BsChevronRight display={'inline'} />
                        </HStack>
                      </Link>
                    </HStack>
                  ) : (
                    <HStack
                      color={vanceKycDetail.color || 'white'}
                      justifySelf={'flex-end'}
                      fontSize={'sm'}
                    >
                      {isAllowedKycProviderForSync(vanceKycDetail.provider) &&
                        (!isSyncLoading ? (
                          <AiOutlineSync
                            onClick={handleSyncButtonClick}
                            color={'white'}
                            cursor={'pointer'}
                          />
                        ) : (
                          <Spinner color={'white'} size={'sm'} />
                        ))}
                      <Text
                        margin={0}
                      >{vanceKycDetail.value}</Text>
                      {vanceKycDetail.showTooltip ? (
                        <VanceUserKycDetails
                          details={vanceKycDetailsList}
                        />
                      ) : (
                        <BsChevronRight display={'inline'} color={'black'} />
                      )}
                    </HStack>
                  )}
                </Grid>
              </VStack>
            )}
          <Divider/>
          {kycDetailsList.map((kyc) => (
            <VStack w={'100%'} key={kyc.title}>
              <Grid
                gridTemplateColumns={'1fr 1fr'}
                justifyContent={'space-between'}
                alignItems={'space-between'}
                w={'100%'}
              >
                <Text color={'gray'}>{kyc.title}</Text>
                {kyc.showLink ? (
                  <HStack justifySelf={'flex-end'}>
                    <Link
                      as={ReactLink}
                      to={kyc.detailsLink}
                      color={kyc.color}
                      justifyContent={'flex-start'}
                      w={'100%'}
                      fontSize={'sm'}
                    >
                      <HStack>
                        <Text>{kyc.value}</Text>
                        <BsChevronRight display={'inline'} />
                      </HStack>
                    </Link>
                  </HStack>
                ) : (
                  <HStack
                    color={kyc.color || 'white'}
                    justifySelf={'flex-end'}
                    fontSize={'sm'}
                  >
                    <Text>{kyc.value}</Text>
                    <BsChevronRight display={'inline'} color={'black'} />
                  </HStack>
                )}
              </Grid>
            </VStack>
          ))}
        </VStack>
      </VStack>
    </Stack>
  );
};

export default KycV2Details;
