import {
  Box,
  Grid,
  HStack,
  Stack,
  StackDivider,
  Text,
  VStack,
  Link
} from '@chakra-ui/react';
import React from 'react';
import CopyableText from '../CopyableText';
import genericCardFactory from './genericCardFactory';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { Link as ReactLink } from 'react-router-dom';

/* Generic Card component to build Reusable Cards */
const GenericCard = ({
  cardData,
  cardType,
  maxW,
  ActionButtons, //list of button components
  ...props
}) => {
  const {
    header,
    headerGridTemplateColumns,
    content,
    contentGridTemplateColumns
  } = genericCardFactory({ cardData, cardType, ...props });
  return (
    <Box position={'relative'} w={{ base: '100%', md: '100%' }} maxW={maxW || '850px'}>
      <VStack
        bg="backGround"
        borderRadius="12px"
        divider={
          <StackDivider style={{ margin: '0' }} borderColor="whiteAlpha.300" />
        }
      >
        <Grid
          gridTemplateColumns={
            headerGridTemplateColumns
              ? headerGridTemplateColumns
              : {
                  base: 'repeat(2, auto)',
                  md: `repeat(${header?.length}, auto)`
                }
          }
          rowGap={2}
          w={'90%'}
          justifyContent="space-between"
          m={'1rem'}
        >
          {header.length > 0 && header?.map((field, i) => (
            <HStack key={i}>
              <Text fontSize={{ base: 'sm', sm: 'md' }} color="gray">
                {field.name}
                {field.name?.length ? ':' : ''}
              </Text>
              {field.isPopup ? (
                <MdOutlineOpenInNew
                  color={'gray'}
                  cursor={'pointer'}
                  onClick={field.onClick}
                />
              ) : field.isCopyable ? (
                <CopyableText
                  title={field.name}
                  fontSize={{ base: 'sm', sm: 'md' }}
                  color="white"
                >
                  {field.value}
                </CopyableText>
              ) : (
                <Text fontSize={{ base: 'sm', sm: 'md' }} color="white">
                  {field.value}
                </Text>
              )}
            </HStack>

            ))}
        </Grid>
        <Grid
          gridTemplateColumns={{
            base: '1fr',
            md: contentGridTemplateColumns
              ? contentGridTemplateColumns
              : `${content?.map((field) => '1fr ')} ${ActionButtons?.map(
                  (actionButton) => 'auto '
                )}`.replaceAll(',', '')
          }}
          gap={2}
          alignItems={'flex-start'}
          w={'90%'}
          m={'1rem'}
          textAlign={{ base: 'end', md: 'start' }}
        >
          {content.length > 0 && content?.map((field, i) => (
            <Stack
              key={i}
              direction={{ base: 'row', md: 'column' }}
              justifyContent={'space-between'}
            >
              <Text fontSize={'sm'} color={'gray'}>
                {field.name}
                {field.name?.length ? ':' : ''}
              </Text>
              {!field?.value?.length ? (
                <Text fontSize={{ base: 'sm', sm: 'md' }} color="white">
                  -
                </Text>
              ) : field.isLink && field.value !== '-' ? (
                <Link
                  as={ReactLink}
                  to={field.route}
                  color="white"
                  _hover={{
                    textDecor: 'underline'
                  }}
                  fontSize={'sm'}
                >
                  <HStack>
                    <Text>{field.value}</Text>
                  </HStack>
                </Link>
              ) : field.isPopup ? (
                <MdOutlineOpenInNew
                  color={'gray'}
                  cursor={'pointer'}
                  onClick={field.onClick}
                />
              ) : field.isCopyable ? (
                <CopyableText
                  title={field.name}
                  fontSize={{ base: 'sm', sm: 'sm' }}
                  color="white"
                >
                  {field.value}
                </CopyableText>
              ) : (
                <Text fontSize={{ base: 'sm', sm: 'sm' }} color="white">
                  {field.value}
                </Text>
              )}
            </Stack>
          ))}
          {ActionButtons}
        </Grid>
      </VStack>
    </Box>
  );
};

export default GenericCard;
