import {
  Heading,
  Spinner,
  Table,
  Tbody,
  VStack,
  Tr,
  Td,
  Text
} from '@chakra-ui/react';
import { tableCellStyles } from '../../NrOnboard/constants';
import {
  camelCaseToSpaceSeparated,
  getNumberFormatBasedOnCountry
} from '../../../utils';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { number } from 'zod';

// args can be used for additional arguments for the action if there are any
const BalanceTemplate = ({ title, action, args = {} }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [balanceData, setBalanceData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      action({
        onSuccess: (data) => {
          setBalanceData(data);
        },
        onComplete: () => {
          setIsLoading(false);
        },
        ...args
      })
    );
  }, []);

  const cellStyles = tableCellStyles();
  const decimalAdjusterDueToCurrencyInMinor = args?.decimalAdjustment || 1; // This is because banks use smallest denomonation for accuracy in some cases - paisa for india
  const numberFormatter = getNumberFormatBasedOnCountry(args?.country || 'UK');
  const formatCurrency = (
    amount,
    currency = numberFormatter.currency || 'GBP'
  ) => {
    return new Intl.NumberFormat(numberFormatter.formatCode || 'en-US', {
      style: 'currency',
      currency
    }).format(amount / decimalAdjusterDueToCurrencyInMinor);
  };
  return (
    <VStack gap={2} alignItems={'stretch'} width={'fit-content'}>
      <Heading color={'white'} size={'sm'}>
        {title}
      </Heading>
      {!isLoading ? (
        <>
          {!balanceData ? (
            <>
              <Text color={'gray.500'} fontSize={'md'}>
                Something went wrong
              </Text>
            </>
          ) : (
            <Table>
              <Tbody>
                {Object.keys(balanceData)?.map((key) => (
                  <Tr key={key}>
                    <Td style={cellStyles} fontSize={'md'}>
                      <Text color={'white'}>
                        {formatCurrency(balanceData[key]) || '-'}
                      </Text>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </>
      ) : (
        <Spinner color="#81EBAB" />
      )}
    </VStack>
  );
};

export default BalanceTemplate;
