const FALCON_DASHBOARD_CONSTANTS = {
  // CLIENT DASHBOARDS
  GET_FALCON_CLIENTS: 'GET_FALCON_CLIENTS',
  GET_FALCON_CLIENTS_SUCCESS: 'GET_FALCON_CLIENTS_SUCCESS',
  GET_FALCON_CLIENTS_FAILURE: 'GET_FALCON_CLIENTS_FAILURE',
  GET_FALCON_CLIENT_DETAILS: 'GET_FALCON_CLIENT_DETAILS',
  GET_FALCON_CLIENT_DETAILS_SUCCESS: 'GET_FALCON_CLIENT_DETAILS_SUCCESS',
  GET_FALCON_CLIENT_DETAILS_FAILURE: 'GET_FALCON_CLIENT_DETAILS_FAILURE',
  CREATE_FALCON_CLIENT: 'CREATE_FALCON_CLIENT',
  CREATE_FALCON_CLIENT_SUCCESS: 'CREATE_FALCON_CLIENT_SUCCESS',
  CREATE_FALCON_CLIENT_FAILURE: 'CREATE_FALCON_CLIENT_FAILURE',
  UPDATE_FALCON_CLIENT: 'UPDATE_FALCON_CLIENT',
  UPDATE_FALCON_CLIENT_SUCCESS: 'UPDATE_FALCON_CLIENT_SUCCESS',
  UPDATE_FALCON_CLIENT_FAILURE: 'UPDATE_FALCON_CLIENT_FAILURE',

  UPDATE_FALCON_CLIENT_PAYOUT_PARTNERS: 'UPDATE_FALCON_CLIENT_PAYOUT_PARTNERS',
  UPDATE_FALCON_CLIENT_PAYOUT_PARTNERS_SUCCESS:
    'UPDATE_FALCON_CLIENT_PAYOUT_PARTNERS_SUCCESS',
  UPDATE_FALCON_CLIENT_PAYOUT_PARTNERS_FAILURE:
    'UPDATE_FALCON_CLIENT_PAYOUT_PARTNERS_FAILURE',
  // Falcon Client Balance Manager
  GET_FALCON_CLIENT_BALANCE_DETAILS: 'GET_FALCON_CLIENT_BALANCE_DETAILS',
  GET_FALCON_CLIENT_BALANCE_DETAILS_SUCCESS: 'GET_FALCON_CLIENT_BALANCE_DETAILS_SUCCESS',
  GET_FALCON_CLIENT_BALANCE_DETAILS_FAILURE: 'GET_FALCON_CLIENT_BALANCE_DETAILS_FAILURE',
  UPDATE_FALCON_CLIENT_BALANCE: 'UPDATE_FALCON_CLIENT_BALANCE',
  GETTING_FALCON_CLIENT_BALANCE_HISTORY: 'GETTING_FALCON_CLIENT_BALANCE_HISTORY',
  GET_FALCON_CLIENT_BALANCE_HISTORY_SUCCESS: 'GET_FALCON_CLIENT_BALANCE_HISTORY_SUCCESS',
  GET_FALCON_CLIENT_BALANCE_HISTORY_FAILURE: 'GET_FALCON_CLIENT_BALANCE_HISTORY_FAILURE',
  // TRANSACTION DASHBOARDS

  GET_FALCON_TRANSACTIONS: 'GET_FALCON_TRANSACTIONS',
  GET_FALCON_TRANSACTIONS_SUCCESS: 'GET_FALCON_TRANSACTIONS_SUCCESS',
  GET_FALCON_TRANSACTIONS_FAILURE: 'GET_FALCON_TRANSACTIONS_FAILURE',

  GET_TRANSACTION_DETAILS: 'GET_TRANSACTION_DETAILS',
  GET_TRANSACTION_DETAILS_SUCCESS: 'GET_TRANSACTION_DETAILS_SUCCESS',
  GET_TRANSACTION_DETAILS_FAILURE: 'GET_TRANSACTION_DETAILS_FAILURE',
  GET_TRANSACTION_FILTERS: 'GET_TRANSACTION_FILTERS',
  GET_TRANSACTION_FILTERS_SUCCESS: 'GET_TRANSACTION_FILTERS_SUCCESS',
  GET_TRANSACTION_CONFIGS: 'GET_TRANSACTION_CONFIGS',
  GET_TRANSACTION_CONFIGS_SUCCESS: 'GET_TRANSACTION_CONFIGS_SUCCESS',
  FAIL_TRANSACTION: 'FAIL_TRANSACTION',
  FAIL_TRANSACTION_SUCCESS: 'FAIL_TRANSACTION_SUCCESS',
  FORCE_SYNC_TRANSACTION: 'FORCE_SYNC_TRANSACTION',
  FORCE_SYNC_TRANSACTION_SUCCESS: 'FORCE_SYNC_TRANSACTION_SUCCESS',
  GET_TRANSACTION_COMPONENTS: 'GET_TRANSACTION_COMPONENTS',
  GET_TRANSACTION_COMPONENTS_SUCCESS: 'GET_TRANSACTION_COMPONENTS_SUCCESS',

  // VENDOR DASHBOARD
  GET_FALCON_VENDORS: 'GET_FALCON_VENDORS',
  GET_FALCON_VENDORS_SUCCESS: 'GET_FALCON_VENDORS_SUCCESS',
  GET_FALCON_VENDORS_FAILURE: 'GET_FALCON_VENDORS_FAILURE',
  GET_FALCON_VENDOR_DETAILS: 'GET_FALCON_VENDOR_DETAILS',
  GET_FALCON_VENDOR_DETAILS_SUCCESS: 'GET_FALCON_VENDOR_DETAILS_SUCCESS',
  GET_FALCON_VENDOR_DETAILS_FAILURE: 'GET_FALCON_VENDOR_DETAILS_FAILURE',
  CREATE_FALCON_VENDOR: 'CREATE_FALCON_VENDOR',
  CREATE_FALCON_VENDOR_SUCCESS: 'CREATE_FALCON_VENDOR_SUCCESS',
  CREATE_FALCON_VENDOR_FAILURE: 'CREATE_FALCON_VENDOR_FAILURE',
  UPDATE_FALCON_VENDOR: 'UPDATE_FALCON_VENDOR',
  UPDATE_FALCON_VENDOR_SUCCESS: 'UPDATE_FALCON_VENDOR_SUCCESS',
  UPDATE_FALCON_VENDOR_FAILURE: 'UPDATE_FALCON_VENDOR_FAILURE',

  GET_DASHBOARD_CONFIG: 'GET_DASHBOARD_CONFIG',
  GET_DASHBOARD_CONFIG_SUCCESS: 'GET_DASHBOARD_CONFIG_SUCCESS',
  GET_DASHBOARD_CONFIG_FAILURE: 'GET_DASHBOARD_CONFIG_FAILURE',

  // RFI TRANSACTION DASHBOARD
  GET_RFI_TRANSACTIONS: 'GET_RFI_TRANSACTIONS',
  GET_RFI_TRANSACTIONS_SUCCESS: 'GET_RFI_TRANSACTIONS_SUCCESS',
  GET_RFI_TRANSACTIONS_FAILURE: 'GET_RFI_TRANSACTIONS_FAILURE',
  GET_RFI_TRANSACTION_DETAILS: 'GET_RFI_TRANSACTION_DETAILS',
  GET_RFI_TRANSACTION_DETAILS_SUCCESS: 'GET_RFI_TRANSACTION_DETAILS_SUCCESS',
  GET_RFI_TRANSACTION_DETAILS_FAILURE: 'GET_RFI_TRANSACTION_DETAILS_FAILURE',
  SUBMITTED_TO_PARTNER: 'SUBMITTED_TO_PARTNER',
  SUBMITTED_TO_PARTNER_SUCCESS: 'SUBMITTED_TO_PARTNER_SUCCESS',
  SUBMITTED_TO_PARTNER_FAILURE: 'SUBMITTED_TO_PARTNER_FAILURE',
  REJECTED_BY_PARTNER: 'REJECTED_BY_PARTNER',
  REJECTED_BY_PARTNER_SUCCESS: 'REJECTED_BY_PARTNER_SUCCESS',
  REJECTED_BY_PARTNER_FAILURE: 'REJECTED_BY_PARTNER_FAILURE',
  SUBMIT_RFI_DOCUMENTS: 'SUBMIT_RFI_DOCUMENTS',
  SUBMIT_RFI_DOCUMENTS_SUCCESS: 'SUBMIT_RFI_DOCUMENTS_SUCCESS',
  SUBMIT_RFI_DOCUMENTS_FAILURE: 'SUBMIT_RFI_DOCUMENTS_FAILURE'
};

export default FALCON_DASHBOARD_CONSTANTS;
