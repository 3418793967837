export default function cxOperations(fetch, baseUrl) {
  return {
    updateUserEmail({ userId, email }) {
      return fetch.put(`${baseUrl}/user/${userId}/update-email`, { email });
    },
    updateUserPhone({ userId, code, phoneNumber }) {
      return fetch.put(`${baseUrl}/user/${userId}/update-phone`, {
        code,
        phoneNo: phoneNumber
      });
    }
  };
}
