import {
  Button,
  Heading,
  Input,
  ModalBody,
  ModalHeader,
  VStack
} from '@chakra-ui/react';
import React, { useState } from 'react';

const RejectModal = ({ rejectionHeading, handleReject }) => {
  const [reason, setReason] = useState('');

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  return (
    <>
      <ModalHeader>
        <Heading size={'md'}>
          {`Rejection Reason for ${rejectionHeading}`}
        </Heading>
      </ModalHeader>
      <ModalBody pb={'2rem'}>
        <VStack alignItems={'start'} gap={4}>
          <Input
            placeholder="Enter the reason for rejection"
            colorScheme="brand"
            onChange={handleReasonChange}
          />
          <Button
            colorScheme="red"
            alignSelf="end"
            variant={'outline'}
            onClick={() => handleReject(reason)}
            isDisabled={reason.trim().length === 0}
          >
            Reject Information
          </Button>
        </VStack>
      </ModalBody>
    </>
  );
};

export default RejectModal;
