export const TRANSFER_DELAY_TABLE_CONFIG = [
    {
        value: "fulfillmentProvider",
        label: "Fulfillment Provider",
        caption: null,
    },
    {
        value: "averageTime",
        label: "Average Time",
        caption: "(in hrs)",
    },
    {
        value: "currentTime",
        label: "Current Time",
        caption: "(in hrs)",
    },
    {
        value: "warningThreshold",
        label: "Warning Threshold",
        caption: "(in hrs)",
    },
    {
        value: "alertThreshold",
        label: "Alert Threshold",
        caption: "(in hrs)",
    },
    {
        value: "customWarningMessage",
        label: "Custom Warning Message",
        caption: null,
    },
    {
        value: "customAlertMessage",
        label: "Custom Alert Message",
        caption: null,
    },
];

export const TRANSFER_DELAY_FORM_FIELD_CONFIG = [
    {
        value: "fulfillmentProvider",
        label: "Fulfillment Provider",
        isEditable: false,
        fieldType: "header",
    },
    {
        value: "averageTime",
        label: "Average Time",
        caption: "(in hours)",
        isEditable: true,
        fieldType: "input",
        dataType: "number"
    },
    {
        value: "currentTime",
        label: "Current Time",
        caption: "(in hours)",
        isEditable: true,
        fieldType: "input",
        dataType: "number",
    },
    {
        value: "warningThreshold",
        label: "Warning Threshold",
        caption: "(in hours)",
        isEditable: true,
        fieldType: "input",
        dataType: "number",
    },
    {
        value: "alertThreshold",
        label: "Alert Threshold",
        caption: "(in hours)",
        isEditable: true,
        fieldType: "input",
        dataType: "number",
    },
    {
        value: "customWarningMessage",
        label: "Custom Warning Message",
        isEditable: true,
        fieldType: "textarea",
        dataType: "string",
    },
    {
        value: "customAlertMessage",
        label: "Custom Alert Message",
        isEditable: true,
        fieldType: "textarea",
        dataType: "string",
    },
];
