import { KYC_PROVIDER } from '../../constants';

// KYC UPDATE STATES HANDLING
export const ALLOWED_STATES_FOR_UPDATE = {
  [KYC_PROVIDER.EFR]: {
    VERIFY: ['DUPLICATE_CHECK'],
    REJECT: ['DUPLICATE_CHECK', 'EFR_VERIFICATION']
  },
  [KYC_PROVIDER.COMPLY_CUBE]: {
    VERIFY: ['IDENTITY', 'PROOF_OF_IDENTITY', 'DUPLICATE_CHECK'],
    REJECT: ['IDENTITY', 'PROOF_OF_IDENTITY', 'DUPLICATE_CHECK']
  },
  [KYC_PROVIDER.SABER]: {
    VERIFY: ['ON_RAMP_KYC_DATA'],
    REJECT: ['ON_RAMP_KYC_DATA']
  },
  [KYC_PROVIDER.SUMSUB]: {
    BLOCK: ['PROOF_OF_IDENTITY']
  }
};

export const canVerifyDataType = (kycProvider, dataType, kycStatus) => {
  return (
    (kycStatus === 'PENDING_FOR_VERIFICATION' ||
      (kycStatus === 'DRAFT' && dataType === 'DUPLICATE_CHECK')) &&
    ALLOWED_STATES_FOR_UPDATE.hasOwnProperty(kycProvider) &&
    ALLOWED_STATES_FOR_UPDATE[kycProvider].VERIFY !== undefined &&
    ALLOWED_STATES_FOR_UPDATE[kycProvider].VERIFY.includes(dataType)
  );
};

export const canRejectDataType = (kycProvider, dataType, kycStatus) => {
  return (
    (kycStatus === 'PENDING_FOR_VERIFICATION' ||
      (kycStatus === 'DRAFT' && dataType === 'DUPLICATE_CHECK')) &&
    ALLOWED_STATES_FOR_UPDATE.hasOwnProperty(kycProvider) &&
    ALLOWED_STATES_FOR_UPDATE[kycProvider].REJECT !== undefined &&
    ALLOWED_STATES_FOR_UPDATE[kycProvider].REJECT.includes(dataType)
  );
};

export const canBlockDataType = (kycProvider, dataType, kycStatus) => {
  return (
    kycStatus === 'PENDING_FOR_VERIFICATION' &&
    ALLOWED_STATES_FOR_UPDATE.hasOwnProperty(kycProvider) &&
    ALLOWED_STATES_FOR_UPDATE[kycProvider].BLOCK !== undefined &&
    ALLOWED_STATES_FOR_UPDATE[kycProvider].BLOCK.includes(dataType)
  );
};

// SYNC STATES HANDLING
const allowedProvidersForSync = [
  KYC_PROVIDER.EFR,
  KYC_PROVIDER.SABER,
  KYC_PROVIDER.COMPLY_CUBE,
  KYC_PROVIDER.SUMSUB,
  KYC_PROVIDER.VANCE
];

export const isAllowedKycProviderForSync = (provider) =>
  allowedProvidersForSync.includes(provider);

// DASHBOARD UI HANDLING

const providerDashboardConfig = {
  [KYC_PROVIDER.COMPLY_CUBE]: {
    text: 'Comply Cube Dashboard',
    url: 'https://portal.complycube.com/clients/{clientId}#tab-checks'
  },
  [KYC_PROVIDER.SUMSUB]: {
    text: 'Sumsub Dashboard',
    url: 'https://cockpit.sumsub.com/checkus/#/applicant/{clientId}/basicInfo?clientId=vance.tech_45146'
  }
};

export const getProviderDashboardConfigOrNull = (kycProvider) => {
  if (providerDashboardConfig[kycProvider] === undefined) {
    return null;
  }
  return providerDashboardConfig[kycProvider];
};

export const getUserKycStatusDetailsTable = (userKycStatusDetails) => {
  if (!userKycStatusDetails) {
    return null;
  }

  return [
    {
      title: 'User KYC Status Details',
      data: [
        {
          name: 'Emirates Id ',
          isCopyable: true,
          value: userKycStatusDetails.emiratesId || '-'
        },
        {
          name: 'Phone ',
          isCopyable: true,
          value: userKycStatusDetails.phone || '-'
        }
      ],
      tableData:
        userKycStatusDetails?.userKycStatus?.map((item) => ({
          userId: item.userId || '-',
          kycStatus: item.kycStatus || '-'
        })) || []
    }
  ];
};
