export default function rfiApi(fetch, baseUrl) {
  return {
    getRFIs(data) {
      return fetch.post(`${baseUrl}/search`, data);
    },
    getRFIDetails(orderId, rfiType) {
      return fetch.get(`${baseUrl}/${orderId}/${rfiType}`);
    },
    createRFI(rfiData) {
      return fetch.post(`${baseUrl}`, rfiData);
    },
    updateRFI(orderId, rfiType, rfiData) {
      return fetch.put(`${baseUrl}/${orderId}/${rfiType}`, rfiData);
    },
    isRFIEnabled(userId) {
      return fetch.get(`${baseUrl}/${userId}/device-check`);
    }
  };
}
