import api from '../../api';
import { setAlert } from './alertActions';

export const getRewardFunnelData =
  ({ pageSize, pageNumber, rewardType, rewardStatus, onSuccess, onComplete }) =>
    async (dispatch) => {
      try {
        const { data } = await api.rewards.getFunnelData({
          pageNumber,
          pageSize,
          rewardType,
          rewardStatus
        });
        onSuccess && onSuccess(data);
      } catch (error) {
        dispatch(
          setAlert({
            title: error?.message,
            status: 'error'
          })
        );
      } finally {
        onComplete && onComplete();
      }
    };

export const getRewardFunnelDataByQuery =
  ({ pageSize, pageNumber, rewardType, rewardStatus, query, onSuccess, onComplete }) =>
    async (dispatch) => {
      try {
        const { data } = await api.rewards.getFunnelDataByQuery({
          pageNumber,
          pageSize,
          rewardType,
          rewardStatus,
          query
        });
        onSuccess && onSuccess(data);
      } catch (error) {
        dispatch(
          setAlert({
            title: error?.message,
            status: 'error'
          })
        );
      } finally {
        onComplete && onComplete();
      }
    };

export const confirmReward =
  ({ userId, onSuccess, onComplete }) =>
    async (dispatch) => {
      try {
        await api.rewards.confirmReward({ userId });
        onSuccess && onSuccess();
      } catch (error) {
        dispatch(
          setAlert({
            title: error?.message,
            status: 'error'
          })
        );
      } finally {
        onComplete && onComplete();
      }
    };

export const invalidateReward =
  ({ userId, onSuccess, onComplete }) =>
    async (dispatch) => {
      try {
        await api.rewards.invalidateReward({ userId });
        onSuccess && onSuccess();
      } catch (error) {
        dispatch(
          setAlert({
            title: error?.message,
            status: 'error'
          })
        );
      } finally {
        onComplete && onComplete();
      }
    };

export const getReferralUserId =
  ({ referrerId, onSuccess, onComplete }) =>
    async (dispatch) => {
      try {
        const { data } = await api.rewards.getReferralUserId({ referrerId });
        onSuccess && onSuccess(data);
      } catch (error) {
        dispatch(
          setAlert({
            title: error?.message,
            status: 'error'
          })
        );
      } finally {
        onComplete && onComplete();
      }
    };

