export default function luluRefundsDashboard(fetch, baseUrl) {
  return {
    getLuluRefunds({ payload }) {
      return fetch.post(`${baseUrl}/search`, payload);
    },

    createRefundSheet(){
      return fetch.post(`${baseUrl}/create-refund-sheet`);
    },

    uploadLuluRefundSheet({ refundSheetDetails }) {
      return fetch.post(`${baseUrl}/upload-sheet`, {
        refundSheetDetails
      });
    },

    uploadMarkCompleteRefundSheet({ completedSheetDetail }) {
      return fetch.post(`${baseUrl}/mark-sheet-completed`, {
        completedSheetDetail
      });
    },

    getLuluRefundSheets() {
      return fetch.get(`${baseUrl}/refund-sheets`);
    },

    downloadSheet({ sheetId }) {
      return fetch.get(`${baseUrl}/download-refund-sheet/${sheetId}`, {
        headers: {'Accept': 'application/octet-stream'},
        responseType: 'blob'
      });
    },

    processSheet({ sheetId }) {
      return fetch.get(`${baseUrl}/mark-as-processed/${sheetId}`);
    },
  };
}