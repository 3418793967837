import {
  Button,
  Grid,
  Heading,
  ModalBody,
  ModalHeader,
  VStack,
  Text
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import InputComponent from '../Input';
import { useDispatch, useSelector } from 'react-redux';
import { submitRfiDocuments } from '../../store/actions/falconDashboardActions';

const UpdateRFIValue = ({ documents, onClose }) => {
  const [docValue, setDocValue] = useState({});
  const dispatch = useDispatch();
  const rfiTransaction = useSelector(
    (state) => state.falconDashboard.rfiTransaction.data
  );

  useEffect(() => {
    if (documents) {
      documents.map((doc, index) => {
        setDocValue((prevState) => ({
          ...prevState,
          [index]: doc.documentValue.value
        }));
      });
    }
  }, [documents]);

  const handleChange = (e, id) => {
    setDocValue((prevState) => ({ ...prevState, [id]: e.target.value }));
  };

  const handleSubmitDocument = () => {
    const transactionId = rfiTransaction
      .filter((trx) => trx.heading === 'Transaction Details')[0]
      .data.filter((data) => data.name === 'transactionId')[0].value;
    const data = {
      transactionId,
      documents: documents.reduce((acc, curr, index) => {
        const key = curr.documentType.value;
        const value = docValue[index];
        acc[key] = value;
        return acc;
      }, {})
    };
    dispatch(submitRfiDocuments(data));
    onClose();
  };

  return (
    <>
      <ModalHeader pt={0}>
        <Heading size={'lg'}>Update RFI Value</Heading>
      </ModalHeader>
      <ModalBody pb={4}>
        <VStack w={'full'}>
          {documents &&
            documents.map((document, id) => (
              <Grid
                w={'full'}
                gridTemplateColumns={`1fr 2fr`}
                alignItems={'center'}
                justifyContent={'space-between'}
                gap={1}
                key={id}
              >
                <Text size={'sm'} color={'gray'}>
                  {document.documentType.value}
                </Text>
                <InputComponent
                  placeholder={document.documentType.value}
                  value={docValue[id]}
                  width="100%"
                  handleChange={(e) => handleChange(e, id)}
                />
              </Grid>
            ))}
          <Button
            w={'fit-content'}
            colorScheme="brand"
            color={'black'}
            alignSelf={'flex-end'}
            onClick={handleSubmitDocument}
          >
            Submit
          </Button>
        </VStack>
      </ModalBody>
    </>
  );
};

export default UpdateRFIValue;
