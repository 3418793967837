export default function beneficiaryVerifications(fetch, baseUrl) {
    return {
        getBeneficiaryVerifications({ payload }) {
            return fetch.post(`${baseUrl}`, payload);
        },

        getBeneficiaryVerificationDetails({ verificationId }) {
            return fetch.get(`${baseUrl}/${verificationId}/verifier-details`);
        },

        forceUpdateBeneficiaryVerification({ payload }) {
            return fetch.post(`${baseUrl}/force-update`, payload);
        },

        retryBeneficiaryVerification({ payload }) {
            return fetch.post(`${baseUrl}/retry`, payload);
        }
    };
}