import React, { useEffect, useState } from 'react';
import {
  Button,
  HStack,
  Spinner,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getClientFilters,
  getClients
} from '../../store/actions/falconDashboardActions';
import { FALCON_DASHBOARD_CONSTANTS as types } from '../../store/constants';
import { EntityCard } from '../../components/FalconServiceDashboard/EntityCard';
import Pagination from '../../components/Pagination';
import { updateFalconClientReqData } from '../../store/actions/reqDataActions';
import ModalLayout from '../../components/Modal/ModalLayout';
import CreateClientModal from '../../components/FalconServiceDashboard/CreateClientModal';
import useDebounce from '../../hooks/useDebounce';
import { clientEntityConfig } from './utils';
import SearchBox from '../../components/SearchBox/SearchBox';
import useAuthRole from '../../hooks/useAuthRole';
import { ROLES } from '../../constants';
export const ClientDashboard = ({ setSelectedClient, selectedClient }) => {
  const { hasAnyAuthority } = useAuthRole();
  const dispatch = useDispatch();
  const clients = useSelector((state) => state.falconDashboard.clients);
  const configsLoading = useSelector(
    (state) => state.falconDashboard.config.isLoading
  );
  const reqData = useSelector((state) => state.reqData.falconDashboard.client);
  const [filterValue, setFilterValue] = useState({});
  const [searchValue, setSearchValue] = useState({
    searchValue: null,
    searchKey: null
  });
  const debouncedValue = useDebounce(searchValue.searchValue, 500);
  const [selectedFilter, setSelectedFilter] = useState();

  const {
    isOpen: isCreateModalOpen,
    onClose: closeCreateModal,
    onOpen: openCreateModal
  } = useDisclosure();

  useEffect(() => {
    dispatch(
      getClientFilters({
        onSuccess: (data) => {
          dispatch(
            updateFalconClientReqData({
              searches: data.search,
              filterValues: data.filters
            })
          );
        }
      })
    );
  }, []);

  useEffect(() => {
    if (debouncedValue != null)
      dispatch(
        updateFalconClientReqData({
          ...searchValue
        })
      );
  }, [debouncedValue]);

  useEffect(() => {
    if (reqData.searchKey)
      dispatch(
        getClients({ ...reqData, filters: filterValue }, (data) => {
          dispatch({
            type: types.GET_FALCON_CLIENTS_SUCCESS,
            payload: { data }
          });
          dispatch(
            updateFalconClientReqData({
              totalPages: data.totalPages,
              pageNumber: data.page,
              pageSize: data.pageSize
            })
          );
        })
      );
  }, [reqData.searchValue]);

  useEffect(() => {
    if (reqData.pageNumber != null)
      dispatch(
        getClients({ ...reqData, filters: filterValue }, (data) => {
          dispatch({
            type: types.GET_FALCON_CLIENTS_SUCCESS,
            payload: { data }
          });
          dispatch(
            updateFalconClientReqData({
              totalPages: data.totalPages,
              pageSize: data.pageSize
            })
          );
        })
      );
  }, [reqData.pageNumber]);

  const handlePageChange = (page) => {
    dispatch(
      updateFalconClientReqData({
        pageNumber: page - 1
      })
    );
  };

  const handleSearchChange = (search) => {
    setSearchValue(search);
  };

  return (
    <VStack
      alignItems={'stretch'}
      width={'full'}
      py={2}
      h={'70vh'}
      overflow={'scroll'}
    >
      <ModalLayout
        isOpen={isCreateModalOpen}
        onClose={closeCreateModal}
        size="xl"
        scrollBehavior="inside"
      >
        <CreateClientModal
          onClose={closeCreateModal}
          config={clientEntityConfig}
        />
      </ModalLayout>
      <VStack gap={2}>
        <HStack justify={'flex-end'} w={'full'}>
          {!configsLoading &&
            hasAnyAuthority(
              ROLES.ADMIN_ROLE,
              ROLES.FALCON_ADMIN,
              ROLES.FALCON_OPS_MANAGER
            ) && (
              <Button
                colorScheme="brand"
                color="black"
                onClick={openCreateModal}
              >
                Create Client
              </Button>
            )}
        </HStack>
        {!reqData.isLoading && !reqData.isError > 0 && (
          <>
            {reqData.searches && (
              <SearchBox
                searches={reqData.searches}
                handleSearchChange={handleSearchChange}
              />
            )}
            <Pagination
              onPageChange={handlePageChange}
              currentPage={reqData.pageNumber + 1}
              totalPages={reqData.totalPages}
            />
          </>
        )}
        {clients.isLoading ? (
          <Spinner color="brand.400" size="xl" thickness="4px" />
        ) : clients.data?.length > 0 ? (
          clients.data.map((client, index) => (
            <EntityCard
              entity={client}
              key={index}
              onClick={() => setSelectedClient(client)}
              selected={client===selectedClient}
            />
          ))
        ) : (
          <>No client to show</>
        )}
      </VStack>
    </VStack>
  );
};
