import { RFI_CONSTANTS as types } from '../constants';
const initialState = {
  rfis: {
    isLoading: false,
    isError: false,
    error: null,
    data: null,
    totalPages: 1,
    pageSize: 20,
    page: 0
  },
  rfi: {
    isLoading: false,
    isError: false,
    error: null,
    data: null
  },
  availableList: [],
  rfiEnabled: {
    isLoading: false,
    isError: false,
    error: null,
    data: null
  }
};

const rfiReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_RFI_ORDERS:
      return {
        ...state,
        rfis: {
          ...state.rfis,
          isLoading: true,
          isError: false
        }
      };
    case types.GET_RFI_ORDERS_SUCCESS:
      return {
        ...state,
        rfis: {
          ...state.rfis,
          isLoading: false,
          data: payload?.data?.data,
          totalPages: payload?.data?.totalPages,
          pageSize: payload?.data?.pageSize,
          page: payload?.data?.page
        }
      };
    case types.GET_RFI_ORDERS_FAILURE:
      return {
        ...state,
        rfis: {
          ...state.rfis,
          isError: true,
          error: payload?.message
        }
      };
    case types.UPDATE_RFI_PAGE_NUMBER:
      return {
        ...state,
        rfis: {
          ...state.rfis,
          page: payload?.page || 0
        }
      };
    case types.GET_RFI_DETAILS:
      return {
        ...state,
        rfi: {
          ...state.rfi,
          isLoading: true,
          data: null,
          isError: false
        }
      };
    case types.GET_RFI_DETAILS_SUCCESS:
      return {
        ...state,
        rfi: {
          ...state.rfi,
          isLoading: false,
          data: payload?.data?.data
        }
      };
    case types.GET_RFI_DETAILS_FAILURE:
      return {
        ...state,
        rfi: {
          ...state.rfi,
          isLoading: false,
          isError: true,
          error: payload?.message
        }
      };
    case types.CREATE_RFI:
      return {
        ...state,
        rfi: {
          ...state.rfi,
          isLoading: true,
          isError: false
        }
      };
    case types.CREATE_RFI_SUCCESS:
      return {
        ...state,
        rfi: {
          ...state.rfi,
          isLoading: false,
          data: payload?.data?.data
        }
      };
    case types.CREATE_RFI_FAILURE:
      return {
        ...state,
        rfi: {
          ...state.rfi,
          isLoading: false,
          isError: true,
          error: payload?.message
        }
      };
    case types.UPDATE_RFI:
      return {
        ...state,
        rfi: {
          ...state.rfi,
          isLoading: true,
          isError: false
        }
      };
    case types.UPDATE_RFI_SUCCESS:
      return {
        ...state,
        rfi: {
          ...state.rfi,
          isLoading: false,
          data: payload?.data?.data
        }
      };
    case types.UPDATE_RFI_FAILURE:
      return {
        ...state,
        rfi: {
          ...state.rfi,
          isLoading: false,
          isError: true,
          error: payload?.message
        }
      };
    case types.UPDATE_RFI_LIST:
      return {
        ...state,
        availableList: payload?.data
      };
    case types.IF_USER_RFI_ENABLED:
      return {
        ...state,
        rfiEnabled: {
          ...state.rfiEnabled,
          isLoading: true,
          isError: false
        }
      };
    case types.IF_USER_RFI_ENABLED_SUCCESS:
      return {
        ...state,
        rfiEnabled: {
          ...state.rfiEnabled,
          isLoading: false,
          data: payload?.data
        }
      };
    case types.IF_USER_RFI_ENABLED_FAILURE:
      return {
        ...state,
        rfiEnabled: {
          ...state.rfiEnabled,
          isLoading: false,
          isError: true,
          error: payload?.message
        }
      };
    default:
      return state;
  }
};

export default rfiReducer;
