import React, {useState } from 'react';

import {
  Grid,
  Heading,
  VStack,
  Divider,
  TabList,
  Tab,
  Tabs,
  Spinner,
} from '@chakra-ui/react';
import ContentLayout from '../../layouts/ContentLayout';
import { TAB_STATES } from './constants';
import { kebabCaseToSpaceSeperate } from '../../utils';
import ManageRefunds from '../../components/LuluRefundDashboard/ManageRefunds';
import ViewRefunds from '../../components/LuluRefundDashboard/ViewRefunds';
import TransactionSideCard from '../../components/LuluRefundDashboard/TransactionSideCard';


const LuluRefundsDashboard = () => {
  const [selectedTab, setSelectedTab] = useState(TAB_STATES.VIEW_REFUNDS);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const tabContentMap = {
    [TAB_STATES.VIEW_REFUNDS]:
      <ViewRefunds
        selectedTransaction={selectedTransaction}
        setSelectedTransaction={setSelectedTransaction}
      />,
    [TAB_STATES.MANAGE_REFUNDS]:
      <ManageRefunds />
  };

  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0 3fr' }}>
        <VStack
          overflowY="hidden"
          overflowX="hidden"
          h={{ lg: '90vh' }}
          py={10}
          px={{ base: 4, sm: 10 }}
          alignItems="stretch"
          gap={4}
        >
          <Heading size="lg" color="colorPrimary">
            Lulu Order Refunds Dashboard
          </Heading>
          <Tabs
            variant={'enclosed'}
            size={'md'}
            onChange={(e) => {
              setSelectedTab(Object.keys(TAB_STATES)[e]);
            }}
            defaultIndex={Object.keys(TAB_STATES).indexOf(selectedTab)}
          >
            <TabList
              color={'whiteAlpha.700'}
              borderColor={'whiteAlpha.500'}
            >
              {Object.keys(TAB_STATES).map(
                (tabState, ind) =>
                  (
                    <Tab
                      key={ind}
                      _selected={{
                        color: 'brand.500',
                        border: '1px solid',
                        borderColor: 'whiteAlpha.500',
                        borderBottom: '1px solid black'
                      }}
                    >
                      {kebabCaseToSpaceSeperate(tabState)}
                    </Tab>
                  )
              )}
            </TabList>
          </Tabs>

          {tabContentMap[selectedTab] ?? (
            <div>No content available for the selected tab.</div>
          )}
        </VStack>

        <Divider
          style={{ margin: '0' }}
          borderColor="whiteAlpha.300"
          orientation="vertical"
        />

        {selectedTransaction &&
          selectedTab === TAB_STATES.VIEW_REFUNDS &&
          (selectedTransaction ? (
            <VStack
              py={10}
              px={8}
              alignItems="flex-start"
              spacing={4}
            >
              <TransactionSideCard
                transaction={selectedTransaction}
              />
            </VStack>
          ) : (
            <Spinner
              align="center"
              color="brand.400"
              size="xl"
              thickness="4px"
            />
          ))
        }
      </Grid>
    </ContentLayout>
  );
};

export default LuluRefundsDashboard;
