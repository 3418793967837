const CONFIG = {
  GETTING_SETTLEMENT_MESSAGE: 'GETTING_SETTLEMENT_MESSAGE',
  GET_SETTLEMENT_MESSAGE_SUCCESS: 'GET_SETTLEMENT_MESSAGE_SUCCESS',
  GET_SETTLEMENT_MESSAGE_FAILURE: 'GET_SETTLEMENT_MESSAGE_FAILURE',

  GETTING_POLLING_STATUS: 'GETTING_POLLING_STATUS',
  GET_POLLING_STATUS_SUCCESS: 'GET_POLLING_STATUS_SUCCESS',
  GET_POLLING_STATUS_FAILURE: 'GET_POLLING_STATUS_FAILURE',
  CLEAR_POLLING_STATUS: 'CLEAR_POLLING_STATUS',

  UPDATING_POLLING_STATUS: 'UPDATING_POLLING_STATUS',
  UPDATE_POLLING_STATUS_SUCCESS: 'UPDATE_POLLING_STATUS_SUCCESS',
  UPDATE_POLLING_STATUS_FAILURE: 'UPDATE_POLLING_STATUS_FAILURE',

  UPDATING_SETTLEMENT_MESSAGE: 'UPDATING_SETTLEMENT_MESSAGE',
  UPDATE_SETTLEMENT_MESSAGE_SUCCESS: 'UPDATE_SETTLEMENT_MESSAGE_SUCCESS',
  UPDATE_SETTLEMENT_MESSAGE_FAILURE: 'UPDATE_SETTLEMENT_MESSAGE_FAILURE',

  UPDATING_NIFTY_RETURNS: 'UPDATING_NIFTY_RETURNS',
  UPDATE_NIFTY_RETURNS_SUCCESS: 'UPDATE_NIFTY_RETURNS_SUCCESS',
  UPDATE_NIFTY_RETURNS_FAILURE: 'UPDATE_NIFTY_RETURNS_FAILURE',

  UPDATING_FD_RETURNS: 'UPDATING_FD_RETURNS',
  UPDATE_FD_RETURNS_SUCCESS: 'UPDATE_FD_RETURNS_SUCCESS',
  UPDATE_FD_RETURNS_FAILURE: 'UPDATE_FD_RETURNS_FAILURE',

  GETTING_FUND_BENCHMARK_RETURNS: 'GETTING_FUND_BENCHMARK_RETURNS',
  GET_FUND_BENCHMARK_RETURNS_SUCCESS: 'GET_FUND_BENCHMARK_RETURNS_SUCCESS',
  GET_FUND_BENCHMARK_RETURNS_FAILURE: 'GET_FUND_BENCHMARK_RETURNS_FAILURE',
  CLEAR_FUND_BENCHMARK_RETURNS: 'CLEAR_FUND_BENCHMARK_RETURNS',

  GETTING_CONFIG_LIST: 'GETTING_CONFIG_LIST',
  GET_CONFIG_LIST_SUCCESS: 'GET_CONFIG_LIST_SUCCESS',
  GET_CONFIG_LIST_ERROR: 'GET_CONFIG_LIST_ERROR',
  UPDATING_CONFIG_LIST_ITEM: 'UPDATING_CONFIG_LIST_ITEM',
  UPDATE_CONFIG_LIST_ITEM_SUCCESS: 'UPDATE_CONFIG_LIST_ITEM_SUCCESS',
  UPDATE_CONFIG_LIST_ITEM_FAILURE: 'UPDATE_CONFIG_LIST_ITEM_FAILURE',

  GETTING_FULFILLMENT_SETTLEMENT_LIST: 'GETTING_FULFILLMENT_SETTLEMENT_LIST',
  GET_FULFILLMENT_SETTLEMENT_LIST_SUCCESS: 'GET_FULFILLMENT_SETTLEMENT_LIST_SUCCESS',
  GET_FULFILLMENT_SETTLEMENT_LIST_ERROR: 'GET_FULFILLMENT_SETTLEMENT_LIST_ERROR',

  UPDATING_FULFILLMENT_SETTLEMENT_LIST_ITEM: 'UPDATING_FULFILLMENT_SETTLEMENT_LIST_ITEM',
  UPDATE_FULFILLMENT_SETTLEMENT_LIST_ITEM_SUCCESS: 'UPDATE_FULFILLMENT_SETTLEMENTS_LIST_ITEM_SUCCESS',
  UPDATE_FULFILLMENT_SETTLEMENT_LIST_ITEM_ERROR: 'UPDATE_FULFILLMENT_SETTLEMENT_LIST_ITEM_ERROR',
};

export default CONFIG;
