export const PAYOUT_SCREEN_ACTIONS = {
  //GET PAYOUT PARTNERS
  GETTING_PAYOUT_PARTNERS: 'GETTING_PAYOUT_PARTNERS',
  GETTING_PAYOUT_PARTNERS_SUCCESS: 'GETTING_PAYOUT_PARTNERS_SUCCESS',
  GETTING_PAYOUT_PARTNERS_FAIL: 'GETTING_PAYOUT_PARTNERS_FAIL',
  // UPDATE PAYPUT PARTERS
  UPDATING_PAYOUT_PARTNERS: 'UPDATING_PAYOUT_PARTNERS',
  UPDATING_PAYOUT_PARTNERS_SUCCESS: 'UPDATING_PAYOUT_PARTNERS_SUCCESS',
  UPDATING_PAYOUT_PARTNERS_FAIL: 'UPDATING_PAYOUT_PARTNERS_FAIL'
};

export const payoutPartners = {
  SABER: 'SABER',
  WHITELIST: 'WHITELIST',
  XETTLE: 'XETTLE'
};
