import React, { useCallback, useEffect, useState } from 'react';
import { HStack, Spinner, VStack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTransactions,
  getFalconTransactionDetails,
  getFalconTransactionFilterAndSearchComponents,
  updateFalconTransactionDashboardRequestBody
} from '../../store/actions/falconDashboardActions';
import FalconTransactionCard from '../../components/FalconTransactionCard/FalconTransactionCard';
import FilterSelect from '../../components/FiltersBox/FilterSelect';
import SearchBox from '../../components/SearchBox/SearchBox';
import Pagination from '../../components/Pagination';
import useDebounce from '../../hooks/useDebounce';
import FunnelBar from '../Funnel/FunnelBar';
import useDidMountEffect from '../../hooks/useDidMount';

export const TransactionDashboard = ({
  selectedTransaction,
  setSelectedTransaction
}) => {
  const dispatch = useDispatch();
  const {
    data: transactions,
    isLoading,
    requestBody,
    totalPages,
    transactionDetails,
    searches,
    filters,
    componentsLoading,
    configs,
    statusTransactionMapping
  } = useSelector((state) => state.falconDashboard.transactionDashboard);

  const {
    transaction,
    recipient,
    payout,
    isLoading: isDetailsLoading
  } = transactionDetails;

  const statusCountObject = {
    COMPLETED: '123',
    CREATED: '321'
  };

  // State Management
  const [searchParams, setSearchParams] = useState({});
  const [filterParams, setFilterParams] = useState({});
  const [activeStatusFunnel, setActiveStatusFunnel] = useState();
  const debouncedSearchParams = useDebounce(searchParams, 500);
  const debouncedFilterParams = useDebounce(filterParams, 500);

  // Fetch Transactions
  const fetchTransactions = () => {
    const combinedRequestBody = {
      ...requestBody,
      ...debouncedSearchParams,
      filters: { ...debouncedFilterParams }
    };
    dispatch(
      getTransactions({
        requestBody: combinedRequestBody
      })
    );
  };

  // Update Selected Transaction Details
  useEffect(() => {
    if (isDetailsLoading) {
      setSelectedTransaction({
        isLoading: isDetailsLoading
      });
    } else if (transaction) {
      setSelectedTransaction({
        transaction,
        recipient,
        payout,
        isDetailsLoading
      });
    }
  }, [transaction, recipient, payout, isDetailsLoading]);

  // Fetch Filters and Searches
  useEffect(() => {
    dispatch(getFalconTransactionFilterAndSearchComponents());
  }, [dispatch]);

  // Fetch Transactions when Filters or Searches Update
  useDidMountEffect(() => {
    fetchTransactions();
  }, [
    debouncedSearchParams,
    debouncedFilterParams,
    requestBody.pageNumber
  ]);


  // Handle Search Change
  const handleSearchChange = useCallback((search) => {
    setSearchParams((prev) => ({
      ...prev,
      searchKey: search.searchKey,
      searchValue: search.searchValue,
    }));
    dispatch(
      updateFalconTransactionDashboardRequestBody({
        ...requestBody,
        searchKey: search.searchKey,
        searchValue: search.searchValue,
        pageNumber: 0,
      })
    );
  }, [dispatch, requestBody, searchParams]);

  // Handle Filter Change
  const handleFilterChange = ({ key, filters }) => {
    setFilterParams((prev) => {
      const updatedParams = { ...prev };
      if (filters.length === 0) {
        delete updatedParams[key];
      } else {
        updatedParams[key] = filters;
      }
      return updatedParams;
    });
    dispatch(
      updateFalconTransactionDashboardRequestBody({
        ...requestBody,
        filters: {
          ...requestBody.filters,
          [key]: filters
        },
        pageNumber: 0
      })
    );
  };

  // Handle Page Change
  const handlePageChange = (page) => {
    const newPageNumber = Math.max(0, page - 1);
    dispatch(
      updateFalconTransactionDashboardRequestBody({
        ...requestBody,
        pageNumber: newPageNumber
      })
    );
  };

  // Handle Transaction Selection
  const handleSelectTransaction = (details) => {
    dispatch(
      getFalconTransactionDetails({
        selectedTransaction: details,
        transactionId: details.transactionId,
        recipientId: details.recipientId
      })
    );
    setSelectedTransaction({});
  };

  return (
    <VStack alignItems={'stretch'} width={'full'} py={2}>
      <HStack width={'100%'}>
        {!componentsLoading ? (
          Array.isArray(searches) && (
            <VStack w={'full'}>
              <FunnelBar
                setActiveFunnel={() => {}}
                activeFunnel={activeStatusFunnel}
                funnelData={statusTransactionMapping}
                isListLoading={isLoading}
              />
              <SearchBox
                searches={searches}
                handleSearchChange={handleSearchChange}
              />
              <FilterSelect
                handleFilterSubmit={handleFilterChange}
                filtersData={filters}
              />
              <Pagination
                currentPage={requestBody.pageNumber + 1}
                onPageChange={handlePageChange}
                totalPages={totalPages}
              />
            </VStack>
          )
        ) : (
          <Spinner align="center" color="brand.400" size="xl" thickness="4px" />
        )}
      </HStack>
      {isLoading ? (
        <Spinner align="center" color="brand.400" size="xl" thickness="4px" />
      ) : (
        <VStack overflow={'auto'} height={'100vh'}>
          {transactions.length > 0 &&
            transactions.map((details, index) => (
              <>
                <FalconTransactionCard
                  key={index}
                  falconTransaction={details}
                  onOrderDetailsClick={() => {
                    handleSelectTransaction(details);
                  }}
                  configs={configs}
                  selected={selectedTransaction?.transaction === details}
                />
              </>
            ))}
        </VStack>
      )}
    </VStack>
  );
};
