import { HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';

export const EntityCard = ({ entity, onClick, selected }) => {
  return (
    <VStack
      bg="backGround"
      w={'full'}
      borderRadius="12px"
      py={4}
      px={4}
      gap={2}
      cursor={'pointer'}
      onClick={onClick}
      borderLeft={selected &&'2px'}
      borderColor={'brand.400'}
    >
      <HStack justify="space-between" w={'full'}>
        {entity &&
          Object.keys(entity).map(
            (obj, id) =>
              entity[obj]?.text && (
                <VStack key={id}>
                  <Text fontSize="sm" color="gray.400">
                    {entity[obj].text}
                  </Text>
                  <Text fontSize="md" color="white">
                    {entity[obj].value}
                  </Text>
                </VStack>
              )
          )}
        <VStack>
          <HStack>
            <Text fontSize="sm" color="gray.400">
              Created At:
            </Text>
            <Text fontSize="md" color="white">
              {entity.createdAt}
            </Text>
          </HStack>
          <HStack>
            <Text fontSize="sm" color="gray.400">
              Updated At:
            </Text>
            <Text fontSize="md" color="white">
              {entity.updatedAt}
            </Text>
          </HStack>
        </VStack>
      </HStack>
    </VStack>
  );
};
