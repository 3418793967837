export default function userApi(fetch, baseUrl) {
  return {
    count() {
      return fetch(`${baseUrl}/count`);
    },

    byId(userId) {
      return fetch(`${baseUrl}/${userId}`);
    },
    getFreeTransfers(userId) {
      return fetch.get(`${baseUrl}/free-transfers/${userId}`);
    },
    addFreeTransfers(reqBody) {
      return fetch.post(`${baseUrl}/free-transfers`, reqBody);
    },

    kycFunnelUsers({ status, pageNo = 0, pageSize = 10 }) {
      return fetch(
        `${baseUrl}?page_size=${pageSize}&page=${pageNo}${
          status ? `&kyc_status=${status}` : ''
        }`
      );
    },

    updateAddress(userId, address) {
      return fetch.put(`${baseUrl}/${userId}/address`, address);
    },

    rejectKyc(userId, formData) {
      return fetch.post(`${baseUrl}/${userId}/reject-kyc`, formData);
    },

    referralCode(userId) {
      return fetch.get(`${baseUrl}/${userId}/referral-code`);
    },

    getReferrals({ userId, pageNo = 0, pageSize = 10 }) {
      return fetch.post(
        `${baseUrl}/${userId}/referrals?page_size=${pageSize}&page=${pageNo}`
      );
    },

    getGoldenTicketInfo(userId) {
      return fetch.get(`${baseUrl}/${userId}/golden-ticket/info`);
    },

    deleteUser(userId) {
      return fetch.post(`${baseUrl}/${userId}/delete`);
    },
    updateVdaEligibilityStatus({ userId, status }) {
      return fetch.patch(`${baseUrl}/${userId}/update-vda-status`, {
        vdaStatus: status
      });
    },
    updateUserKycDetailsList({ userId, details }) {
      return fetch.put(`${baseUrl}/${userId}/kyc/details/list`, details);
    },
   
  };
}
