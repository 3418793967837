import api from '../../api/index';
import { setAlert } from './alertActions';
export const updateUserEmail =
  ({ userId, email, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.cxTechOperations.updateUserEmail({
        userId,
        email
      });

      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };
export const updateUserPhoneNumber =
  ({ userId, phoneNumber, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.cxTechOperations.updateUserPhone({
        userId,
        phoneNumber
      });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: 'phone number cannot be updated',
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };
