import React, { useEffect, useState } from 'react';
import {
  Button,
  HStack,
  Select,
  Spinner,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getClientFilters,
  getClients,
  getVendorFilters,
  getVendors
} from '../../store/actions/falconDashboardActions';
import { FALCON_DASHBOARD_CONSTANTS as types } from '../../store/constants';
import { EntityCard } from '../../components/FalconServiceDashboard/EntityCard';
import Pagination from '../../components/Pagination';
import {
  updateFalconClientReqData,
  updateFalconVendorReqData
} from '../../store/actions/reqDataActions';
import InputComponent from '../../components/Input';
import { camelCaseToSpaceSeparated, toSentenceCase } from '../../utils';
import ModalLayout from '../../components/Modal/ModalLayout';
import CreateClientModal from '../../components/FalconServiceDashboard/CreateClientModal';
import useDebounce from '../../hooks/useDebounce';
import { vendorEntityConfig } from './utils';
import SearchBox from '../../components/SearchBox/SearchBox';
import { ROLES } from '../../constants';
import useAuthRole from '../../hooks/useAuthRole';
export const VendorDashboard = ({ setSelectedVendor, selectedVendor }) => {
  const { hasAnyAuthority } = useAuthRole();
  const dispatch = useDispatch();
  const vendors = useSelector((state) => state.falconDashboard.vendors);
  const reqData = useSelector((state) => state.reqData.falconDashboard.vendor);
  const [filterValue, setFilterValue] = useState({});
  const [searchValue, setSearchValue] = useState({
    searchValue: null,
    searchKey: null
  });
  const debouncedValue = useDebounce(searchValue.searchValue, 500);
  const [selectedFilter, setSelectedFilter] = useState();

  const {
    isOpen: isCreateModalOpen,
    onClose: closeCreateModal,
    onOpen: openCreateModal
  } = useDisclosure();

  useEffect(() => {
    dispatch(
      getVendorFilters({
        onSuccess: (data) => {
          dispatch(
            updateFalconVendorReqData({
              searches: data.search,
              filterValues: data.filters
            })
          );
        }
      })
    );
  }, []);

  useEffect(() => {
    if (debouncedValue != null)
      dispatch(
        updateFalconVendorReqData({
          ...searchValue
        })
      );
  }, [debouncedValue]);

  useEffect(() => {
    if (reqData.searchKey)
      dispatch(
        getVendors({ ...reqData, filters: filterValue }, (data) => {
          dispatch({
            type: types.GET_FALCON_VENDORS_SUCCESS,
            payload: { data }
          });
          dispatch(
            updateFalconVendorReqData({
              totalPages: data.totalPages,
              pageNumber: data.page,
              pageSize: data.pageSize
            })
          );
        })
      );
  }, [reqData.searchValue]);

  useEffect(() => {
    if (reqData.pageNumber != null)
      dispatch(
        getVendors({ ...reqData, filters: filterValue }, (data) => {
          dispatch({
            type: types.GET_FALCON_VENDORS_SUCCESS,
            payload: { data }
          });
          dispatch(
            updateFalconVendorReqData({
              totalPages: data.totalPages,
              pageSize: data.pageSize
            })
          );
        })
      );
  }, [reqData.pageNumber]);

  const handlePageChange = (page) => {
    dispatch(
      updateFalconVendorReqData({
        pageNumber: page - 1
      })
    );
  };

  const handleSearchChange = (search) => {
    setSearchValue(search);
    dispatch(
      updateFalconVendorReqData({
        pageNumber: 0
      })
    );
  };

  return (
    <VStack
      alignItems={'stretch'}
      width={'full'}
      py={2}
      h={'70vh'}
      overflow={'scroll'}
    >
      <ModalLayout
        isOpen={isCreateModalOpen}
        onClose={closeCreateModal}
        size="xl"
        scrollBehavior="inside"
      >
        <CreateClientModal
          onClose={closeCreateModal}
          config={vendorEntityConfig}
        />
      </ModalLayout>
      <VStack gap={2}>
        <HStack justify={'flex-end'} w={'full'}>
          {hasAnyAuthority(
            ROLES.ADMIN_ROLE,
            ROLES.FALCON_ADMIN,
            ROLES.FALCON_OPS_MANAGER
          ) && (
            <Button colorScheme="brand" color="black" onClick={openCreateModal}>
              Create Vendor
            </Button>
          )}
        </HStack>
        {!reqData.isLoading && !reqData.isError > 0 && (
          <>
            {reqData.searches && (
              <SearchBox
                searches={reqData.searches}
                handleSearchChange={handleSearchChange}
              />
            )}
            <Pagination
              onPageChange={handlePageChange}
              currentPage={reqData.pageNumber + 1}
              totalPages={reqData.totalPages}
            />
          </>
        )}
        {vendors.isLoading ? (
          <Spinner color="brand.400" size="xl" thickness="4px" />
        ) : vendors.data?.length > 0 ? (
          vendors.data.map((vendor, index) => (
            <EntityCard
              entity={vendor}
              key={index}
              onClick={() => setSelectedVendor(vendor)}
              selected={vendor===selectedVendor}
            />
          ))
        ) : (
          <>No vendor to show</>
        )}
      </VStack>
    </VStack>
  );
};
