import {
  Grid,
  Heading,
  Table,
  Tbody,
  Td,
  Tr,
  VStack,
  Text,
  Thead,
  Th,
  Spinner,
  useDisclosure,
  HStack,
  Button,
  Box,
  Tabs,
  Tab,
  TabList
} from '@chakra-ui/react';
import { kebabCaseToSpaceSeperate } from '../../utils';
import ContentLayout from '../../layouts/ContentLayout';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getOrderTransferChecks } from '../../store/actions/ordersActions';
import { tableCellStyles } from '../../components/NrOnboard/constants';
import ModalLayout from '../../components/Modal/ModalLayout';
import BeneficiaryChecksModal from '../../components/OrderTransferChecks/BeneficiaryChecksModal';
import { updateBeneficiaryRisks } from '../../store/actions/thunesActions';
import { useDispatch, useSelector } from 'react-redux';
import FiltersAndSort from './FiltersAndSort';
import PayoutDashboard from './PayoutDashboard';
const VDATransferRoutingSystemDashboard = () => {
  const [filtersBody, setFiltersBody] = useState({
    status: '',
    priority: '',
    volumeRange: '',
    balanceStatus: ''
  });
  const onChange = (event) => {
    const { name, value } = event.target;
    setFiltersBody((prev) => ({
      ...prev,
      [name]: value
    }));
  };
  const TAB_STATES = {
    PAYOUT_DASHBOARD: 'PAYOUT_DASHBOARD',
    PARTNER_MAPPING: 'PARTNER_MAPPING',
    LIQUDITY_RATE_AND_TRADE: 'LIQUDITY_RATE_AND_TRADE'
  };
  const [selectedTab, setSelectedTab] = useState(TAB_STATES.PAYOUT_DASHBOARD);
  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '4fr 2fr' }}>
        <VStack>
          <Box width="100%">
            <Heading>VDA Transfer Routing System Dashboard</Heading>
            <Text>Manager partners, mappings and liquidity settings</Text>
            <Tabs
              // variant={'enclosed'}
              size={'md'}
              onChange={(e) => {
                setSelectedTab(Object.keys(TAB_STATES)[e]);
              }}
              defaultIndex={Object.keys(TAB_STATES).indexOf(selectedTab)}
            >
              <TabList color={'white'}>
                {Object.keys(TAB_STATES).map((tabState, index) => (
                  <Tab
                    key={index}
                    justifyContent="space-around"
                    _selected={{
                      color: 'brand.500',
                      border: '1px solid white',
                      borderBottom: '1px solid black'
                    }}
                  >
                    {kebabCaseToSpaceSeperate(tabState)}
                  </Tab>
                ))}
              </TabList>
            </Tabs>
          </Box>
          <Box width="100%">
            <FiltersAndSort filtersBody={filtersBody} onChange={onChange} />
          </Box>
          {selectedTab === TAB_STATES.PAYOUT_DASHBOARD && <PayoutDashboard />}
          {selectedTab === TAB_STATES.PARTNER_MAPPING && (
            <Text color="white">work in progress</Text>
          )}
          {selectedTab === TAB_STATES.LIQUDITY_RATE_AND_TRADE && (
            <Text color="white">work in progress</Text>
          )}
        </VStack>
      </Grid>
    </ContentLayout>
  );
};
export default VDATransferRoutingSystemDashboard;
