import { Button, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import CopyableText from '../CopyableText';
import { getRFIStatusColor } from '../../utils';

export const RFITransactionCard = ({ trx, onClick, selected }) => {
  return (
    <VStack
      bg="backGround"
      w={'full'}
      borderRadius="12px"
      py={4}
      px={4}
      gap={2}
      borderLeft={selected &&'2px'}
      borderColor={'brand.400'}
    >
      <HStack justify={'space-between'} w={'full'}>
        <VStack align={'flex-start'} h={'full'}>
          <HStack gap={1}>
            <Text fontSize="sm" color="gray.400">
              Transaction ID:
            </Text>
            <CopyableText title="Transaction ID" fontSize="md" color="white">
              {trx.transactionId}
            </CopyableText>
          </HStack>
          <HStack gap={1}>
            <Text fontSize="sm" color="gray.400">
              Payout ID:
            </Text>
            <CopyableText title="Payout ID" fontSize="md" color="white">
              {trx.payoutId}
            </CopyableText>
          </HStack>
        </VStack>
        <VStack align={'flex-start'}>
          <HStack gap={1}>
            <Text fontSize="sm" color="gray.400">
              Partner Name:
            </Text>
            <Text fontSize="md" color="white">
              {trx.partnerName}
            </Text>
          </HStack>
          <HStack gap={1}>
            <Text fontSize="sm" color="gray.400">
              Status:
            </Text>
            <HStack>
              <Icon
                viewBox="0 0 200 200"
                color={getRFIStatusColor(trx.status)}
                w="8px"
              >
                <path
                  fill="currentColor"
                  d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                />
              </Icon>
              <Text fontSize={{ base: 'sm', sm: 'md' }} color="white">
                {trx.status}
              </Text>
            </HStack>
          </HStack>
            <Button
              alignSelf={'flex-end'}
              size="sm"
              rounded="md"
              color="black"
              colorScheme="brand"
              onClick={onClick}
            >
              View Details
            </Button>
        </VStack>
      </HStack>
    </VStack>
  );
};
