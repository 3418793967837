import {
  Box,
  Table,
  Tbody,
  Td,
  Tr,
  Text,
  Thead,
  Th,
  Button,
  Heading
} from '@chakra-ui/react';

const dummyPayoutPartners = [
  {
    name: 'Partner A',
    priority: 'High',
    minAmount: 100,
    maxAmount: 10000,
    totalVolume: 50000,
    currentBalance: 20000,
    minBalance: 5000,
    status: 'Active',
    reserveAmount: 3000,
    lockBalance: 2000,
    minPendingOrders: 2,
    maxPendingOrders: 10
  },
  {
    name: 'Partner B',
    priority: 'Medium',
    minAmount: 200,
    maxAmount: 20000,
    totalVolume: 80000,
    currentBalance: 40000,
    minBalance: 10000,
    status: 'Inactive',
    reserveAmount: 5000,
    lockBalance: 3000,
    minPendingOrders: 3,
    maxPendingOrders: 15
  },
  {
    name: 'Partner C',
    priority: 'Low',
    minAmount: 50,
    maxAmount: 5000,
    totalVolume: 20000,
    currentBalance: 10000,
    minBalance: 2000,
    status: 'Active',
    reserveAmount: 1000,
    lockBalance: 500,
    minPendingOrders: 1,
    maxPendingOrders: 5
  },
  {
    name: 'Partner D',
    priority: 'High',
    minAmount: 500,
    maxAmount: 50000,
    totalVolume: 150000,
    currentBalance: 75000,
    minBalance: 25000,
    status: 'Active',
    reserveAmount: 10000,
    lockBalance: 7000,
    minPendingOrders: 5,
    maxPendingOrders: 25
  }
];

const PayoutDashboard = () => {
  return (
    <Box p={6} bg="gray.900" minH="100vh">
      <Heading mb={6} color="white" fontSize="2xl">
        Payout Dashboard
      </Heading>
      <Table
        variant="simple"
        colorScheme="gray"
        size="md"
        bg="gray.800"
        borderRadius="md"
        overflow="hidden"
      >
        <Thead bg="gray.700">
          <Tr>
            <Th color="gray.300">Name</Th>
            <Th color="gray.300">Priority</Th>
            <Th color="gray.300">Min Amount</Th>
            <Th color="gray.300">Max Amount</Th>
            <Th color="gray.300">Total Volume</Th>
            <Th color="gray.300">Current Balance</Th>
            <Th color="gray.300">Min Balance</Th>
            <Th color="gray.300">Status</Th>
            <Th color="gray.300">Reserve Amount</Th>
            <Th color="gray.300">Lock Balance</Th>
            <Th color="gray.300">Min Pending Orders</Th>
            <Th color="gray.300">Max Pending Orders</Th>
          </Tr>
        </Thead>
        <Tbody>
          {dummyPayoutPartners.map((partner, index) => (
            <Tr key={index} _hover={{ bg: 'gray.700' }}>
              <Td>
                <Text color="white">{partner.name}</Text>
              </Td>
              <Td>
                <Text color="white">{partner.priority}</Text>
              </Td>
              <Td>
                <Text color="white">{partner.minAmount}</Text>
              </Td>
              <Td>
                <Text color="white">{partner.maxAmount}</Text>
              </Td>
              <Td>
                <Text color="white">{partner.totalVolume}</Text>
              </Td>
              <Td>
                <Text color="white">{partner.currentBalance}</Text>
              </Td>
              <Td>
                <Text color="white">{partner.minBalance}</Text>
              </Td>
              <Td>
                <Text color="white">{partner.status}</Text>
              </Td>
              <Td>
                <Text color="white">{partner.reserveAmount}</Text>
              </Td>
              <Td>
                <Text color="white">{partner.lockBalance}</Text>
              </Td>
              <Td>
                <Text color="white">{partner.minPendingOrders}</Text>
              </Td>
              <Td>
                <Text color="white">{partner.maxPendingOrders}</Text>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default PayoutDashboard;
