import React from 'react';
import CopyableText from '../CopyableText';
import { getUserKycStatusColor } from '../../utils';
import { Box, VStack, Text, HStack, Icon } from '@chakra-ui/react';

const UserKycStatusCard = ({ item, isMobile, onLinkClick }) => (
  <VStack
    cursor={'pointer'}
    w={{ base: '100%', md: '100%' }}
    maxW={'800px'}
    bg="backGround"
    border={'1px solid'}
    borderRadius="12px"
    borderColor="whiteAlpha.300"
    padding={'12px'}
    onClick={(event) => onLinkClick(event, item.userId)}
  >
    <Box fontSize="sm" w={'100%'} overflowWrap={'break-word'}>
      <Text color={'whiteAlpha.600'}>User Id:</Text>
      <CopyableText
        w={isMobile ? 'initial' : '100%'}
        minW={isMobile ? 'none' : '160px'}
        wordBreak="break-all"
        whiteSpace={'normal'}
        title={'UserId'}
      >
        {item?.userId}
      </CopyableText>
    </Box>
    <HStack fontSize="sm" w={'100%'} overflowWrap={'break-word'}>
      <Text color={'whiteAlpha.600'}>Status:</Text>
      <Icon
        viewBox="0 0 200 200"
        color={getUserKycStatusColor(item?.kycStatus)}
        w="10px"
      >
        <path
          fill="currentColor"
          d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
        />
      </Icon>
      <Text>{item?.kycStatus}</Text>
    </HStack>
  </VStack>
);

export default UserKycStatusCard;
