import { useCallback, useState } from 'react';
import { IDLE, LOADING, REJECTED, RESOLVED } from '../constants';

export default function usePromise() {
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [status, setStatus] = useState(IDLE);
  const run = useCallback((promise) => {
    setError(null);
    setData(null);
    setStatus(LOADING);
    promise
      .then((res) => {
        setData(res);
        setStatus(RESOLVED);
      })
      .catch((err) => {
        setError(err);
        setStatus(REJECTED);
      });
  }, []);

  return {
    isLoading: status === LOADING,
    isError: status === REJECTED,
    isSuccess: status === RESOLVED,

    run,
    data,
    error,
    status
  };
}
