import React, { useEffect, useState } from 'react';
import { Divider, Spinner, VStack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRFIFilters,
  getRfiTransactions
} from '../../store/actions/falconDashboardActions';
import { FALCON_DASHBOARD_CONSTANTS as types } from '../../store/constants';
import Pagination from '../../components/Pagination';
import { updateFalconRFITransactionsReqData } from '../../store/actions/reqDataActions';
import useDebounce from '../../hooks/useDebounce';
import { RFITransactionCard } from '../../components/FalconServiceDashboard/RfiTransactionCard';
import SearchBox from '../../components/SearchBox/SearchBox';
import FilterSelect from '../../components/FiltersBox/FilterSelect';

export const RFITransactionDashboard = ({ setSelectedRfiTrxs, selectedTrx }) => {
  const dispatch = useDispatch();
  const trxs = useSelector((state) => state.falconDashboard.rfiTransactions);
  const reqData = useSelector(
    (state) => state.reqData.falconDashboard.rfiTransactions
  );
  const [searchValue, setSearchValue] = useState({
    searchValue: null,
    searchKey: null
  });
  const debouncedValue = useDebounce(searchValue.searchValue, 500);

  useEffect(() => {
    dispatch(
      getRFIFilters({
        onSuccess: (data) => {
          dispatch(
            updateFalconRFITransactionsReqData({
              searches: data.search,
              filterValues: data.filters,
              filters: null
            })
          );
        }
      })
    );
  }, []);

  useEffect(() => {
    if (debouncedValue != null)
      dispatch(
        updateFalconRFITransactionsReqData({
          ...searchValue
        })
      );
  }, [debouncedValue]);

  useEffect(() => {
    if (reqData.searchKey)
      dispatch(
        getRfiTransactions({ ...reqData }, (data) => {
          dispatch({
            type: types.GET_RFI_TRANSACTIONS_SUCCESS,
            payload: { data }
          });
          dispatch(
            updateFalconRFITransactionsReqData({
              totalPages: data.totalPages,
              pageNumber: data.page,
              pageSize: data.pageSize
            })
          );
        })
      );
  }, [reqData.searchValue]);

  useEffect(() => {
    if (reqData.pageNumber != null)
      dispatch(
        getRfiTransactions({ ...reqData }, (data) => {
          dispatch({
            type: types.GET_RFI_TRANSACTIONS_SUCCESS,
            payload: { data }
          });
          dispatch(
            updateFalconRFITransactionsReqData({
              totalPages: data.totalPages,
              pageSize: data.pageSize
            })
          );
        })
      );
  }, [reqData.pageNumber]);

  useEffect(() => {
    dispatch(
      getRfiTransactions({ ...reqData }, (data) => {
        dispatch({
          type: types.GET_RFI_TRANSACTIONS_SUCCESS,
          payload: { data }
        });
        dispatch(
          updateFalconRFITransactionsReqData({
            totalPages: data.totalPages,
            pageSize: data.pageSize
          })
        );
      })
    );
  }, [reqData.filters]);

  const handlePageChange = (page) => {
    dispatch(
      updateFalconRFITransactionsReqData({
        pageNumber: page - 1
      })
    );
  };

  const handleFilterChange = ({ key, filters }) => {
    const updatedFilters = { ...reqData.filters };
    if (filters.length === 0) {
      delete updatedFilters[key];
    } else {
      updatedFilters[key] = filters;
    }
    dispatch(
      updateFalconRFITransactionsReqData({
        filters: updatedFilters
      })
    );
  };

  const handleSearchChange = (search) => {
    setSearchValue(search);
  };

  return (
    <VStack alignItems={'stretch'} width={'full'} py={2}>
      <VStack gap={2}>
        {!reqData.isLoading && !reqData.isError > 0 && (
          <>
            {reqData.searches && (
              <SearchBox
                searches={reqData.searches}
                handleSearchChange={handleSearchChange}
              />
            )}
            {reqData.filterValues && (
              <FilterSelect
                filtersData={reqData.filterValues}
                handleFilterSubmit={handleFilterChange}
              />
            )}
            <Pagination
              onPageChange={handlePageChange}
              currentPage={reqData.pageNumber + 1}
              totalPages={reqData.totalPages}
            />
          </>
        )}
        <Divider borderColor="whiteAlpha.300" />
        <VStack>
          {trxs.isLoading ? (
            <Spinner color="brand.400" size="xl" thickness="4px" />
          ) : trxs.data?.length > 0 ? (
            trxs.data.map((trx, index) => (
              <RFITransactionCard
                trx={trx}
                key={index}
                onClick={() => setSelectedRfiTrxs(trx)}
                selected={trx===selectedTrx}
              />
            ))
          ) : (
            <>No Transactions in RFI status to show</>
          )}
        </VStack>
      </VStack>
    </VStack>
  );
};
