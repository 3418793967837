import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Heading,
    HStack,
    IconButton,
    Spinner,
    Text,
    VStack,
    useDisclosure,
} from '@chakra-ui/react';
import { AiFillEdit } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import ModalLayout from "../../Modal/ModalLayout";
import UpdateTransferDelayConfig from "../../Modal/UpdateTransferDelayConfigModal";
import { TRANSFER_DELAY_TABLE_CONFIG } from "./constants";
import { kebabCaseToSpaceSeperate } from "../../../utils";
import {
    getFulfillmentSettlementList,
    updateFulfillmentSettlementItem,
} from "../../../store/actions/configActions";
import { ROLES } from '../../../constants';
import useAuthRole from '../../../hooks/useAuthRole';


const TransferDelayConfig = () => {
    const { hasAuthority } = useAuthRole();
    const dispatch = useDispatch();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { data, isLoading, error } = useSelector(
      (state) => state.config.fulfillmentSettlementList
    );

    const [modalData, setModalData] = useState(null);
    const [isUpdatingFulfillmentSettlementItem, setIsUpdatingFulfillmentSettlementItem] = useState(false);
    const [fulfillmentSettlementList, setFulfillmentSettlementList] = useState(null);

    const transformFulfillmentData = (data) => {
        return data.map((item) => ({
            ...item,
            averageTime: parseFloat((item.averageTime / 60).toFixed(2)),
            currentTime: parseFloat((item.currentTime / 60).toFixed(2)),
            warningThreshold: parseFloat((item.warningThreshold / 60).toFixed(2)),
            alertThreshold: parseFloat((item.alertThreshold / 60).toFixed(2)),
        }));
    };

    const triggerGetFulfillmentSettlementList = useCallback(() => {
        dispatch(
            getFulfillmentSettlementList({
                onSuccess: (data) => data?.fulfillmentSettlementData,
            })
        );
    }, [dispatch]);

    useEffect(() => {
        triggerGetFulfillmentSettlementList();
    }, []);

    useEffect(() => {
        if(data){
            const transformedData = transformFulfillmentData(data)
            setFulfillmentSettlementList(transformedData)
        }
    }, [data]);

    const onUpdateFulfillmentSettlementItem = (data) => {
        setModalData(data);
        onOpen();
    };

    const onUpdateFulfillmentSettlementItemModalClose = () => {
        setModalData(null);
        onClose();
    };

    const handleSubmit = () => {
        setIsUpdatingFulfillmentSettlementItem(true);
        dispatch(
          updateFulfillmentSettlementItem({
              payload: {
                  ...modalData,
                  averageTime: Math.round(modalData.averageTime * 60),
                  currentTime: Math.round(modalData.currentTime * 60),
                  warningThreshold: Math.round(modalData.warningThreshold * 60),
                  alertThreshold: Math.round(modalData.alertThreshold * 60),
              },
              onSuccess: (data) => data?.fulfillmentSettlementData,
              onComplete: () => {
                  setIsUpdatingFulfillmentSettlementItem(false);
                  onUpdateFulfillmentSettlementItemModalClose();
              }
          })
        );
    };

    return (
      <Box position={'relative'}>
          {isLoading ? (
            <Spinner size={'lg'} color={'brand.200'} />
          ) : !error && (
            <>
                <ModalLayout
                  isOpen={isOpen}
                  onClose={onUpdateFulfillmentSettlementItemModalClose}
                  size={'md'}
                >
                    <UpdateTransferDelayConfig
                      modalData={modalData}
                      setModalData={setModalData}
                      onClose={onClose}
                      handleSubmit={handleSubmit}
                      isLoading={isUpdatingFulfillmentSettlementItem}
                    />
                </ModalLayout>
                <Heading fontSize={'1.3rem'} color={'white'} marginY={'0.75rem'}>
                    Transfer Delay Message Configuration
                </Heading>
                <VStack
                  p={1}
                  gap={1}
                  sx={{
                      marginTop: "0px !important",
                      border: "1px solid gray",
                      borderRadius: "xl",
                  }}
                >
                    <Grid
                      w={'100%'}
                      justifyContent={'flex-start'}
                      height={'100%'}
                      alignItems={'center'}
                      templateColumns={`repeat(${hasAuthority(ROLES.CONFIG_MANAGER_ROLE) ? TRANSFER_DELAY_TABLE_CONFIG.length + 1 : TRANSFER_DELAY_TABLE_CONFIG.length}, 1fr)`}
                      gap="4"
                      paddingY={2}
                      textAlign={'center'}
                      sx={{
                          marginTop: "0px !important",
                          border: "1px solid gray",
                          borderRadius: "xl",
                      }}
                    >
                        {TRANSFER_DELAY_TABLE_CONFIG.map((item, index) => (
                          <Text key={index} color={'gray'} fontWeight={"semibold"}>
                              {kebabCaseToSpaceSeperate(item.label)}
                              {item?.caption && item.caption}
                          </Text>
                        ))}
                        {hasAuthority(ROLES.CONFIG_MANAGER_ROLE) && (
                          <Text color={'gray'} fontWeight={"semibold"}>Update</Text>
                        )}
                    </Grid>
                    {fulfillmentSettlementList && fulfillmentSettlementList.length > 0 &&
                      fulfillmentSettlementList.map((item, index) => (
                        <Grid
                          key={index}
                          w={'100%'}
                          justifyContent={'flex-start'}
                          height={'100%'}
                          alignItems={'center'}
                          templateColumns={`repeat(${hasAuthority(ROLES.CONFIG_MANAGER_ROLE) ? TRANSFER_DELAY_TABLE_CONFIG.length + 1 : TRANSFER_DELAY_TABLE_CONFIG.length}, 1fr)`}
                          gap={4}
                          paddingY={2}
                          textAlign={'center'}
                          sx={{
                              marginTop: "0px !important",
                              border: "1px solid gray",
                              borderRadius: "xl",
                          }}
                        >
                            <Text color="white" fontWeight="semibold">
                                {kebabCaseToSpaceSeperate(item.fulfillmentProvider)}
                            </Text>
                            <Text color="white">{item.averageTime || "-"}</Text>
                            <Text color="white">{item.currentTime || "-"}</Text>
                            <Text color="white">{item.warningThreshold || "-"}</Text>
                            <Text color="white">{item.alertThreshold || "-"}</Text>
                            <Text color="white">{item.customWarningMessage || "-"}</Text>
                            <Text color="white">{item.customAlertMessage || "-"}</Text>
                            {hasAuthority(ROLES.CONFIG_MANAGER_ROLE) && (
                              <HStack
                                spacing={4}
                                flexDirection={'row'}
                                justifyContent={'center'}
                                alignItems={'center'}
                              >
                                  <IconButton
                                    rounded={'full'}
                                    bg={'altGray.400'}
                                    color={'brand.500'}
                                    _hover={{
                                        background: 'brand.500',
                                        color: 'altGray.500',
                                    }}
                                    size={'md'}
                                    fontSize={'1rem'}
                                    fontWeight={400}
                                    icon={<AiFillEdit />}
                                    onClick={() => onUpdateFulfillmentSettlementItem(item)}
                                  />
                              </HStack>
                            )}
                        </Grid>
                      ))
                    }
                </VStack>
            </>
          )}
      </Box>
    );
};

export default TransferDelayConfig;