import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  useDisclosure,
  VStack,
  Text,
  Tooltip,
  Link,
  Stack,
  Grid,
  Icon,
  IconButton,
  Input,
  Select
} from '@chakra-ui/react';

import React, { useState } from 'react';
import profileLogo from '../../logo/profile.svg';
import { getUserDetailsList, getActiveDeviceDetailsList } from './utils';
import ActiveDevice from './ActiveDevice';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { BsCashCoin, BsPeopleFill } from 'react-icons/bs';
import ModalLayout from '../../components/Modal/ModalLayout';
import AddFreeTransfers from '../../components/Modal/AddFreeTransfers';
import CopyableText from '../../components/CopyableText';
import { Link as ReactLink } from 'react-router-dom';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { BiAward, BiNetworkChart, BiSync } from 'react-icons/bi';
import { GiTicket } from 'react-icons/gi';
import { MdEdit, MdOutlineStart } from 'react-icons/md';
import { FaArrowRightFromBracket } from 'react-icons/fa6';
import GenericSelectModal from '../../components/Modal/GenericSelectModal';
import { VDA_ELIGIBILITY_STATUS } from '../../constants/v2/common';
import { COUNTRY_CODE } from '../../constants';
import { useDispatch } from 'react-redux';
import {
  getUserById,
  updateVdaEligibilityStatus
} from '../../store/actions/usersActions';
import { setAlert } from '../../store/actions/alertActions';
import {
  updateUserEmail,
  updateUserPhoneNumber
} from '../../store/actions/cxOperationActions';
import useAuthRole from '../../hooks/useAuthRole';
import { ROLES } from '../../constants/index';
import GenericUpdateModal from '../../components/Modal/GenericUpdateModal';
const UserDetails = ({
  userData,
  allowAddFreeTransfer,
  referralCode,
  allowSyncWallet,
  onWalletSync,
  isSyncingWallet,
  onVanceCashWalletSync
}) => {
  const { hasAnyAuthority } = useAuthRole();
  const userDetailsList = getUserDetailsList(userData);
  const activeDeviceDetails = getActiveDeviceDetailsList(userData.activeDevice);
  const {
    isOpen: isFreeTransferModalOpen,
    onOpen: onFreeTransferModalOpen,
    onClose: onFreeTransferModalClose
  } = useDisclosure();
  const {
    isOpen: isEmailUpdateOpen,
    onOpen: onEmailUpdateOpen,
    onClose: onEmailUpdateClose
  } = useDisclosure();
  const {
    isOpen: isPhoneUpdateOpen,
    onOpen: onPhoneUpdateOpen,
    onClose: onPhoneUpdateClose
  } = useDisclosure();
  const {
    isOpen: isUpdateVdaStatusOpen,
    onOpen: onUpdateVdaStatusOpen,
    onClose: onUpdateVdaStatusClose
  } = useDisclosure();

  const [isUpdateVdaStatusLoading, setIsUpdateVdaStatusLoading] =
    useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const emailId =
    userDetailsList.filter((item) => item.name === 'Email:')[0].value || '';

  const phoneNumber =
    userDetailsList.filter((item) => item.name === 'Mobile number:')[0].value ||
    ' ';

  const dispatch = useDispatch();

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleUpdateVdaStatusClose = () => {};
  const handleUpdate = ({ email, phoneNumber }) => {
    if (email && !isValidEmail(email)) {
      dispatch(
        setAlert({
          title: 'Email Format is Wrong',
          status: 'error'
        })
      );
      return;
    }
    const userId = userDetailsList.filter(
      (detail) => detail.name === 'User ID:'
    )[0].value;
    setIsModalLoading(true);
    if (email)
      dispatch(
        updateUserEmail({
          userId,
          email,
          onSuccess: () => {
            dispatch(
              setAlert({
                title: 'Updated Successfully!',
                status: 'success'
              })
            );
            window.location.reload();
          },
          onComplete: () => {
            setIsModalLoading(false);
            onEmailUpdateClose();
          }
        })
      );
    if (phoneNumber != undefined) {
      dispatch(
        updateUserPhoneNumber({
          userId,
          phoneNumber: phoneNumber,
          onSuccess: () => {
            dispatch(
              setAlert({
                title: 'Updated Successfully!',
                status: 'success'
              })
            );
            window.location.reload();
          },
          onComplete: () => {
            setIsModalLoading(false);
            onPhoneUpdateClose();
          }
        })
      );
    }
  };
  const handleUpdateVdaStatusSubmit = ({ value }) => {
    if (!value) {
      dispatch(
        setAlert({
          title: 'Please enter a valid status',
          status: 'error'
        })
      );
      return;
    }
    if (value === userData?.vdaEligibilityStatus) {
      return;
    }

    setIsUpdateVdaStatusLoading(true);
    dispatch(
      updateVdaEligibilityStatus({
        userId: userData?.userId,
        newStatus: value,
        onSuccess: () => {
          handleUpdateVdaStatusClose();
          dispatch(getUserById(userData?.userId));
        },
        onComplete: () => {
          setIsUpdateVdaStatusLoading(false);
        }
      })
    );
  };
  return (
    <Box
      position="relative"
      maxW={{ base: '100%', xl: '32rem' }}
      w={{ base: '100%', xl: 'auto' }}
    >
      <ModalLayout isOpen={isEmailUpdateOpen} onClose={onEmailUpdateClose}>
        <GenericUpdateModal
          title={'Email Update'}
          data={{
            email: emailId
          }}
          isLoading={isModalLoading}
          onCancel={onEmailUpdateClose}
          onSubmit={({ email }) => {
            handleUpdate({
              email: email
            });
          }}
        />
      </ModalLayout>
      <ModalLayout isOpen={isPhoneUpdateOpen} onClose={onPhoneUpdateOpen}>
        <GenericUpdateModal
          title={'Phone Number Update'}
          data={{
            phoneNumber: phoneNumber
          }}
          isLoading={isModalLoading}
          onCancel={onPhoneUpdateClose}
          onSubmit={({ phoneNumber }) => {
            handleUpdate({
              phoneNumber
            });
          }}
        />
      </ModalLayout>
      <ModalLayout
        isOpen={isUpdateVdaStatusOpen}
        onClose={onUpdateVdaStatusClose}
      >
        <GenericSelectModal
          isLoading={isUpdateVdaStatusLoading}
          title={'Update VDA Eligibility Status'}
          initialValue={userData?.vdaEligibilityStatus}
          label={'Choose status'}
          options={Object.keys(VDA_ELIGIBILITY_STATUS).map((status) => status)}
          handleSubmit={handleUpdateVdaStatusSubmit}
          placeholder={'Select eligibility status'}
        />
      </ModalLayout>
      <ModalLayout
        isOpen={isFreeTransferModalOpen}
        onClose={onFreeTransferModalClose}
      >
        <AddFreeTransfers
          userId={userData?.userId}
          onClose={onFreeTransferModalClose}
          creditorsList={userData?.creditors}
        />
      </ModalLayout>
      {referralCode && (
        <Tooltip
          hasArrow
          label={'Referral Code'}
          bg={'shadedGray.800'}
          px={2}
          py={1}
        >
          <HStack
            position={'absolute'}
            top={-2}
            right={-2}
            bg={'altGray.300'}
            color={'brand.200'}
            px={3}
            py={1}
            rounded={'lg'}
            border={'1px solid'}
            borderColor={'altGray.100'}
          >
            <CopyableText title="Referral Code">{referralCode}</CopyableText>
            <BsPeopleFill />
          </HStack>
        </Tooltip>
      )}
      <Box
        bg="backGround"
        w={{ base: '100%', xl: 'auto' }}
        borderRadius="12px"
        className="scroll"
      >
        <VStack
          mx={{ base: 5, md: 5 }}
          my={0}
          pb={2}
          spacing="2rem"
          className="scroll-sm"
          position={'relative'}
        >
          <HStack style={{ marginTop: '2rem', alignSelf: 'start' }}>
            <Image src={profileLogo} h="4rem" />
            <Heading
              fontWeight="400"
              color="white"
              size={'lg'}
              style={{ marginLeft: '1rem' }}
            >
              {userData?.name}
            </Heading>
          </HStack>
          {userData ? (
            <>
              {' '}
              <Grid
                gridTemplateColumns={{ base: '1fr', sm: '0.75fr 1fr' }}
                rowGap={2}
                w={'auto'}
              >
                {userDetailsList.map((item, idx) => {
                  return (
                    <React.Fragment key={item.name}>
                      {' '}
                      <Box
                        color={'gray'}
                        pt="0"
                        pl={'0'}
                        pr={0}
                        border="none"
                        maxWidth={'20rem'}
                        whiteSpace={'break-word'}
                      >
                        {item.name}
                      </Box>
                      <Box color={'white'} pt="0" border="none">
                        {item.name === 'App Platform:' &&
                        item.value &&
                        activeDeviceDetails ? (
                          <ActiveDevice
                            appPlatform={item.value}
                            activeDeviceDetails={activeDeviceDetails}
                          />
                        ) : item.name === 'Free transfers left:' &&
                          allowAddFreeTransfer ? (
                          <Button
                            variant={'link'}
                            color={'white'}
                            fontWeight={400}
                            padding={0}
                            textDecoration={'none !important'}
                            rightIcon={<AiOutlinePlusCircle />}
                            onClick={onFreeTransferModalOpen}
                          >
                            {' '}
                            {item.value}
                          </Button>
                        ) : item.name === 'User Type:' &&
                          item.value === 'GOLDEN_TIER_USER' ? (
                          <Link
                            as={ReactLink}
                            to={`/user/${userData?.userId}/golden-ticket`}
                            color="white"
                            justifyContent={'flex-start'}
                            w={'100%'}
                            fontSize={'sm'}
                          >
                            <HStack>
                              <Text>{item.value}</Text>
                              <GiTicket display={'inline'} />
                            </HStack>
                          </Link>
                        ) : item.name === 'Vance Coins:' &&
                          item.value !== '-' ? (
                          <HStack alignItems={'center'}>
                            <Button
                              variant={'link'}
                              color={'white'}
                              fontWeight={400}
                              padding={0}
                              textDecoration={'none !important'}
                              rightIcon={<BiSync />}
                              onClick={onWalletSync}
                              isLoading={isSyncingWallet}
                            >
                              {' '}
                              {item.value}
                            </Button>
                            <Link
                              as={ReactLink}
                              to={`/user/${userData?.userId}/redeemed-coins`}
                              color="white"
                              _hover={{
                                textDecor: 'underline'
                              }}
                              fontSize={'sm'}
                            >
                              <BsCashCoin />
                            </Link>
                          </HStack>
                        ) : item.isCopyable ? (
                          <HStack>
                            <CopyableText title={item.name.replace(':', '')}>
                              {item.value}
                            </CopyableText>
                            {item.isEditable &&
                              hasAnyAuthority(
                                ROLES.ADMIN_ROLE,
                                ROLES.MANAGER_ROLE,
                                ROLES.USER_DETAILS_MANAGER
                              ) && (
                                <Button
                                  rightIcon={<MdEdit />}
                                  onClick={() => {
                                    item.name === 'Email:' &&
                                      onEmailUpdateOpen();
                                    item.name === 'Mobile number:' &&
                                      onPhoneUpdateOpen();
                                  }}
                                  variant={'link'}
                                />
                              )}
                          </HStack>
                        ) : (
                          <HStack
                            alignItems={'center'}
                            justifyContent={'start'}
                            gap={1}
                          >
                            <Text>{item.value}</Text>
                            {item.name === 'Vance Cash:' &&
                              item.value !== '-' && (
                                <Link
                                  as={ReactLink}
                                  to={`/user/${userData?.userId}/vance-cash`}
                                  color="white"
                                  _hover={{
                                    textDecor: 'underline'
                                  }}
                                  fontSize={'sm'}
                                >
                                  <FaArrowRightFromBracket />
                                </Link>
                              )}
                            {item.name === 'Vance Cash:' &&
                              item.value !== '-' && (
                                <IconButton
                                  variant={'unstyled'}
                                  icon={<BiSync />}
                                  onClick={onVanceCashWalletSync}
                                  isLoading={isSyncingWallet}
                                />
                              )}
                            {item?.property === 'vda-eligibility-status' && (
                              <Button
                                rightIcon={<MdEdit />}
                                onClick={() => {
                                  onUpdateVdaStatusOpen();
                                }}
                                variant={'link'}
                              />
                            )}
                          </HStack>
                        )}
                      </Box>
                    </React.Fragment>
                  );
                })}
              </Grid>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                gap={0}
                w={'100%'}
                pb={2}
                mt={0}
                style={{ marginTop: '1rem' }}
              >
                <Link
                  as={ReactLink}
                  to={`/user/${userData?.userId}/workflows`}
                  color="white"
                  justifyContent={'flex-start'}
                  w={'100%'}
                  fontSize={'sm'}
                >
                  <HStack>
                    <BiNetworkChart display={'inline'} />
                    <Text>Workflows</Text>
                  </HStack>
                </Link>
                <Link
                  as={ReactLink}
                  to={`/user/${userData.userId}/documents`}
                  color="white"
                  justifyContent={'flex-start'}
                  w={'100%'}
                  fontSize={'sm'}
                >
                  <HStack>
                    <HiOutlineDocumentText display={'inline'} />
                    <Text>Documents</Text>
                  </HStack>
                </Link>
                <Link
                  as={ReactLink}
                  to={`/user/${userData?.userId}/referrals`}
                  color="white"
                  justifyContent={'flex-start'}
                  w={'100%'}
                  fontSize={'sm'}
                >
                  <HStack>
                    <BiAward display={'inline'} />
                    <Text>Referrals</Text>
                  </HStack>
                </Link>
                <Link
                  as={ReactLink}
                  to={`/onboarding/${userData?.userId}`}
                  color="white"
                  justifyContent={'flex-start'}
                  w={'100%'}
                  fontSize={'sm'}
                >
                  <HStack>
                    <MdOutlineStart />
                    <Text>NRE-NRO</Text>
                  </HStack>
                </Link>
              </Stack>
            </>
          ) : (
            ''
          )}
        </VStack>
      </Box>
    </Box>
  );
};

export default UserDetails;
