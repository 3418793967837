export default function rewardsApi(fetch, baseUrl) {
  return {
    // Getting funnel data using query
    getFunnelDataByQuery({pageSize, pageNumber, rewardType, rewardStatus, query}){
      return fetch.get(`${baseUrl}?reward_type=${rewardType}&reward_status=${rewardStatus}&search_by=${query}&page_size=${pageSize}&page_number=${pageNumber}`);
    },
    getFunnelData({ pageSize, pageNumber, rewardType, rewardStatus }) {
      return fetch.get(
        `${baseUrl}?reward_type=${rewardType}&reward_status=${rewardStatus}&page_size=${pageSize}&page_number=${pageNumber}`
      );
    },
    confirmReward({ userId }) {
      return fetch.post(`${baseUrl}/confirm-reward/${userId}`);
    },
    invalidateReward({ userId }) {
      return fetch.post(`${baseUrl}/invalidate-reward/${userId}`);
    },
    getReferralUserId({ referrerId }) {
      return fetch.get(`${baseUrl}/referral-user-id/${referrerId}`);
    }
  };
}

